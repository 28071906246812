import { Typography } from 'src/components/atoms/typography';
import styled from 'styled-components';

export const KitDayCardContainer = styled.div`
  && {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    height: ${props => (props.kitDay.status !== 'checking' ? '0' : props.modal ? 'auto' : '364px')};
    transition: height 0.5s;
    padding-bottom: ${props => (props.modal ? '24px' : '')};
  }
`;

export const KitDayCardInfoContainer = styled.div`
  && {
    min-height: 123px;
    height: ${props => (props.modal ? `calc(${props.kitDay.products.length * 50 - 232}px)` : 'auto')};
    border: 1px solid ${props => props.theme.palette.grayScale[40]};
    padding: 130px 25px 109px 25px;
  }
`;

export const KitDayCardProductsContainer = styled.div`
  && {
    width: 100%;
  }
`;

export const KitDayCardKitText = styled(Typography)`
  && {
    text-align: center;
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.xsmall};
  }
`;

export const KitDayCardDayText = styled(Typography)`
  && {
    width: 30px;
    text-align: center;
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.medium};
    font-weight: bold;
  }
`;
export const KitDayCardDayNumberText = styled(Typography)`
  && {
    width: 30px;
    text-align: center;
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.medium};
    font-weight: bold;
    margin-bottom: 36px;
  }
`;
export const KitDayCardTotalText = styled(Typography)`
  && {
    width: 30px;
    text-align: center;
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.xsmall};
  }
`;

export const KitDayCardQuantityText = styled(Typography)`
  && {
    width: 30px;
    text-align: center;
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.medium};
    font-weight: bold;
  }
`;
