import React from 'react';

export const Afternoon = () => (
  <svg viewBox="0 0 64 65" version="1.1">
    <title>Group 2 Copy</title>
    <desc>Created with Sketch.</desc>
    <g
      id="04_Protótipos"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g
        id="Inicial_preenchido_ver_dia"
        transform="translate(-1092.000000, -481.000000)"
        stroke="#004D69"
        stroke-width="2.3"
      >
        <g id="Group-2-Copy" transform="translate(1093.000000, 482.000000)">
          <path
            d="M58,36 C58,23.2974508 47.7025492,13 35,13 C22.2974508,13 12,23.2974508 12,36 C30.7294429,36 45.3507809,36 58,36 Z"
            id="Oval"
          ></path>
          <path d="M0.875,36 L69.125,36" id="Line-2"></path>
          <path d="M10,55 L60.1206897,55" id="Line-2-Copy-3"></path>
          <path d="M26,64 L44,64" id="Line-2-Copy-5"></path>
          <path d="M9,45 L34,45" id="Line-2-Copy"></path>
          <path d="M35,0.9 L35,8.1" id="Line-2-Copy-4"></path>
          <path d="M37,45 L62,45" id="Line-2-Copy-2"></path>
          <path
            d="M51.077939,6.04458831 L51.0901896,13.0634526"
            id="Line-2-Copy-4"
            transform="translate(51.084064, 9.554020) rotate(33.000000) translate(-51.084064, -9.554020) "
          ></path>
          <path
            d="M62.0564155,18.7688604 L62.0666882,25.7736506"
            id="Line-2-Copy-4"
            transform="translate(62.061552, 22.271255) rotate(65.000000) translate(-62.061552, -22.271255) "
          ></path>
          <path
            d="M7.94358445,18.7688604 L7.93331182,25.7736506"
            id="Line-2-Copy-4"
            transform="translate(7.938448, 22.271255) rotate(295.000000) translate(-7.938448, -22.271255) "
          ></path>
          <path
            d="M18.922061,6.04458831 L18.9098104,13.0634526"
            id="Line-2-Copy-4"
            transform="translate(18.915936, 9.554020) rotate(327.000000) translate(-18.915936, -9.554020) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
