import React, { ReactNode } from "react";
import {
  StyledMuiExpansionPanel, StyledMuiExpansionPanelDetails, StyledMuiExpansionPanelSummary
} from "./styles";

export interface CollapseProps {
  title: ReactNode | string;
}

export const Collapse: React.FC<CollapseProps> = ({ ...props }) => {
  return (
    <StyledMuiExpansionPanel {...props}>
      <StyledMuiExpansionPanelSummary>
        {props.title}
      </StyledMuiExpansionPanelSummary>

      <StyledMuiExpansionPanelDetails>
        {props.children}
      </StyledMuiExpansionPanelDetails>
    </StyledMuiExpansionPanel>
  );
};
