interface PrintTagsParams {
  pcId?: string;
  order: string;
  quantity: number;
  productType: string;
  print: any;
  lastCode?: number;
}

export const shouldPrintNfe = (sale: any, cd: any) =>
  sale.logisticKind !== 'b2b' && (sale.logisticKind === 'partner' || sale.user.cpf.length > 14 || cd.hasNfce === false);

export const printTags = ({ pcId, order, quantity, productType, print, lastCode }: PrintTagsParams) => {
  repeat(quantity)((index: number) => {
    print({
      variables: {
        input: {
          pcId,
          order,
          tagCode: getTagCode(order, productType, lastCode ? lastCode + index : index),
          useGoogle: false,
          productType,
          quantity: 1,
        },
      },
    });
  });
};

const repeat = (total: number) => (func: any) => {
  const iter = (i: number) => {
    if (i === total) return;
    func(i);
    iter(i + 1);
  };
  return iter(0);
};

export const getTagCode = (order: string, productType: string, index: number): string => {
  const bagIndexCode = (index + 1).toString().padStart(3, '0');
  const productTypeInitial = getProductTypeInitial(productType);

  return `${order}-${productTypeInitial}-${bagIndexCode}`;
};

const getProductTypeInitial = (productType: string): string => productType.slice(0, 1).toUpperCase();
