import { Typography } from 'src/components/atoms/typography';
import styled from 'styled-components';

const calculateTotalHeight = (totalQuantity: number, itemsQuantity: number) =>
  ((Math.floor(totalQuantity / 7) + (totalQuantity % 7 > 0 ? 1 : 0)) * 32 + 18) * itemsQuantity + (itemsQuantity - 1);

const calculatePadding = (totalQuantity: number, itemsQuantity: number) => {
  const totalHeight = calculateTotalHeight(totalQuantity, itemsQuantity);

  const padding = (totalHeight - 102) / 2;
  if (padding < 24) {
    return 24;
  }
  return padding;
};

export const calculateCardHeight = (totalQuantity: number, itemsQuantity: number) => {
  const totalHeight = calculateTotalHeight(totalQuantity, itemsQuantity) + 2;
  if (totalHeight < 154) {
    return 154;
  }
  return totalHeight;
};
export const MealCardContainer = styled.div`
  && {
    margin-top: ${props => (props.meal.status !== 'checking' ? '0' : '4px')};
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    height: ${props =>
      props.meal.status !== 'checking' ? '0' : calculateCardHeight(props.totalQuantity, props.itemsQuantity)};
    transition: height 0.5s;
  }
`;

export const MealCardInfoContainer = styled.div`
  && {
    border: 1px solid ${props => props.theme.palette.grayScale[40]};
    padding: ${props =>
      `${calculatePadding(props.totalQuantity, props.itemsQuantity)}px 25px ${calculatePadding(
        props.totalQuantity,
        props.itemsQuantity
      )}px 25px`};
  }
`;

export const MealCardProductsContainer = styled.div`
  && {
    width: 100%;
  }
`;

export const MealText = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.xsmall};
    margin-bottom: 5px;
    text-align: center;
  }
`;

export const MealQuantityText = styled(Typography)`
  && {
    width: 30px;
    text-align: center;
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.medium};
    font-weight: bold;
    margin-bottom: 24px;
  }
`;

export const MealProductsQuantityText = styled(Typography)`
  && {
    text-align: center;
    width: 30px;
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.medium};
    font-weight: bold;
  }
`;
