import React from 'react';
import { LookupItem } from '../../../contracts/lookupItem';
import { v2 } from '../../../theme';
import {
  DropdownIndicator,
  SelectContainer,
  StyledSelect
} from './styles';

export interface LookupProps {
  placeholder: string;
  loadOptions?: (option: any) => void;
  options: LookupItem[];
  onSelect: (option: LookupItem) => void;
  isMulti?: boolean;
  isClearable?: boolean;
  selected?: LookupItem;
  disabled?: boolean;
}

export const Lookup = React.memo(
  ({ onSelect, isMulti, isClearable, selected, disabled, ...props }: Partial<LookupProps>) => {
    const handleChange = (option: LookupItem) => {
      onSelect(option);
    };
    return (
      <SelectContainer>
        <StyledSelect
          {...props}
          isDisabled={disabled}
          defaultValue={selected}
          components={{ DropdownIndicator }}
          theme={(theme: any) => ({ ...theme, palette: v2.palette })}
          onChange={handleChange}
          isClearable={isClearable}
          isMulti={isMulti}
          isLoading={!props.options || props.options.length === 0}
          loadingMessage={() => 'Carregando...'}
        />
      </SelectContainer>
    );
  }
);
