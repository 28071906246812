import * as R from 'ramda';
import { FetchSaleQuery_sale_cart_items } from 'src/apollo/types/FetchSaleQuery';
import { FormattedProduct, PickingItemStatus, ProductsBySku, ProductTypes } from 'src/common/types';
import { filterByTypes } from './filters';
import { sortByPackageColor } from './sorting';

export const mergeAndFormatProducts = (
  products: FetchSaleQuery_sale_cart_items[],
  productsBySku: ProductsBySku,
  productTypes: ProductTypes[]
): FormattedProduct[] => {
  const formattedProducts: FormattedProduct[] = [];
  products.filter(filterByTypes(productTypes)).forEach((product, index) => {
    const found = formattedProducts.find(p => p.sku === product.item.sku);
    if (found) {
      found.totalQuantity += product.quantity;
    } else {
      if (!productsBySku[product.item.sku]) {
        throw new Error(
          `Não foi possível encontrar o produto ${product.item.sku} presente na venda na lista de todos os produtos.`
        );
      }
      formattedProducts.push({
        type: product.item.type as ProductTypes,
        key: String(Math.random()),
        sku: product.item.sku,
        name: productsBySku[product.item.sku].name,
        color: productsBySku[product.item.sku].color,
        totalQuantity: Number(product.quantity),
        checks: 0,
        status: PickingItemStatus.waiting,
        catalogueCategories: productsBySku[product.item.sku].catalogueCategories,
        pickingPriority: productsBySku[product.item.sku].pickingPriority,
      });
    }
  });
  return sortByPackageColor(formattedProducts);
};

export const mergeAndFormatMeals = (meals: any[], productsBySku: ProductsBySku, productTypes: ProductTypes[]) => {
  const formattedMeals: any[] = [];
  meals.forEach(meal => {
    const found = formattedMeals.find(m => m.sku === meal.item.sku);
    if (found) {
      found.totalQuantity += meal.quantity;
    } else {
      formattedMeals.push({
        key: String(Math.random()),
        sku: meal.item.sku,
        name: meal.item.name,
        totalQuantity: Number(meal.quantity),
        checks: 0,
        status: PickingItemStatus.waiting,
        products: meal.item.products
          .filter((productSku: string) => R.includes(productsBySku[productSku].type, productTypes))
          .map((productSku: string) => ({
            key: String(Math.random()),
            sku: productSku,
            name: productsBySku[productSku].name,
            color: productsBySku[productSku].color,
            totalQuantity: Number(meal.quantity),
            checks: 0,
            status: PickingItemStatus.checking,
            type: productsBySku[productSku].type,
          })),
      });
    }
  });
  return formattedMeals;
};
