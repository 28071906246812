import React from 'react';
import { DescribedLabelContainer, Description, Label } from './styles';

interface DescribedLabelProps {
  description: string;
  label: string;
  user?: boolean;
}

export const DescribedLabel: React.FC<DescribedLabelProps> = ({ description, label, user }) => {
  return (
    <DescribedLabelContainer>
      <Description>
        {description}
      </Description>
      <Label user={user}>
        {label}
      </Label>
    </DescribedLabelContainer>
  );
};
