import styled from 'styled-components';

export interface TypographyProps {
	fontSize?: 'h1' | 'h2' | 'large' | 'medium' | 'small' | 'xsmall' | 'caption';
	color?: string;
	fontWeight?: 'bold';
}

export const Typography = styled.p<TypographyProps>`
	color: ${props => props.color};
	font-size: ${props => props.theme.typography.fontSize[props.fontSize]};
	font-weight: ${props => props.fontWeight};
	margin: 0; /* the <p> tag comes with a default margin value */
	font-family: ${props => props.theme.typography.fontFamily};
`;
