/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DeliveryModifier {
  ECONOMIC = "ECONOMIC",
  EXPRESS = "EXPRESS",
  SCHEDULED = "SCHEDULED",
}

export enum MarketableKind {
  Kit = "Kit",
  Meal = "Meal",
  Product = "Product",
}

export enum PickingEventTypes {
  ADD_TAGS = "ADD_TAGS",
  BAG_PICKING = "BAG_PICKING",
  BATCH_UNSAFE_EXPIRATION_DATE = "BATCH_UNSAFE_EXPIRATION_DATE",
  CANCELED = "CANCELED",
  EXTRA_ITEMS = "EXTRA_ITEMS",
  FINISH_PACKING = "FINISH_PACKING",
  MANUAL_TAG_PRINTING = "MANUAL_TAG_PRINTING",
  MISSING_PRODUCT = "MISSING_PRODUCT",
  NUMBER_OF_BAGS = "NUMBER_OF_BAGS",
  PICKING_LIST = "PICKING_LIST",
  TAG_PROBLEM = "TAG_PROBLEM",
}

export enum PickingProblems {
  MISSING_PRODUCT = "MISSING_PRODUCT",
  TAG_PROBLEM = "TAG_PROBLEM",
}

export interface CancelPicking {
  order: string;
  productType: string;
  force?: boolean | null;
}

export interface EmailSignIn {
  email: string;
  password: string;
}

export interface FinishPicking {
  order: string;
  distributionCenter: string;
  productType: string;
  productBatches?: string[] | null;
  packageCodes?: string[] | null;
  numberOfPackages?: number | null;
  pickingProblems?: PickingProblem[] | null;
}

export interface LogEvent {
  event: PickingEventTypes;
  user: string;
  timestamp: string;
  order: string;
  payload?: string | null;
}

export interface PickingProblem {
  sku: string;
  name: string;
  problem: PickingProblems;
}

export interface PrintTag {
  order: string;
  tagCode: string;
  useGoogle: boolean;
  productType: string;
  quantity: number;
  pcId?: string | null;
}

export interface StartPicking {
  order?: string | null;
  distributionCenter: string;
  productType: string;
  pcId?: number | null;
  logisticKind: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
