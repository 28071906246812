import { Timer } from '@material-ui/icons';
import React from 'react';
import { ExpressOrderIndicatorContainer, InfoText } from './styles';

export const ExpressOrderIndicator: React.FC = () => {
  return (
    <ExpressOrderIndicatorContainer>
      <Timer style={{ fontSize: 40, color: 'white' }} />
      <InfoText>Pedido Expresso</InfoText>
    </ExpressOrderIndicatorContainer>
  );
};
