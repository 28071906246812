import React from 'react';
import { Typography } from 'src/components/atoms/typography';
import styled from 'styled-components';
import CheckBox from 'src/components/atoms/checkbox';

const calculateHeight = (total: number) => `${(Math.floor(total / 7) + (total % 7 > 0 ? 1 : 0)) * 32 + 18}px`;

interface ProductCardContainerProps {
  product: any;
  index: number;
}

interface ProductCardQuantityProps {
  done: boolean;
}

interface ProductCardNameProps {
  kind: string;
  done: boolean;
}
export const ProductCardContainer = styled.div<ProductCardContainerProps>`
  && {
    position: relative;
    justify-content: flex-end;
    transition: height 0.5s;
    height: ${props => (props.product.status !== 'checking' ? 0 : calculateHeight(props.product.totalQuantity))};
    overflow: hidden;
    margin-top: ${props => (props.index !== 0 ? '1px' : '0px')};
    background-color: ${props =>
      props.product.checks === props.product.totalQuantity
        ? props.theme.palette.disable
        : props.theme.palette.categoryColors[props.product.color ? props.product.color : 'Amarelo']};
    display: flex;
    align-items: center;
  }
`;
export const ProductCardQuantity = styled(Typography)<ProductCardQuantityProps>`
  && {
    position: absolute;
    left: 24px;
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.large};
    width: 82px;
    font-weight: bold;
    opacity: ${props => (props.done ? '0.3' : '1')};
  }
`;

export const ProductCardName = styled(Typography)<ProductCardNameProps>`
  && {
    position: absolute;
    left: ${props => (props.kind === 'product' ? '105px' : '0px')};
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.large};
    padding-left: ${props => (props.kind === 'product' ? '0' : '24px')};
    font-weight: bold;
    text-decoration: ${props => props.done && 'line-through'};
    opacity: ${props => (props.done ? '0.3' : '1')};
  }
`;

export const ProductCardQuantityProgress = styled(Typography)<ProductCardQuantityProps>`
  && {
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.large};
    width: 224px;
    padding-right: 63px;
    display: flex;
    font-weight: bold;
    opacity: ${props => (props.done ? '0.3' : '1')};
  }
`;

export const ProductCardManualPickIconContainer = styled.div`
  && {
    position: absolute;
    right: 24px;
  }
`;

export const ProductCardManualPickIcon = styled.div<ProductCardQuantityProps>`
  && {
    width: 18px;
    height: 18px;
    :hover {
      cursor: ${props => !props.done && 'pointer'};
    }
    opacity: ${props => (props.done ? '0.3' : '1')};
  }
`;

export const CheckBoxContainer = styled.div`
  position: relative;
`;
export const ProductCheckBox = styled(CheckBox)`
  && {
    margin: 0;
    cursor: default;
    span[class*='MuiButtonBase'] {
      padding: 4px;
      :hover {
        cursor: default;
      }
    }
  }
`;
