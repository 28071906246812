import React, { ComponentType } from 'react';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  && {
    padding: 24px;
    position: relative;
    top: 50px;
  }
`;

export const InitialFormAndStatusContainer = styled.div`
  && {
    display: flex;
    position: relative;
  }
`;

export const InitialFormContainer = styled.div`
  && {
    flex-basis: 55%;
    max-width: 55%;
    margin-right: 24px;
  }
`;

export const StatusContainer = styled.div`
  && {
    position: relative;
    flex-basis: 45%;
    max-width: 45%;
    margin-right: 24px;
  }
`;

export const StepperAndInfosContainer = styled.div`
  && {
    display: flex;
  }
`;

export const OrdersPerPeriodAndSaleInfoContainer = styled.div`
  && {
    flex-basis: 25%;
    max-width: 25%;
  }
`;
