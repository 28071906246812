import React from 'react';
import { ExtraItemCard } from '../../molecules/extraItemCard';
import { ExtraItemsContainer, NoExtraItemsMessage } from './styles';
import { StartPickingMutation_startPicking_promotionalItems } from 'src/apollo/types/StartPickingMutation';

export interface PromotionalItems {
  name: string;
  title: string;
  image: string | JSX.Element;
}

export interface ExtraItemsProps {
  promotionalItems: PromotionalItems[];
}

export const ExtraItems: React.FC<ExtraItemsProps> = ({ ...props }) => {
  return (
    <ExtraItemsContainer {...props}>
      {props.promotionalItems && props.promotionalItems.length > 0 ? (
        props.promotionalItems.map((promotionalItem, index) => (
          <ExtraItemCard promotionalItem={promotionalItem} index={index} />
        ))
      ) : (
        <NoExtraItemsMessage>Não há itens extras</NoExtraItemsMessage>
      )}
    </ExtraItemsContainer>
  );
};
