import moment from 'moment-timezone';
import { parseBagBarcode } from 'src/beltops/barcode/bag';
import { setKitDaysStatus } from 'src/common/picking/logic/status/setKitDaysStatus';
import {
  FormattedSale,
  Marketable,
  Marketables,
  PickingItemStatus,
  PickingTypes,
  ProductsBySku,
  ProductTypes,
  TypeTagCode,
} from 'src/common/types';
const errorSound = new Audio(require('../../../../assets/sounds/error.mp3'));

const DEBOUNCE = 100;
const BUSY = 250;
export const getSkuFromBarcode = barcode =>
  String.fromCharCode(96 + parseInt(barcode.substr(1, 2))).toUpperCase() + barcode.substr(3, 3);

export const skusDictionary: { [sku: string]: string } = {
  R039: 'R039',
  L110: 'L110',
  R054: 'R054',
  R055: 'R055',
  R056: 'R056',
  R057: 'R057',
};

export const getSku = (barcode: string, productsBySku: ProductsBySku) => {
  const { sku } = parseBagBarcode(barcode);
  if (productsBySku[sku]) {
    return { sku, isEan13: false };
  }
  const product = Object.values(productsBySku).find(p => p.fiscal && p.fiscal.barcode === barcode);
  if (!product) {
    return { sku: null, isEan13: true };
  }
  return { sku: product.sku, isEan13: true };
};

export const fiscalBarcodeExistsForProduct = (barcode: string, productsBySku: ProductsBySku) =>
  Boolean(Object.values(productsBySku).find(p => p.fiscal && p.fiscal.barcode === barcode));

export const isExpirationDateSafe = (
  today: moment.Moment,
  expirationDate: moment.Moment,
  expirationInDays: number,
  productType: ProductTypes,
  cut?: number
) => {
  const deltaInDays = expirationDate.startOf('day').diff(today.startOf('day'), 'days')

  if (cut && !isNaN(cut)) {
    return deltaInDays >= cut
  }

  if (expirationInDays === 180 && productType === ProductTypes.frozen) {
    return deltaInDays >= 28;
  }

  if (expirationInDays === 90 && productType === ProductTypes.frozen) {
    return deltaInDays >= 21;
  }

  return deltaInDays >= 0;
};

const getExpiration = (products: ProductsBySku) => (sku: string) => {
  return products[sku].expiration;
};

export const isProductValid = (sku: string, barcode: string, today: moment.Moment, products: ProductsBySku) => {
  const product = products[sku];
  const shouldBypassVerification =
    sku.startsWith('R') || !barcode || !product || fiscalBarcodeExistsForProduct(barcode, products);

  if (shouldBypassVerification) {
    return true;
  }
  const { expiration: expirationDate } = parseBagBarcode(barcode);
  return isExpirationDateSafe(today, moment(expirationDate), product.expiration, product.type as ProductTypes, product.expirationCut);
};

export const getCardTotalChecks = products => products.reduce((acc, cur) => acc + cur.checks, 0) + 1;

export const getCardTotalQuantity = products => products.reduce((acc, cur) => acc + cur.totalQuantity, 0);

export const checkIfProductIsDone = product => product.checks + 1 === product.totalQuantity;

export const checkIfCardIsDone = products => getCardTotalChecks(products) === getCardTotalQuantity(products);

export const checkIfKitIsDone = (doneKitDay, kit, dayIndex) => doneKitDay && dayIndex + 1 === kit.kitDays.length;

export const getNextMarketableIndex = (marketables: Marketable[], currentIndex: number): number => {
  const rest = marketables.slice(currentIndex + 1);
  for (const marketableIndex in rest) {
    if (rest[marketableIndex].items.length > 0) {
      return Number(marketableIndex) + currentIndex + 1;
    }
  }
  return 0;
};

export const getFirstMarketableIndex = (marketables: Marketable[]): number => {
  for (const marketableIndex in marketables) {
    if (marketables[marketableIndex].items.length > 0) {
      return Number(marketableIndex);
    }
  }
  return 0;
};

export const updateMarketable = (
  skuOrKey: string,
  sale: FormattedSale,
  onProductCheck,
  enqueueSnackbar,
  barcode,
  currentMarketableIndex
) => {
  const marketable = sale.marketables[currentMarketableIndex];
  if (marketable.kind === Marketables.Product) {
    return {
      ...marketable,
      items: updateProducts(skuOrKey, marketable.items, onProductCheck, enqueueSnackbar, barcode),
    };
  }
  if (marketable.kind === Marketables.Meal) {
    return { ...marketable, items: updateMeals(skuOrKey, marketable.items, onProductCheck, enqueueSnackbar, barcode) };
  }
  return { ...marketable, items: updateKits(skuOrKey, marketable.items, onProductCheck, enqueueSnackbar, barcode) };
};

function isRelatedSku(inputSku, currentSku) {
  const skuMap: Record<string, string> = {
    V046:	'V098',
    V047:	'V099',
    V054:	'V100',
    V056:	'V101',
    V060:	'V102',
    V061:	'V103',
    V062:	'V104',
    V063:	'V105',
    V064:	'V106',
    V065:	'V107',
    V070:	'V108',
    V071:	'V109',
    V074:	'V110',
    V081:	'V111',
    V083:	'V112',
    V084:	'V113',
    V085:	'V114',
    V086:	'V115',
    V088:	'V116',
    V089:	'V117',
    V090:	'V118',
    V091:	'V119',
    V092:	'V120',
  };

  return currentSku === skuMap[inputSku];
}

export const updateProducts = (skuOrKey, items, onProductCheck, enqueueSnackbar, barcode) => {
  let doneProduct = false;
  let checkedProduct = false;
  const updatedProducts = items.map((product: any) => {
    if (product.status === PickingItemStatus.checking && (skuOrKey === product.sku || skuOrKey === product.key || isRelatedSku(skuOrKey, product.sku))) {
      checkedProduct = true;
      onProductCheck(barcode);
      doneProduct = checkIfProductIsDone(product);
      return {
        ...product,
        checks: product.checks + 1,
        status: doneProduct ? PickingItemStatus.done : PickingItemStatus.checking,
      };
    }
    if (doneProduct && product.status === PickingItemStatus.waiting) {
      doneProduct = false;
      return { ...product, status: PickingItemStatus.checking };
    }
    return { ...product };
  });
  if (!checkedProduct) {
    errorSound.play();
    enqueueSnackbar('Produto não encontrado', {
      variant: 'error',
    });
  }
  return updatedProducts;
};

export const skuFirstLetterAlphabetPosition = (sku: string) => sku.charCodeAt(0) - 64;

export const paddedSkuFirstLetterAlphabetPosition = (sku: string) =>
  `00${skuFirstLetterAlphabetPosition(sku)}`.slice(-2);

export const generateBatch = (sku: string, date: string | Date): string => {
  const numbers = sku.slice(1, 4);
  const now = moment(date)
    .tz('America/Sao_Paulo')
    .format('DDMMYY');
  return `1${paddedSkuFirstLetterAlphabetPosition(sku)}${numbers}${now}0`;
};

export const updateMeals = (skuOrKey, items, onProductCheck, enqueueSnackbar, barcode) => {
  let doneMeal = false;
  let checkedProduct = false;
  const updatedMeals = items.map(meal => {
    let checkedMeal = false;
    if (meal.status === PickingItemStatus.checking) {
      return {
        ...meal,
        products: meal.products.map(product => {
          if (
            !checkedProduct &&
            (skuOrKey === product.sku || skuOrKey === product.key || isRelatedSku(skuOrKey, product.sku)) &&
            product.checks < product.totalQuantity
          ) {
            onProductCheck(barcode);
            doneMeal = checkIfCardIsDone(meal.products);
            checkedProduct = true;
            checkedMeal = true;
            return { ...product, checks: product.checks + 1 };
          }
          return { ...product };
        }),
        // @ts-ignore
        ...(checkedMeal && doneMeal && { status: PickingItemStatus.done }),
      };
    }
    if (doneMeal && meal.status === PickingItemStatus.waiting) {
      doneMeal = false;
      return { ...meal, status: PickingItemStatus.checking };
    }
    return { ...meal };
  });
  if (!checkedProduct) {
    errorSound.play();
    enqueueSnackbar('Produto não encontrado', {
      variant: 'error',
    });
  }
  return updatedMeals;
};

export const updateKits = (skuOrKey, items, onProductCheck, enqueueSnackbar, barcode) => {
  let doneKit = false;
  let checkedProduct = false;
  const updatedKits = items.map(kit => {
    if (kit.status === PickingItemStatus.checking) {
      let doneKitDay = false;
      return {
        ...kit,
        kitDays: kit.kitDays.map((kitDay, dayIndex) => {
          let checkedKitDay = false;
          if (kitDay.status === PickingItemStatus.checking) {
            let doneProduct = false;
            return {
              ...kitDay,
              products: kitDay.products.map(product => {
                if (
                  (skuOrKey === product.sku || skuOrKey === product.key || isRelatedSku(skuOrKey, product.sku)) &&
                  product.status === PickingItemStatus.checking
                ) {
                  checkedProduct = true;
                  onProductCheck(barcode);
                  checkedKitDay = true;
                  doneProduct = checkIfProductIsDone(product);
                  doneKitDay = checkIfCardIsDone(kitDay.products);
                  doneKit = checkIfKitIsDone(doneKitDay, kit, dayIndex);
                  return {
                    ...product,
                    checks: product.checks + 1,
                    ...(doneProduct && { status: PickingItemStatus.done }),
                  };
                }
                if (doneProduct && product.status === PickingItemStatus.waiting) {
                  doneProduct = false;
                  return { ...product, status: PickingItemStatus.checking };
                }
                return { ...product };
              }),
              // @ts-ignore
              ...(checkedKitDay && doneKitDay && { status: PickingItemStatus.done }),
            };
          }
          if (doneKitDay && kitDay.status === PickingItemStatus.waiting) {
            doneKitDay = false;
            return {
              ...kitDay,
              status: PickingItemStatus.checking,
            };
          }
          return { ...kitDay };
        }),
        ...(checkedProduct && doneKit && { status: PickingItemStatus.done }),
      };
    }
    if (doneKit && kit.status === PickingItemStatus.waiting) {
      doneKit = false;
      return {
        ...kit,
        kitDays: setKitDaysStatus(kit.kitDays),
        status: PickingItemStatus.checking,
      };
    }
    return { ...kit };
  });
  if (!checkedProduct) {
    errorSound.play();
    enqueueSnackbar('Produto não encontrado', {
      variant: 'error',
    });
  }
  return updatedKits;
};

export const checkBarcode = (barcode: any) => /^[0-9]{12}([0-9])?$/.test(barcode);

export const checkTagcode = (tagCode: any) => /^[0-9]*-([A-Z])-[0-9]{3}$/.test(tagCode);

export const checkOrder = (tagCode: string, order: string) => {
  const orderFromTagCode = tagCode.split('-')[0];
  return order === orderFromTagCode;
};

export const checkType = (tagCode: string, pickingType: string) => {
  const typeFromTagCode = tagCode.split('-')[1];
  return (
    (typeFromTagCode === TypeTagCode.F && pickingType === PickingTypes.frozen) ||
    (typeFromTagCode === TypeTagCode.S && pickingType === PickingTypes.snacks) ||
    (typeFromTagCode === TypeTagCode.G && pickingType === PickingTypes.greenGrocer) ||
    (typeFromTagCode === TypeTagCode.B && pickingType === PickingTypes.basket)
  );
};
export const triggerBusy = (setBusy: any) => {
  setBusy(true);
  setTimeout(() => {
    setBusy(false);
  }, BUSY);
};

export const triggerDebounce = (setBarcode: any) =>
  setTimeout(() => {
    setBarcode('');
  }, DEBOUNCE);
