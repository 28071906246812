import React from 'react';
import {
  AddRemoveContainer,
  AddRemoveDivider,
  AddRemoveIcon,
  AddRemoveIconContainer,
  AddRemovePartContainer,
  AddRemoveQuantityText,
} from './styles';
import { Minus } from 'src/common/icons/minus';
import { Plus } from 'src/common/icons/plus';

export interface AddRemoveProps {
  quantity: number;
  onAdd: () => void;
  onRemove: () => void;
}
export const AddRemove: React.FC<AddRemoveProps> = ({ ...props }) => (
  <AddRemoveContainer>
    <AddRemoveIconContainer onClick={props.onRemove}>
      <AddRemoveIcon>
        <Minus />
      </AddRemoveIcon>
    </AddRemoveIconContainer>
    <AddRemoveDivider />
    <AddRemovePartContainer>
      <AddRemoveQuantityText quantity={props.quantity}>{props.quantity}</AddRemoveQuantityText>
    </AddRemovePartContainer>
    <AddRemoveDivider />
    <AddRemoveIconContainer onClick={props.onAdd}>
      <AddRemoveIcon>
        <Plus />
      </AddRemoveIcon>
    </AddRemoveIconContainer>
  </AddRemoveContainer>
);
