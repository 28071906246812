import { Typography } from "src/components/atoms/typography";
import styled from "styled-components";

export const AddRemoveContainer = styled.div`
  && {
    border: 1px solid ${props => props.theme.palette.grayScale[20]};
    width: 105px;
    border-radius: 2px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }
`;

export const AddRemovePartContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 33.33%;
    max-width: 33.33%
  }
`;

export const AddRemoveIconContainer = styled.div`
  && {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 33.33%;
    max-width: 33.33%
    :hover {
      cursor: pointer;
    }
  }
`;

export const AddRemoveIcon = styled.div`
  && {
    height: 17px;
    width: 17px;
    align-self: center;
    justify-self: center;
  }
`;

export const AddRemoveDivider = styled.div`
  && {
    border: 1px solid ${props => props.theme.palette.grayScale[10]};
    height: 30px;
  }
`;

export const AddRemoveQuantityText = styled(Typography)`
  && {
    color: ${props => props.quantity === 0 ? props.theme.palette.grayScale[20] : props.theme.palette.grayScale[60]};
    font-size: ${props => props.theme.typography.fontSize.large};
  }
`;