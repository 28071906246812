import { Marketable, Marketables, PickingItemStatus } from 'src/common/types';
import { setKitDaysStatus } from './setKitDaysStatus';

export function setMarketablesStatus(marketables: Marketable[]) {
  let statusSet = false;
  return marketables.map((marketable: Marketable) => {
    if (!statusSet && marketable.items.length > 0) {
      statusSet = true;
      return { ...marketable, items: setStatus(marketable) };
    }
    return marketable;
  });
}

export function setStatus(marketable: Marketable) {
  if (marketable.kind === Marketables.Kit) {
    return setKitsStatus(marketable.items);
  }
  if (marketable.kind === Marketables.Meal) {
    return setMealsStatus(marketable.items);
  }
  return setProductsStatus(marketable.items);
}

function setKitsStatus(kits: any[]) {
  return kits.map((kit, index) =>
    index === 0
      ? {
          ...kit,
          status: PickingItemStatus.checking,
          kitDays: setKitDaysStatus(kit.kitDays),
        }
      : {
          ...kit,
          status: PickingItemStatus.waiting,
        }
  );
}

function setMealsStatus(meals: any[]) {
  return meals.map((meal, index) => ({
    ...meal,
    status: index < 2 ? PickingItemStatus.checking : PickingItemStatus.waiting,
  }));
}

function setProductsStatus(products: any[]) {
  return products.map((product, index) => ({
    ...product,
    status: index < 7 ? PickingItemStatus.checking : PickingItemStatus.waiting,
  }));
}
