import React from 'react';
import 'babel-polyfill';
import { ApolloProvider } from 'react-apollo';
import styled, { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';
import { v2 } from '../src/theme';
import { client } from 'src/apollo/client';
import JssProvider from 'react-jss/lib/JssProvider';
import { createMuiTheme, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import RootContainer from './common/rootContainer';
import { AUTH_TOKEN, EMAIL } from 'src/common/constants';
import { NotificationIcon } from 'src/common/notificationIcon';
import { Check } from 'src/common/icons/check';
import { Error } from 'src/common/icons/error';

const token = localStorage.getItem(AUTH_TOKEN);
const user = localStorage.getItem(EMAIL);

const StyledSnackbarProvider = styled(SnackbarProvider)`
  && {
    [class*='MuiTypography-root'] {
      width: 568px;
    }
  }
`;

function createGenerateClassName() {
  let ruleCounter = 0;

  return (rule, sheet) => {
    ruleCounter += 1;

    if (sheet && sheet.options.meta) {
      return `${sheet.options.meta}-${rule.key}-${ruleCounter}`;
    }

    return `${rule.key}-${ruleCounter}`;
  };
}

const generateClassName = createGenerateClassName();

const jss = create({
  ...jssPreset(),
  // Define a custom insertion for injecting the JSS styles in the DOM
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const App = () => (
  <ApolloProvider client={client}>
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <ThemeProvider theme={createMuiTheme(v2)}>
        <StyledSnackbarProvider
          iconVariant={{
            success: (
              <NotificationIcon>
                <Check />
              </NotificationIcon>
            ),
            error: (
              <NotificationIcon>
                <Error />
              </NotificationIcon>
            ),
          }}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <RootContainer token={token} user={user} />
        </StyledSnackbarProvider>
      </ThemeProvider>
    </JssProvider>
  </ApolloProvider>
);

export default App;
