import React from 'react';

export const Check = () => (
  <svg width="37px" height="37px" viewBox="0 0 37 37" version="1.1">
    <title>check_line</title>
    <desc>Created with Sketch.</desc>
    <g id="02_Protótipos_Logistics" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="iPhone-8-Copy-7" transform="translate(-25.000000, -71.000000)" fill="#FFFFFF" fill-rule="nonzero">
        <g id="check_line" transform="translate(25.000000, 71.000000)">
          <path
            d="M35.5095215,11.393543 C34.5592285,9.20411133 33.2074277,7.2240332 31.4916973,5.50830273 C29.7759668,3.79257227 27.7958887,2.44077148 25.606457,1.49047852 C23.3279941,0.501451172 20.9370137,0 18.5,0 C16.0629863,0 13.6720059,0.501451172 11.393543,1.49047852 C9.20411133,2.44077148 7.2240332,3.79257227 5.50830273,5.50830273 C3.79257227,7.2240332 2.44077148,9.20411133 1.49047852,11.393543 C0.501451172,13.6720059 0,16.0629863 0,18.5 C0,20.9370137 0.501451172,23.3279941 1.49047852,25.606457 C2.44069922,27.7958887 3.7925,29.7759668 5.50830273,31.4916973 C7.2240332,33.2074277 9.20411133,34.5592285 11.393543,35.5095215 C13.6720059,36.4985488 16.0629863,37 18.5,37 C20.9370137,37 23.3279941,36.4985488 25.606457,35.5095215 C27.7958887,34.5592285 29.7759668,33.2074277 31.4916973,31.4916973 C33.2074277,29.7759668 34.5592285,27.7958887 35.5095215,25.606457 C36.4985488,23.3279941 37,20.9370137 37,18.5 C37,16.0629863 36.4985488,13.6720059 35.5095215,11.393543 Z M18.5,35.5546875 C9.25541992,35.5546875 1.4453125,27.7445801 1.4453125,18.5 C1.4453125,9.25541992 9.25541992,1.4453125 18.5,1.4453125 C27.7445801,1.4453125 35.5546875,9.25541992 35.5546875,18.5 C35.5546875,27.7445801 27.7445801,35.5546875 18.5,35.5546875 Z"
            id="Shape"
          ></path>
          <path
            d="M32.7610915,12.5186009 C31.9700822,10.680723 30.8426573,9.02244131 29.410108,7.58996479 C27.9775587,6.15741549 26.3193498,5.02999061 24.4814718,4.23898122 C22.5713333,3.41682629 20.5588803,3 18.5,3 C15.7011221,3 12.7835563,3.95576056 10.0626878,5.76387559 C9.72794601,5.98633333 9.6369108,6.43801643 9.85936854,6.77268545 C10.0818263,7.10742723 10.5335094,7.19838967 10.8681784,6.97600469 C13.3496338,5.32703756 15.9886362,4.45539906 18.5,4.45539906 C26.1129742,4.45539906 32.5446009,10.8870258 32.5446009,18.5 C32.5446009,26.1129742 26.1129742,32.5446009 18.5,32.5446009 C10.8870258,32.5446009 4.45539906,26.1129742 4.45539906,18.5 C4.45539906,15.9891455 5.32681925,13.3499977 6.97534977,10.8681056 C7.19773474,10.5332911 7.10662676,10.0816808 6.77188498,9.85929577 C6.43699765,9.6369108 5.98546009,9.72809155 5.76307512,10.0628333 C3.95546948,12.7842113 3,15.7017042 3,18.5 C3,20.5588803 3.41682629,22.5713333 4.23890845,24.4813991 C5.02991784,26.319277 6.15734272,27.9775587 7.58989202,29.4100352 C9.02244131,30.8426573 10.6806502,31.9700094 12.5186009,32.7610915 C14.4286667,33.5831737 16.4411197,34 18.5,34 C20.5588803,34 22.5713333,33.5831737 24.4813991,32.7610915 C26.319277,31.9700822 27.9775587,30.8426573 29.4100352,29.410108 C30.8425845,27.9775587 31.9700094,26.3193498 32.7610188,24.4814718 C33.5831737,22.5713333 34,20.5588803 34,18.5 C34,16.4411197 33.5831737,14.4286667 32.7610915,12.5186009 Z"
            id="Path"
          ></path>
          <path
            d="M8,7 C7.448,7 7,7.448 7,8 C7,8.552 7.448,9 8,9 C8.552,9 9,8.552 9,8 C9,7.448 8.552,7 8,7 Z"
            id="Path"
          ></path>
          <path
            d="M27.1436115,12.82033 C26.5913692,12.29134 25.8574577,12 25.0769231,12 C24.2963885,12 23.5624769,12.29134 23.0102346,12.82033 L16.3076923,19.24066 L13.9897654,17.02033 C13.4375231,16.49134 12.7036115,16.2 11.9230769,16.2 C11.1425423,16.2 10.4086308,16.49134 9.85660769,17.02012 C9.30421923,17.54883 9,18.25191 9,19 C9,19.74809 9.30421923,20.45117 9.85638846,20.97967 L14.2410038,25.17967 C14.7932462,25.70866 15.5272308,26 16.3076923,26 C17.0881538,26 17.8221385,25.70866 18.3743808,25.17967 L27.1433923,16.77988 C27.6957808,16.25117 28,15.54809 28,14.8 C28,14.05191 27.6957808,13.34883 27.1436115,12.82033 Z M26.1101577,15.78973 L17.3409269,24.18973 C17.0646962,24.45426 16.6977769,24.6 16.3076923,24.6 C15.9176077,24.6 15.5506885,24.45426 15.2744577,24.18973 L10.8896231,19.98952 C10.6136115,19.72527 10.4615385,19.37387 10.4615385,19 C10.4615385,18.62613 10.6136115,18.27473 10.8898423,18.01027 C11.1660731,17.74574 11.5329923,17.6 11.9230769,17.6 C12.3131615,17.6 12.6800808,17.74574 12.9563115,18.01027 L16.3076923,21.22054 L24.0436885,13.81027 C24.3199192,13.54574 24.6868385,13.4 25.0769231,13.4 C25.4670077,13.4 25.8339269,13.54574 26.1103769,13.81048 C26.3863885,14.07473 26.5384615,14.42613 26.5384615,14.8 C26.5384615,15.17387 26.3863885,15.52527 26.1101577,15.78973 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
