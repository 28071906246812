import React from 'react';

export const SuccessBag = () => (
  <svg width="186px" height="184px" viewBox="0 0 186 184" version="1.1">
    <title>Group</title>
    <desc>Created with Sketch.</desc>
    <g id="04_Protótipos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Sacola_plastico-Copy-2" transform="translate(-457.000000, -282.000000)">
        <g id="Group" transform="translate(459.000000, 284.000000)">
          <g id="Group-5" transform="translate(44.000000, 40.000000)">
            <path
              d="M97,1 L97,65.1277427 L84.6280195,59.7156371 L59.4019955,69.7340426 L2,92 L2,1 L97,1 Z M48.325835,11.1648936 L25.5145148,11.1648936 C22.306563,11.1648936 19.7060042,13.7654524 19.7060042,16.9734043 C19.7060042,20.1813561 22.306563,22.7819149 25.5145148,22.7819149 L25.5145148,22.7819149 L48.325835,22.7819149 C51.5337868,22.7819149 54.1343456,20.1813561 54.1343456,16.9734043 C54.1343456,13.7654524 51.5337868,11.1648936 48.325835,11.1648936 L48.325835,11.1648936 Z"
              id="Combined-Shape"
              fill="#E57374"
            ></path>
            <polygon id="Path-6" fill="#CC5253" points="71 2 71 64.7628309 84.3675567 59.5175425 97 65 96 2"></polygon>
            <path
              d="M96,1.5 L2,1.5 C1.72385763,1.5 1.5,1.72385763 1.5,2 L1.5,115 C1.5,115.276142 1.72385763,115.5 2,115.5 L96,115.5 C96.2761424,115.5 96.5,115.276142 96.5,115 L96.5,2 C96.5,1.72385763 96.2761424,1.5 96,1.5 Z M48.05,9.7125 C52.1092929,9.7125 55.4,13.0032071 55.4,17.0625 C55.4,21.1217929 52.1092929,24.4125 48.05,24.4125 L25.45,24.4125 C21.3907071,24.4125 18.1,21.1217929 18.1,17.0625 C18.1,13.0032071 21.3907071,9.7125 25.45,9.7125 L48.05,9.7125 Z"
              id="Combined-Shape"
              stroke="#004D69"
              stroke-width="3"
            ></path>
            <path
              d="M73,2 L72.5,115"
              id="Path-2"
              stroke="#004D69"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M84.5,45 L84.5,101.625185"
              id="Path-2-Copy"
              stroke="#004D69"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M84.5384615,101.612034 L73,115.193983"
              id="Path-2-Copy-3"
              stroke="#004D69"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M95.5,101.612034 L84.25,115.41058"
              id="Path-2-Copy-4"
              stroke="#004D69"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="translate(90.000000, 108.500000) scale(-1, 1) translate(-90.000000, -108.500000) "
            ></path>
            <path
              d="M84.5,1 L84.5,34"
              id="Path-2-Copy-2"
              stroke="#004D69"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <g id="logo-rgb-branco-copy" transform="translate(26.000000, 34.000000)" fill="#FFFFFF">
              <path
                d="M8.0496713,13.8905381 C8.19563089,14.3731781 8.32842908,14.4897428 8.40175044,15.129067 C8.47511734,15.7681612 8.98413151,15.4424352 9.24708649,15.1793025 C9.51081567,14.9161122 10.2567808,14.4146195 10.565277,12.8097854 C10.737605,11.9146856 10.9514665,8.97412787 10.9971443,8.16909558 C11.0426856,7.36394834 10.535402,7.93205766 10.0555342,8.12541255 C9.53941556,8.33376912 8.52739865,9.31910886 8.31677053,9.78542522 C8.10573253,10.2515691 7.90366616,13.4077831 8.0496713,13.8905381"
                id="Fill-1"
              ></path>
              <path
                d="M7.91225711,2.97442418 C7.92551403,2.9313935 7.87806477,2.89531049 7.8287123,2.90931787 C7.18443942,3.09180605 5.58409367,2.75041812 4.3380094,4.0239133 C3.24346085,5.14248684 2.83361221,3.56828117 1.74582337,5.57744407 C1.01367421,6.92949266 0.630076879,9.22575087 0.341968704,10.9647394 C-0.14105548,13.8772664 -0.0735896025,17.0524599 0.302657361,19.5506485 L0.302919874,19.5522173 C0.421903956,20.4430308 0.963009173,20.3487891 1.33866548,20.7012709 C1.71464993,21.0539208 2.30556702,21.3194447 2.80919848,21.1295606 C3.31309246,20.9393404 4.20032126,21.7149572 4.90831921,21.0111142 C5.61631716,20.3070471 6.60677925,19.2299915 8.13066805,18.0974106 C9.62863367,16.9843279 12.1890556,15.5529976 12.9729855,15.3126309 C12.9957585,15.3054591 13.0060622,15.2846161 12.9963492,15.2655661 C12.9518532,15.1747422 12.813115,14.9406508 12.5631368,14.9335911 C12.2525182,14.9247945 12.1527632,14.9483829 11.7185008,14.7899874 C11.4263237,14.6835313 11.5311977,14.493199 10.0124279,14.9758934 C8.25975889,15.5333872 6.71736291,16.1715636 5.4493588,16.948413 C5.40866926,16.9726178 5.28240044,17.0529081 5.26284321,17.0650105 C3.51719643,18.18745 4.92538256,11.5909814 5.82074927,8.76210652 C6.15952248,7.69205456 7.69588065,3.6861673 7.91225711,2.97442418"
                id="Fill-2"
              ></path>
              <path
                d="M10.4379261,5.62537055 C10.772786,5.84950224 11.2672631,6.01099256 11.8916327,5.26018472 C13.329968,3.53088322 13.6116902,2.46144852 11.2588649,3.08608511 C9.57830451,3.53208274 9.88649377,5.25654175 10.4379261,5.62537055"
                id="Fill-3"
              ></path>
              <path
                d="M27.9964855,0.661458083 C27.8991348,0.619725075 27.81393,0.559563769 27.7342793,0.492824979 C27.3313876,0.154538126 25.9774491,-0.0725552102 25.5874357,0.0212873545 C22.1357867,0.85254536 15.6736495,8.27030395 15.6701705,8.20469921 C15.6273239,7.4332623 15.4337211,6.4960274 14.8256298,5.9025795 C14.5750202,5.6580218 13.9171244,5.65297529 13.734813,5.64208841 C12.7241961,5.58096317 12.8908216,11.1930887 13.4295161,12.4430944 C13.4643061,12.5240088 13.5503043,12.5727162 13.644054,12.568747 C14.4933555,12.5318904 15.1251283,12.8080881 15.894535,12.1253905 C17.5192856,10.6840708 19.6196816,8.19426596 20.835498,7.17730741 C22.1975541,6.03792826 22.1809526,6.74330681 22.7155578,6.15768385 C23.8641747,4.90008007 26.6916191,3.56995386 28.3942503,2.52152545 C29.7318315,1.69752536 28.5111933,0.882200744 27.9964855,0.661458083"
                id="Fill-4"
              ></path>
              <path
                d="M22.3999349,10.6572887 C19.3906698,10.7136233 15.3867193,12.6615822 14.2592494,15.2213823 C13.1270487,17.7929408 12.7474883,22.7157504 13.1645706,24.9132076 C13.4948795,28.0286315 14.0590681,29.1967984 14.7737329,29.0503754 C14.8036726,29.04435 14.8288816,29.0268587 14.8472861,29.0048631 C15.0697602,28.7361771 15.3936534,28.5558246 15.5189206,28.4535683 C15.6765254,28.324695 16.3708415,27.9245617 16.2680615,27.3845572 C16.243695,27.2566199 16.230086,27.1279805 16.2284659,26.9981712 C16.1875094,24.1711475 17.2401949,15.092626 18.8244722,13.2866442 C19.7321251,12.2520305 21.1296356,12.6612312 21.4350596,13.1132531 C22.5939597,14.8282689 21.2762884,16.346728 21.2458302,16.9037557 C21.2227598,17.33191 21.5329793,17.3423228 21.3750506,17.7578999 C20.856614,19.1199911 18.6088026,20.4457544 17.1234818,20.9036262 C17.0874505,20.914741 17.0707957,20.94791 17.0868673,20.9792654 C17.1377388,21.0811122 17.2675424,21.2849813 17.4886556,21.2915332 C17.7838404,21.3002496 17.8786495,21.27685 18.2913898,21.4356748 C20.3876881,21.4728802 24.4651917,18.0424976 25.3662345,16.2857135 C26.768735,13.5514108 25.8707381,10.5927058 22.3999349,10.6572887"
                id="Fill-5"
              ></path>
              <path
                d="M12.3239945,18.4419056 C11.3630305,18.663186 9.74826451,19.5631382 9.34884089,19.9019984 C9.02473286,20.0747583 8.93222022,20.4531848 8.88765851,21.0451128 C8.82960275,21.8149493 8.57089374,21.5562851 8.51384219,22.2397138 C8.48114268,22.6279899 8.46199997,23.1170566 8.46407115,23.6531888 C8.46871561,24.7661947 8.56643757,26.0841397 8.83631839,27.1377681 C8.08925062,29.3670818 5.72088953,30.4630748 6.00677491,26.977712 C6.14447689,25.3004241 6.41925322,23.8288028 6.9304577,22.5370489 C6.99215371,22.3239951 6.68831812,22.2196788 6.25694815,22.3338447 C5.40048451,22.5608334 4.09808989,23.3847869 3.78264314,23.6712091 C3.50290854,23.859303 3.42357613,24.2255854 3.38780123,24.8085032 C3.34091728,25.5670351 3.11509607,25.3169334 3.06896528,25.9901207 C2.93521737,27.9376572 2.7405266,30.3321198 5.22625447,30.9043485 C7.27546581,31.3762344 9.11423287,29.9994156 9.96398087,29.0194354 C9.96398087,29.0194354 10.0236057,29.1064589 10.2144052,29.2780436 C10.3180896,29.371447 10.4155605,29.4342383 10.4642646,29.4636192 C10.4834701,29.4749239 10.50732,29.4708945 10.5220693,29.4557283 C10.678914,29.2938254 10.9837538,29.1097048 11.5864667,28.8838353 C11.7370979,28.8561333 11.7816596,28.6328382 11.9561407,28.5197356 C12.1311239,28.4068008 12.2233854,28.4163706 12.2737213,28.2725439 C12.3239945,28.1288292 12.2659387,28.1665487 12.1881754,27.9391122 C11.8786284,27.0409509 11.984384,24.2189817 12.341066,21.9875414 C12.4717386,21.1708632 12.7408035,19.51417 12.9927968,18.6603879 C13.0503505,18.4633397 12.7571218,18.3421783 12.3239945,18.4419056"
                id="Fill-6"
              ></path>
            </g>
          </g>
          <polygon
            id="Star"
            stroke="#004D69"
            stroke-width="3"
            fill="#EEC76B"
            stroke-linejoin="round"
            points="16.5 76.8722098 9.15268435 79.7082039 9.69181257 72.1236296 4.61179355 66.2917961 12.2923088 64.4402655 16.5 58 20.7076912 64.4402655 28.3882065 66.2917961 23.3081874 72.1236296 23.8473157 79.7082039"
          ></polygon>
          <polygon
            id="Star-Copy"
            stroke="#004D69"
            stroke-width="3"
            fill="#EEC76B"
            stroke-linejoin="round"
            points="39.5 19.6585519 32.1526843 22.6127124 32.6918126 14.7121142 27.6117935 8.63728757 35.2923088 6.70860986 39.5 0 43.7076912 6.70860986 51.3882065 8.63728757 46.3081874 14.7121142 46.8473157 22.6127124"
          ></polygon>
          <polygon
            id="Star-Copy-2"
            stroke="#004D69"
            stroke-width="3"
            fill="#EEC76B"
            stroke-linejoin="round"
            points="6 32.4361049 2.47328849 33.854102 2.73207003 30.0618148 0.293660902 27.145898 3.98030821 26.2201327 6 23 8.01969179 26.2201327 11.7063391 27.145898 9.26792997 30.0618148 9.52671151 33.854102"
          ></polygon>
          <polygon
            id="Star-Copy-3"
            stroke="#004D69"
            stroke-width="3"
            fill="#EEC76B"
            stroke-linejoin="round"
            points="173 147.154157 167.709933 149.281153 168.098105 143.592722 164.440491 139.218847 169.970462 137.830199 173 133 176.029538 137.830199 181.559509 139.218847 177.901895 143.592722 178.290067 149.281153"
          ></polygon>
          <polygon
            id="Star-Copy-4"
            stroke="#004D69"
            stroke-width="3"
            fill="#EEC76B"
            stroke-linejoin="round"
            points="83 24.7268415 77.1221475 27.0901699 77.5534501 20.7696914 73.4894348 15.9098301 79.633847 14.3668879 83 9 86.366153 14.3668879 92.5105652 15.9098301 88.4465499 20.7696914 88.8778525 27.0901699"
          ></polygon>
          <polygon
            id="Star-Copy-5"
            stroke="#004D69"
            stroke-width="3"
            fill="#EEC76B"
            stroke-linejoin="round"
            points="163 177.726842 157.122147 180.09017 157.55345 173.769691 153.489435 168.90983 159.633847 167.366888 163 162 166.366153 167.366888 172.510565 168.90983 168.44655 173.769691 168.877853 180.09017"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);
