import { FetchSaleQuery_sale_cart_items } from 'src/apollo/types/FetchSaleQuery';
import { Marketable, Marketables, ProductTypes } from 'src/common/types';

export const filterByKind = (kind: Marketables) => (item: FetchSaleQuery_sale_cart_items | Marketable) =>
  kind === item.kind;

export const filterByTypes = (types: ProductTypes[]) => (item: FetchSaleQuery_sale_cart_items) =>
  types.includes(item.item.type as ProductTypes);

export const filterBySkuInitials = (initials: string) => (item: FetchSaleQuery_sale_cart_items) =>
  initials.toUpperCase() === item.item.sku.slice(0, initials.length).toUpperCase();

export const filterOutBySkuInitials = (initialsList: string[]) => (item: FetchSaleQuery_sale_cart_items) => 
  initialsList.every(initials => initials.toUpperCase() !== item.item.sku.slice(0, initials.length).toUpperCase());
