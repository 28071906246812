import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const StyledGrid = styled(props => (
	<Grid
		classes={{ item: 'item', 'spacing-xs-16': 'spacing16', 'spacing-xs-24': 'spacing24' }}
		{...props}
	/>
))`
	& .item {
		box-sizing: 'border-box';
		margin: '0';
	}
	& .spacing16 {
		margin: ${props => `-${(props.theme.spacing.unit * 3) / 2}px`};
		width: calc(100% + ${props => props.theme.spacing.unit * 3}px);
	}
	& .spacing24 {
		margin: ${props => `-${(props.theme.spacing.unit * 6) / 2}px`};
		width: calc(100% + ${props => props.theme.spacing.unit * 6}px);
	}
	& .spacing16 .item {
		padding: ${props => (props.theme.spacing.unit * 3) / 2}px;
	}
	& .spacing24 .item {
		padding: ${props => (props.theme.spacing.unit * 6) / 2}px;
	}
`;
