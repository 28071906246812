import React, { FormEvent, useState } from 'react';
import { StyledDiv } from './styles';
import { StyledFormControlLabel, StyledRadio } from './styles';
export interface RadioProps {
  labels: string[];
  direction: 'row' | 'column';
  onSelect: (value: string) => void;
  fontSize?: string;
  selected: string
}

export const Radio = ({ labels, direction, onSelect, fontSize, selected, ...props }: RadioProps) => {
  const handleSelect = (event: FormEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    onSelect(value);
  };
  return (
    <StyledDiv direction={direction} {...props}>
      {labels.map((label, index) => (
        <StyledFormControlLabel
          control={
            <StyledRadio
              checked={selected === label}
              onChange={handleSelect}
              value={index}
            />
          }
          label={label}
          fontSize={fontSize}
        />
      ))}
    </StyledDiv>
  );
};
