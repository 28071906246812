import momentTimezone from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { AllProductsQuery } from 'src/apollo/types/AllProductsQuery';
import { FetchSaleQuery, FetchSaleQueryVariables } from 'src/apollo/types/FetchSaleQuery';
import { DeliveryModifier, PickingEventTypes } from 'src/apollo/types/globalTypes';
import { LogEventMutation, LogEventMutationVariables } from 'src/apollo/types/LogEventMutation';
import { AUTH_TOKEN, DISTRIBUTION_CENTER, EMAIL, LOGISTIC_KIND, PC_ID, PRODUCT_TYPE } from 'src/common/constants';
import { errorFormat } from 'src/common/errorFormatting';
import { SaleInfo } from 'src/common/saleInfo/organisms/SaleInfo';
import { PickingStatusContainer } from 'src/common/status';
import {
  FormattedSale,
  LogisticKinds,
  LogisticKindsPT,
  PickingTypes,
  PickingTypesPT,
} from 'src/common/types';
import { StartPickingMutation, StartPickingMutationVariables } from '../../apollo/types/StartPickingMutation';
import { PageTitle } from '../../components/molecules/pageTitle';
import {
  CANCEL_PICKING_MUTATION,
  FETCH_PRODUCTS_QUERY,
  FETCH_SALE,
  FINISH_PICKING_MUTATION,
  LOG_EVENT_MUTATION,
  PRINT_NFE_MUTATION,
  PRINT_TAGS_MUTATION,
  START_PICKING_MUTATION,
} from './graphql';
import { dcHasMultipleStations } from './logic/dcHasMultipleStations';
import { formatProductsBySku } from './logic/formatting/formatProductsBySku';
import { formatSaleForPicking } from './logic/formatting/formatSaleForPicking';
import { getTotalBags } from './logic/getTotalBags';
import { pcIdIsValid } from './logic/pcIdIsValid';
import { printTags, shouldPrintNfe } from './logic/printing';
import { ExpressOrderIndicator } from './organisms/expressOrderIndicator';
import { OrdersPerPeriodContainer } from './organisms/ordersPerPeriodContainer';
import { PickingFinished } from './organisms/pickingFinished';
import { PickingInitialForm } from './organisms/pickingInitialForm';
import { StepperContainer } from './organisms/stepper';
import {
  InitialFormAndStatusContainer,
  InitialFormContainer,
  OrdersPerPeriodAndSaleInfoContainer,
  StatusContainer,
  StepperAndInfosContainer,
  StyledContainer,
} from './styles';

export interface LoginProps {
  refreshToken?: (data: any) => void;
}

export interface FinishPickingParams {
  packageCodes?: string[];
  numberOfPackages?: string;
  pickingProblems?: any;
  productBatches?: string[];
}

const Picking: React.FC<LoginProps> = ({ ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [distributionCenter, setDistributionCenter] = useState(
    localStorage.getItem(DISTRIBUTION_CENTER) && JSON.parse(localStorage.getItem(DISTRIBUTION_CENTER))
  );
  const [productType, setProductType] = useState(localStorage.getItem(PRODUCT_TYPE));
  const [logisticKind, setLogisticKind] = useState(localStorage.getItem(LOGISTIC_KIND) as LogisticKinds);
  const [pcId, setPcId] = useState((localStorage.getItem(PC_ID) && Number(localStorage.getItem(PC_ID))) || 0);
  const [order, setOrder] = useState('');
  const [isExpressDelivery, setIsExpressDelivery] = useState(false);
  const [hasNfe, setHasNfe] = useState(false);
  const [productsBySku, setProductsBySku] = useState();
  const [packageCodes, setPackageCodes] = useState([]);
  const [numberOfPackages, setNumberOfPackages] = useState('');
  const [pickingProblems, setPickingProblems] = useState();
  const [packageSize, setPackageSize] = useState<string>();
  const [alreadyFinishedPicking, setAlreadyFinishedPicking] = useState(false);
  const [rejectedBatches, setRejectedBatches] = useState([]);
  const [firstOrder, setFirstOrder] = useState(false);
  const [hasBougtGreenGrocer, setHasBougtGreenGrocer] = useState(false);
  const [_secondOrder, setSecondOrder] = useState(false);
  const [observations, setObservations] = useState('');
  const [insideObservations, setInsideObservations] = useState('');
  const [period, setPeriod] = useState('');
  const [dateString, setDateString] = useState('');
  const [formattedSale, setFormattedSale] = useState<FormattedSale>();
  const [user, setUser] = useState('');
  const [step, setStep] = useState(0);
  const [finishSuccess, setFinishSuccess] = useState(false);
  const [totalTags, setTotalTags] = useState(0);
  const [fetchSale, setFetchSale] = useState(false);
  const [finishPickingParams, setFinishPickingParams] = useState<FinishPickingParams>({});
  const [hasBabyFood, setHasBabyFood] = useState(false);
  const [promotionalItems, setPromotionalItems] = useState([]);

  const handleProductsFetched = (data: AllProductsQuery) => {
    if (data && data.products) {
      setProductsBySku(formatProductsBySku(data.products));
    }
  };
  const handleError = (error: Error) => {
    enqueueSnackbar(errorFormat(error), {
      variant: 'error',
    });
  };

  const { loading: productsLoading } = useQuery<AllProductsQuery>(FETCH_PRODUCTS_QUERY, {
    skip: productsBySku,
    onCompleted: !productsBySku && handleProductsFetched,
    onError: handleError,
  });

  const handleNextPickingError = (error: Error) => {
    if (errorFormat(error) === 'Pedido já separado.') {
      setFetchSale(true);
      setAlreadyFinishedPicking(true);
    } else {
      setOrder('');
      setStep(0);
      setFinishSuccess(false);
      handleError(error);
    }
  };

  const [startPickingMutation, { loading: startingPicking }] = useMutation<
    StartPickingMutation,
    StartPickingMutationVariables
  >(START_PICKING_MUTATION, {
    variables: {
      input: {
        distributionCenter: distributionCenter && distributionCenter.value,
        productType,
        logisticKind,
      },
    },
    onError: handleNextPickingError,
  });

  const [logEventMutation] = useMutation<LogEventMutation, LogEventMutationVariables>(LOG_EVENT_MUTATION);

  const handleSaleFetched = (data: FetchSaleQuery) => {
    if (data && data.sale) {
      try {
        const newFormattedSale = formatSaleForPicking(
          data.sale,
          productsBySku,
          productType as PickingTypes,
          firstOrder,
          packageSize
        );
        setHasBabyFood(!!data.sale.cart.items.find(item => item.item.type === 'babyFood'));
        setPeriod(data.sale.cart.deliverySettings.chosenPeriod.id);
        setDateString(data.sale.cart.deliverySettings.chosenDateString);
        setIsExpressDelivery(data.sale.cart.deliverySettings.deliveryModifier === DeliveryModifier.EXPRESS);
        setUser(data.sale.user.name);
        setFinishSuccess(false);
        setFetchSale(false);
        setObservations(data.sale.observations);
        setInsideObservations(data.sale.insideObservations && data.sale.insideObservations.text);
        setFormattedSale(newFormattedSale);
        const quantity = !alreadyFinishedPicking ? getTotalBags(newFormattedSale.packageSizes) : 0;
        setTotalTags(quantity);

        if (!alreadyFinishedPicking) {
          printTags({
            ...(dcHasMultipleStations(distributionCenter.value) && { pcId: String(pcId) }),
            order,
            quantity,
            productType,
            print: printTagsMutation,
          });
          if (shouldPrintNfe(data.sale, distributionCenter)) {
            printNfeMutation({ variables: { order } });
            setHasNfe(true);
          } else {
            setHasNfe(false);
          }
        } else if (shouldPrintNfe(data.sale, distributionCenter)) {
          setHasNfe(true);
        }
        setStep(1);
      } catch (err) {
        setFinishSuccess(false);
        setFetchSale(false);
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
        handleCancel();
      }
    }
  };

  const handleFinished = () => {
    setStep(2);
    setFinishSuccess(true);
  };

  const handleFetchSaleError = (error: Error) => {
    handleCancel();
    handleError(error);
  };
  const handleFinishPickingError = (error: Error) => {
    setStep(2);
    setFinishSuccess(false);
    handleError(error);
  };

  const { loading: fetchingSale } = useQuery<FetchSaleQuery, FetchSaleQueryVariables>(FETCH_SALE, {
    skip: !fetchSale,
    fetchPolicy: 'network-only',
    onCompleted: handleSaleFetched,
    variables: { order: Number(order) },
    onError: handleFetchSaleError,
  });

  const [printNfeMutation] = useMutation(PRINT_NFE_MUTATION, {
    onError: handleError,
  });

  const [printTagsMutation] = useMutation(PRINT_TAGS_MUTATION);

  const [cancelPickingMutation] = useMutation(CANCEL_PICKING_MUTATION, {
    variables: { input: { order, productType } },
  });

  const [finishPickingMutation, { loading: finishingPicking }] = useMutation(FINISH_PICKING_MUTATION, {
    onCompleted: handleFinished,
    onError: handleFinishPickingError,
  });

  const nextPicking = async ({ pickingLine = false }) => {
    const invalidPcId = dcHasMultipleStations(distributionCenter.value) && !pcIdIsValid(pcId);
    if (!distributionCenter) {
      enqueueSnackbar('Selecione o centro de distribuição', {
        variant: 'error',
      });
      return;
    }
    if (!productType) {
      enqueueSnackbar('Informe o tipo de produto', {
        variant: 'error',
      });
      return;
    }
    if (!logisticKind) {
      enqueueSnackbar('Informe o tipo do cliente', {
        variant: 'error',
      });
      return;
    }
    if (invalidPcId) {
      enqueueSnackbar('Informe o número do seu computador', {
        variant: 'error',
      });
      return;
    }

    const {
      data: { startPicking },
    } = await startPickingMutation({
      variables: {
        input: {
          distributionCenter: distributionCenter.value,
          productType,
          logisticKind,
          ...(pcId && pcId !== 0 && { pcId }),
          ...(!pickingLine && order && order !== '' && { order }),
        },
      },
    });

    if (startPicking && startPicking.order) {
      logEventMutation({
        variables: {
          input: {
            event: PickingEventTypes.BAG_PICKING,
            order: startPicking.order,
            user: localStorage.getItem(EMAIL),
            payload: order && order !== '' ? 'manual' : 'picking line',
            timestamp: momentTimezone()
              .tz('America/Sao_Paulo')
              .format(),
          },
        },
      });
      setRejectedBatches(startPicking.rejectedBatches);
      setFirstOrder(startPicking.first);
      setSecondOrder(startPicking.second);
      setHasBougtGreenGrocer(startPicking.hasBoughtGreenGrocer);
      setOrder(startPicking.order);
      setPackageSize(startPicking.packageSize);
      setPromotionalItems(startPicking.promotionalItems);
      setFetchSale(true);
    }
  };

  const handleOrderInputChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setOrder(value);
  };

  const handlePcIdInputChange = (event: any) => {
    const {
      target: { value },
    } = event;
    localStorage.setItem(PC_ID, String(Number(value)));
    setPcId(Number(value));
  };

  const handleDistributionCenterSelect = (option: any) => {
    localStorage.setItem(DISTRIBUTION_CENTER, JSON.stringify(option));
    setDistributionCenter(option);
  };

  const handleProductTypeSelect = (index: string) => {
    localStorage.setItem(PRODUCT_TYPE, Object.keys(PickingTypesPT)[Number(index)]);
    setProductType(Object.keys(PickingTypesPT)[Number(index)]);
  };

  const handleLogisticKindSelect = (index: string) => {
    const value = Object.keys(LogisticKinds)[Number(index)] as LogisticKinds;
    localStorage.setItem(LOGISTIC_KIND, value);
    setLogisticKind(value);
  };

  const handleFinishPicking = async (params: FinishPickingParams) => {
    setFinishPickingParams(params);
    setPackageCodes(params.packageCodes);
    setNumberOfPackages(params.numberOfPackages);
    setPickingProblems(params.pickingProblems);
    try {
      await finishPickingMutation({
        variables: {
          input: {
            order,
            productBatches: params.productBatches,
            distributionCenter: distributionCenter.value,
            productType,
            packageCodes: params.packageCodes || packageCodes,
            numberOfPackages: params.numberOfPackages || numberOfPackages || null,
            pickingProblems: params.pickingProblems || pickingProblems,
          },
        },
      });
    } catch (e) {}
  };

  const handleCancel = () => {
    if (order) {
      cancelPickingMutation({
        variables: {
          input: {
            order,
            productType,
          },
        },
      });
      setOrder('');
    }
    setStep(0);
  };

  const handleForceCancel = () => {
    if (order) {
      const cancelBody = {
        query: `
    mutation CancelPickingMutation($input: CancelPicking!) {
      cancelPicking(input: $input)
    }
  `,
        operationName: 'CancelPickingMutation',
        variables: { input: { order, productType } },
      };

      const authToken = localStorage.getItem(AUTH_TOKEN);

      const client = new XMLHttpRequest();
      client.open('POST', `${process.env.DOBBY_API}/graphql`, false); // third parameter indicates sync xhr
      client.setRequestHeader('content-type', 'application/json');
      client.setRequestHeader('authorization', `Bearer ${authToken}`);
      client.send(JSON.stringify(cancelBody));
    }
  };

  const handleBack = () => {
    setOrder('');
    setStep(0);
    setAlreadyFinishedPicking(false);
  };

  const renderPageTitle = () =>
    step === 0 ? <PageTitle>Iniciar Picking</PageTitle> : <PageTitle>Estação de Picking</PageTitle>;

  const renderPickingInitialForm = () =>
    step === 0 && (
      <InitialFormAndStatusContainer>
        <InitialFormContainer>
          <PickingInitialForm
            selectedDistributionCenter={distributionCenter}
            onDistributionCenterSelect={handleDistributionCenterSelect}
            onProductTypeSelect={handleProductTypeSelect}
            onLogisticKindSelect={handleLogisticKindSelect}
            productTypes={Object.values(PickingTypesPT)}
            logisticKindsValues={Object.values(LogisticKinds)}
            logisticKindsLabels={Object.values(LogisticKindsPT)}
            logisticKind={logisticKind}
            // @ts-ignore
            pickingType={PickingTypesPT[productType]}
            onOrderInputChange={handleOrderInputChange}
            onPcIdInputChange={handlePcIdInputChange}
            nextPicking={nextPicking}
            loadingSale={startingPicking || fetchingSale || productsLoading}
            onError={handleError}
            pcId={pcId}
          />
        </InitialFormContainer>
        <StatusContainer>
          <PickingStatusContainer
            distributionCenter={distributionCenter && distributionCenter.value}
            size="large"
            productType={productType}
            logisticKind={logisticKind}
            onError={handleError}
          />
        </StatusContainer>
      </InitialFormAndStatusContainer>
    );

  const renderStepper = () =>
    step === 1 && (
      <StepperContainer
        onBack={handleBack}
        alreadyFinishedPicking={alreadyFinishedPicking}
        order={order}
        isExpressDelivery={isExpressDelivery}
        productType={productType}
        productsBySku={productsBySku}
        sale={formattedSale}
        onFinish={handleFinishPicking}
        totalTags={totalTags}
        observations={observations}
        insideObservations={insideObservations}
        onCancel={handleCancel}
        onForceCancel={handleForceCancel}
        finishingPicking={finishingPicking}
        firstOrder={firstOrder}
        hasBabyFood={hasBabyFood}
        secondOrder={false}
        onError={handleError}
        rejectedBatches={rejectedBatches}
        hasNfe={hasNfe}
        pcId={pcId}
        distributionCenter={distributionCenter.value}
        promotionalItems={promotionalItems}
        hasBoughtGreenGrocer={hasBougtGreenGrocer}
      />
    );

  const renderPickingFinished = () =>
    step === 2 && (
      <PickingFinished
        success={finishSuccess}
        nextPicking={nextPicking}
        order={order}
        isExpressDelivery={isExpressDelivery}
        loading={startingPicking || fetchingSale}
        finishingPicking={finishingPicking}
        params={finishPickingParams}
        onRetry={handleFinishPicking}
        onCancel={handleCancel}
        onForceCancel={handleForceCancel}
        onBack={handleBack}
      />
    );

  const renderOrdersPerPeriodAndSaleInfoContainer = () =>
    step !== 2 && (
      <OrdersPerPeriodAndSaleInfoContainer>
        {isExpressDelivery ? renderExpressOrderIndicator() : renderOrdersPerPeriod()}
        {renderSaleInfo()}
      </OrdersPerPeriodAndSaleInfoContainer>
    );

  const renderOrdersPerPeriod = () =>
    step !== 0 && period && <OrdersPerPeriodContainer period={period} dateString={dateString} />;

  const renderExpressOrderIndicator = () => step !== 0 && period && <ExpressOrderIndicator />;

  const renderSaleInfo = () =>
    step !== 0 && (
      <SaleInfo
        customer={user}
        order={order}
        isExpressDelivery={isExpressDelivery}
        distributionCenter={distributionCenter.label}
        observations={insideObservations}
      />
    );

  return (
    <StyledContainer>
      {renderPageTitle()}
      {renderPickingInitialForm()}
      <StepperAndInfosContainer>
        {renderStepper()}
        {renderPickingFinished()}
        {renderOrdersPerPeriodAndSaleInfoContainer()}
      </StepperAndInfosContainer>
    </StyledContainer>
  );
};
export default Picking;
