import React from "react";

export const ListIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
    style={{ width: '1em', height: '1em', verticalAlign: 'middle', fill: ' #004D69', overflow: 'hidden' }}
    viewBox="0 0 1024 1024"
    version="1.1"
  >
    <path d="M256 576l128 0L384 448 256 448 256 576zM512 768l256 0 0-128L512 640 512 768zM256 768l128 0 0-128L256 640 256 768zM512 384l256 0L768 256 512 256 512 384zM896 0 128 0C57.28 0 0 57.28 0 128l0 768c0 70.72 57.28 128 128 128l768 0c70.72 0 128-57.28 128-128L1024 128C1024 57.28 966.72 0 896 0zM896 832c0 35.328-28.672 64-64 64L192 896c-35.328 0-64-28.672-64-64L128 192c0-35.328 28.672-64 64-64l640 0c35.328 0 64 28.672 64 64L896 832zM512 576l256 0L768 448 512 448 512 576zM256 384l128 0L384 256 256 256 256 384z" />
  </svg>
);