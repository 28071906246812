import styled from 'styled-components';
import { Typography } from "src/components/atoms/typography";
interface LabelProps {
  user: boolean
}
export const DescribedLabelContainer = styled.div``;

export const Description = styled(Typography)`
  font-size: ${props => props.theme.typography.fontSize.small};
  color: ${props => props.theme.palette.grayScale[60]};
`;

export const Label = styled(Typography)<LabelProps>`
  font-size: ${props => props.user ? props.theme.typography.fontSize.large : props.theme.typography.fontSize.medium};
  color: ${props => props.user ? props.theme.palette.primary.darker : props.theme.palette.grayScale[80]};
  font-weight: bold;
  border-bottom: ${props => props.user ? `1px solid ${props.theme.palette.grayScale[20]}` : ''};
  padding-bottom: 5px;
  margin-bottom: 12px;
`;