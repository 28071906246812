import React from 'react'
import Picking from "src/common/picking";
import { Header } from "src/common/header";

export interface MainAppProps {
    user: string;
    signOut: () => void;
}
export const MainApp: React.FC<MainAppProps> = ({...props}) => {

    return (
        <div>
            <Header user={props.user} signOut={props.signOut}/>
            <Picking />
        </div>
    )
};
