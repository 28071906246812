import { CartItem, Marketables, ProductsBySku, ProductTypes } from 'src/common/types';
import { filterByTypes } from '../filters';

export const extractProductsFromMeals = (
  mealsCartItems: any[],
  allProducts: ProductsBySku,
  productTypes: ProductTypes[]
): any[] =>
  mealsCartItems.map((item: CartItem) =>
    item.item.products
      .map((productSku: string) => ({
        kind: Marketables.Product,
        quantity: 1,
        item: allProducts[productSku],
      }))
      .filter(filterByTypes(productTypes))
  );
