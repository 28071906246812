import React, { Component, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { LoginPage } from "../login";
import { AUTH_TOKEN, EMAIL } from "src/common/constants";
import { MainApp } from "src/common/mainApp";

const ProtectedRoute = ({ component: Component, token, ...rest }) => {
  return token ? (
    <Route {...rest} render={matchProps => <Component {...matchProps} />} />
  ) : (
    <Redirect to="/login" />
  );
};

export interface RootContainerProps {
  token: string;
  user: string;
}
const RootContainer: React.FC<RootContainerProps> = ({ ...props }) => {
  const [token, setToken] = useState(props.token);
  const [user, setUser] = useState(props.user);
  const signIn = (data = {}) => {
    const dataToken = data[AUTH_TOKEN];
    const dataEmail = data[EMAIL];

    if (dataToken) {
      localStorage.setItem(AUTH_TOKEN, dataToken);
      localStorage.setItem(EMAIL, dataEmail);
    } else {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(EMAIL);
    }
    setUser(dataEmail);
    setToken(dataToken);
  };

  const signOut = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(EMAIL);
    setUser("");
    setToken("");
  };

  return (
    <div>
      <Router>
        <Switch>
          <Route
            path="/login"
            component={props => <LoginPage signIn={signIn} token={token} />}
          />
          <ProtectedRoute
            path="/"
            component={props => <MainApp user={user} signOut={signOut} />}
            token={token}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default RootContainer;
