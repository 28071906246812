import React from 'react';

export const PickingErrors = () => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <title>outline-report-24px</title>
    <desc>Created with Sketch.</desc>
    <g id="04_Protótipos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Acrescentar_etiquetas-Copy"
        transform="translate(-635.000000, -654.000000)"
        fill="#E57374"
        fill-rule="nonzero"
      >
        <g id="outline-report-24px" transform="translate(635.000000, 654.000000)">
          <path
            d="M12.73,0 L5.27,0 L0,5.27 L0,12.73 L5.27,18 L12.73,18 L18,12.73 L18,5.27 L12.73,0 Z M16,11.9 L11.9,16 L6.1,16 L2,11.9 L2,6.1 L6.1,2 L11.9,2 L16,6.1 L16,11.9 Z"
            id="Shape"
          ></path>
          <circle id="Oval" cx="9" cy="13" r="1"></circle>
          <polygon id="Path" points="8 4 10 4 10 11 8 11"></polygon>
        </g>
      </g>
    </g>
  </svg>
);
