import { Typography } from 'src/components/atoms/typography';
import styled from 'styled-components';

export const ExtraItemsContainer = styled.div`
  && {
    display: flex;
  }
`;

export const NoExtraItemsMessage = styled(Typography)`
  && {
    font-size: ${props => props.theme.typography.fontSize.h2};
    color: ${props => props.theme.palette.grayScale[80]};
    font-weight: bold;
  }
`;
