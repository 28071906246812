import React from 'react';
import styled from 'styled-components';
import { Typography } from 'src/components/atoms/typography/styles';

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex=start;
    position: fixed;
    width: 100%;
    z-index: 100;
    height: 50px;
    background: ${props => props.theme.palette.primary.darkest}
    color: ${props => props.theme.palette.grayScale[10]}
`;

export const StyledLogo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
`;

export const StyledUserContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 20px;
  :hover {
    cursor: pointer;
  }
  position: relative;
`;

export const StyledUser = styled(Typography)`
  color: ${props => props.theme.palette.grayScale[40]};
  font-size: ${props => props.theme.typography.fontSize.medium};
`;
export const StyledUserLogo = styled.div`
  color: ${props => props.theme.palette.grayScale[40]};
  margin-right: 20px;
  width: 20px;
  height: 20px;
`;

