import { Typography } from 'src/components/atoms/typography';
import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import { Paper } from 'src/components/atoms/paper';

export const StyledModal = styled(Modal)`
  && {
    display: flex;
    justify-content: center;
  }
`;

export const ModalTitle = styled(Typography)`
  && {
    font-size: ${props => props.theme.typography.fontSize.h2};
    color: ${props => props.theme.palette.grayScale[80]};
    font-weight: bold;
    margin-bottom: 12px;
  }
`;

export const ModalContainer = styled(Paper)`
  && {
    width: 900px;
    overflow-y: scroll;
    margin: 24px 0 24px 0;
  }
`;

export const PickingProblem = styled(Typography)`
  && {
    font-size: ${props => props.theme.typography.fontSize.large};
    color: ${props => props.theme.palette.primary.darker};
    font-weight: bold;
    margin-bottom: 12px;
    margin-top: 24px;
  }
`;
