import React from "react";
import MuiTextField from "@material-ui/core/TextField";
import styled from "styled-components";

export const TextField = styled(props => <MuiTextField {...props} />)`
  && {
    label[class*="MuiInputLabel-formControl"] {
      font-family: Lato;
      top: -7px;
    }
    input[class*="MuiOutlinedInput-input-"] {
      padding-top: 10.5px;
      padding-bottom: 10.5px;
    }
    div[class*="focused"] {
      fieldset {
        border-color: ${props => props.theme.palette.primary.darker};
      }
    }
    label[class*="shrink"] {
    top: 0;
      color: ${props => props.theme.palette.primary.darker};
    }
  }
`;
