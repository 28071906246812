import React, { useState, MouseEvent } from 'react';
import { HeaderContainer, StyledLogo, StyledUserLogo, StyledUser, StyledUserContainer } from './styles';
import logo from '../../assets/images/logo-livup.png';
import { Menu, MenuItem } from '@material-ui/core';
import { User } from 'src/common/icons/user';

export interface HeaderProps {
  user: string;
  signOut: () => void;
}

export const Header: React.FC<HeaderProps> = ({ ...props }) => {
  const [signOutConfirmation, setSignOutConfirmation] = useState(false);
  const [anchorElement, setAnchorElement] = useState();

  const handleClose = () => setSignOutConfirmation(false);
  const handleOpenSignOutMenu = (event: MouseEvent) => {
    const { currentTarget } = event;
    setSignOutConfirmation(true);
    setAnchorElement(currentTarget);
  };
  const handleSignOut = () => {
    handleClose();
    props.signOut();
  };
  return (
    <HeaderContainer>
      <Menu open={signOutConfirmation} onClose={handleClose} anchorEl={anchorElement}>
        <MenuItem onClick={handleSignOut}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
          Sair
        </MenuItem>
      </Menu>
      <StyledUserContainer>
        <StyledUserLogo>
          <User />
        </StyledUserLogo>
        <StyledUser onClick={handleOpenSignOutMenu}>{props.user}</StyledUser>
      </StyledUserContainer>
      <StyledLogo src={logo} alt="LivUp logo" title="LivUp" />
    </HeaderContainer>
  );
};
