import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';

import App from './App';

const dsn = process.env.SENTRY_DSN;

if (dsn) {
  const environment = process.env.SENTRY_ENVIRONMENT;

  Sentry.init({ dsn, environment });
}

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
