import React from 'react';
import { InProgressContainer, InProgressGrid } from './styles';
import { Typography } from 'src/components/atoms/typography/styles';

interface InProgressPickingProps {
  currentPickings: string[];
}

export const InProgressPickings: React.FC<InProgressPickingProps> = ({ currentPickings }) => {
  return (
    <InProgressContainer>
      <Typography size="medium" color="#004D69" fontWeight="bold">
        Pickings em andamento:
      </Typography>
      <InProgressGrid>
        {currentPickings.map(pickingOrder => (
          <Typography size="small" color="#4A4A4A">
            {pickingOrder}
          </Typography>
        ))}
      </InProgressGrid>
    </InProgressContainer>
  );
};
