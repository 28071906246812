import { InputAdornment } from '@material-ui/core';
import React, { useCallback } from 'react';
import { TextField } from './styles';
import {InputProps as MuiInputProps}  from '@material-ui/core/Input';

export interface InputProps extends MuiInputProps {
  type?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  fullWidth?: boolean;
  readOnly?: boolean;
}

const Input = React.memo(({ readOnly, ...props }: InputProps) => {
  const handleType = useCallback(type => {
    switch (type) {
      case 'weight':
        return 'Kg';
      case 'currency':
        return 'R$';
      case 'percentage':
        return '%';
      default:
        return '';
    }
  }, []);

  return (
    <TextField
      {...props}
      variant='outlined'
      defaultValue={props.value}
      InputProps={{
        readOnly,
        endAdornment: (
          <InputAdornment position='end'>
            {handleType(props.type)}
          </InputAdornment>
        )
      }}
      InputLabelProps={{
        shrink: readOnly,
      }}
    />
  );
});

export default Input;
