import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import momentTimezone from 'moment-timezone';
import {
  StyledDivider,
  PickingListHeadersContainer,
  PickingListManualPickingtHeader,
  PickingListProductHeader,
  PickingListQuantityConfirmationHeader,
  PickingListQuantityHeader,
  AllProductsButton,
} from './styles';
import { ProductCard } from 'src/common/picking/molecules/productCard';
import { setStatus } from 'src/common/picking/logic/status/setMarketablesStatus';
import { MealCard } from 'src/common/picking/organisms/mealCard';
import { KitDayCard } from 'src/common/picking/organisms/kitDayCard';
import {
  checkBarcode,
  triggerBusy,
  getSku,
  skusDictionary,
  updateMarketable,
  getNextMarketableIndex,
  getFirstMarketableIndex,
  isProductValid,
} from './logic';
import { useSnackbar } from 'notistack';
import { ManualPickingModal, reasons } from 'src/common/picking/organisms/manualPickingModal';
import { AllProductsModal } from 'src/common/picking/organisms/allProductsModal';
import { StartPickingMutation_startPicking_rejectedBatches } from 'src/apollo/types/StartPickingMutation';
import { RejectedBatchesModal } from 'src/common/picking/organisms/rejectedBatchModal';
import { UnsafeExpirationDateModal } from 'src/common/picking/organisms/unsafeExpirationDateModal';
import { fromEvent } from 'rxjs';
import { scan, map, debounceTime } from 'rxjs/operators';
import { PickingEventTypes } from 'src/apollo/types/globalTypes';
import { EMAIL } from 'src/common/constants';
import { LogEventMutation, LogEventMutationVariables } from 'src/apollo/types/LogEventMutation';
import { LOG_EVENT_MUTATION } from 'src/common/picking/graphql';
import { FormattedSale, Marketables, PickingItemStatus, ProductsBySku, ProductTypes } from 'src/common/types';
import { LIVUP_GS1_MANUFACTURER_CODE } from '../../consts';
const errorSound = new Audio(require('../../../../assets/sounds/error.mp3'));
export interface PickingListProps {
  order: string;
  sale: FormattedSale;
  onProductCheck: (barcode: string) => void;
  productsBySku: ProductsBySku;
  onManualPickingConfirm: (data: any) => void;
  rejectedBatches: StartPickingMutation_startPicking_rejectedBatches[];
}
export const PickingList: React.FC<PickingListProps> = ({ ...props }) => {
  const [logEventMutation] = useMutation<LogEventMutation, LogEventMutationVariables>(LOG_EVENT_MUTATION);
  const { enqueueSnackbar } = useSnackbar();
  const [manualPickingModal, setManualPickingModal] = useState(false);
  const [allProductsModal, setAllProductsModal] = useState(false);
  const [rejectedBatchModal, setRejectedBatchModal] = useState(false);
  const [unsafeExpirationDateModal, setUnsafeExpirationDateModal] = useState(false);

  const [product, setProduct] = useState<any>();
  const [sale, setSale] = useState(props.sale);
  const [busy, setBusy] = useState(false);
  const [currentMarketableIndex, setCurrentMarketableIndex] = useState(getFirstMarketableIndex(props.sale.marketables));
  const rejectedBatch = (code: string) =>
    props.rejectedBatches.some(rejectedBatch => code.includes(rejectedBatch.code));

  const handleBarCodeScan = (scannedCode: string, productsBySku: ProductsBySku) => {
    const { sku, isEan13 } = getSku(scannedCode, productsBySku);
    const product = sku ? productsBySku[sku] : null;
    if (!product) {
      errorSound.play();
      enqueueSnackbar('Produto não encontrado.', {
        variant: 'error',
      });
      return;
    } else if (isEan13 && scannedCode.match(`^${LIVUP_GS1_MANUFACTURER_CODE}`)) {
      errorSound.play();
      enqueueSnackbar('Para produtos internos, leia o código do lote.', {
        variant: 'error',
      });
      return;
    } else if (rejectedBatch(scannedCode)) {
      errorSound.play();
      setRejectedBatchModal(true);
      return;
    }
    return handleProductScan(sku, scannedCode);
  };

  const handlePaste = (event: any) => {
    if (!busy) {
      const { clipboardData } = event;
      const paste = clipboardData.getData('text');
      if (checkBarcode(paste) || skusDictionary[paste.slice(2, 6)]) {
        triggerBusy(setBusy);
        handleBarCodeScan(paste, props.productsBySku);
      }
    } else {
      enqueueSnackbar('Espere alguns segundos para ler novamente', {
        variant: 'error',
      });
    }
  };

  const handleTagScan = (scannedCode: string) => {
    triggerBusy(setBusy);
    if (checkBarcode(scannedCode) || skusDictionary[scannedCode.slice(2, 6)]) {
      handleBarCodeScan(scannedCode, props.productsBySku);
    }
  };

  useEffect(() => {
    window.addEventListener('paste', handlePaste);
    if (!busy) {
      const obs = fromEvent(window, 'keypress')
        .pipe(
          map((event: KeyboardEvent) => {
            const { keyCode } = event;
            return String.fromCharCode(keyCode);
          }),
          scan((acc, cur) => {
            return `${acc}${cur}`;
          }, ''),
          debounceTime(70)
        )
        .subscribe(handleTagScan);

      return () => {
        window.removeEventListener('paste', handlePaste);
        obs.unsubscribe();
      };
    }
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  });

  const handleProductScan = (skuOrKey: string, barcode?: string) => {
    const product = props.productsBySku[skuOrKey];

    const today = momentTimezone().tz('America/Sao_Paulo');
    const shouldLogExpirationError = product && !isProductValid(skuOrKey, barcode, today, props.productsBySku);

    if (shouldLogExpirationError) {
      logEventMutation({
        variables: {
          input: {
            event: PickingEventTypes.BATCH_UNSAFE_EXPIRATION_DATE,
            order: props.order,
            user: localStorage.getItem(EMAIL),
            payload: JSON.stringify({
              barcode,
              product: {
                id: product.id,
                sku: product.sku,
                name: product.name,
                type: product.type,
                expiration: product.expiration,
                fiscal: {
                  barcode: product.fiscal.barcode,
                },
              },
            }),
            timestamp: momentTimezone()
              .tz('America/Sao_Paulo')
              .format(),
          },
        },
      });
      errorSound.play();
      setUnsafeExpirationDateModal(true);
      return;
    }

    const updatedMarketable = updateMarketable(
      skuOrKey,
      sale,
      props.onProductCheck,
      enqueueSnackbar,
      barcode,
      currentMarketableIndex
    );
    if (
      updatedMarketable.items.every((marketableItem: any) => marketableItem.status === 'done') &&
      sale.marketables.length > currentMarketableIndex
    ) {
      const nextIndex = getNextMarketableIndex(sale.marketables, currentMarketableIndex);
      const nextMarketable = {
        ...sale.marketables[nextIndex],
        items: setStatus(sale.marketables[nextIndex]),
      };
      setCurrentMarketableIndex(nextIndex);
      return setSale({
        ...sale,
        marketables: [
          ...sale.marketables.slice(0, currentMarketableIndex),
          updatedMarketable,
          ...sale.marketables.slice(currentMarketableIndex + 1, nextIndex),
          nextMarketable,
          ...sale.marketables.slice(nextIndex + 1),
        ],
      });
    }
    return setSale({
      ...sale,
      marketables: [
        ...sale.marketables.slice(0, currentMarketableIndex),
        updatedMarketable,
        ...sale.marketables.slice(currentMarketableIndex + 1),
      ],
    });
  };

  const handleClose = () => {
    setManualPickingModal(false);
    setAllProductsModal(false);
    setRejectedBatchModal(false);
    setUnsafeExpirationDateModal(false);
  };
  const handleManualPickIconClick = (product: any) => {
    setProduct(product);
    setManualPickingModal(true);
  };

  const handleManualPickConfirm = (reason: string) => {
    handleProductScan(product.key);
    setManualPickingModal(false);
    if (reason === Object.keys(reasons)[1]) {
      logEventMutation({
        variables: {
          input: {
            event: PickingEventTypes.MISSING_PRODUCT,
            order: props.order,
            user: localStorage.getItem(EMAIL),
            payload: product.sku,
            timestamp: momentTimezone()
              .tz('America/Sao_Paulo')
              .format(),
          },
        },
      });
    }

    props.onManualPickingConfirm({ name: product.name, sku: product.sku, problem: reason });
  };

  const handleAllProductsButtonClick = () => {
    setAllProductsModal(true);
  };

  const renderHeaders = () => (
    <PickingListHeadersContainer>
      <PickingListQuantityHeader>Quantidade</PickingListQuantityHeader>
      <PickingListProductHeader>Produto</PickingListProductHeader>
      <PickingListQuantityConfirmationHeader>Confirmação</PickingListQuantityConfirmationHeader>
      <PickingListManualPickingtHeader>Conferência manual</PickingListManualPickingtHeader>
      <AllProductsButton variant="outlined" color="primary" size="large" onClick={handleAllProductsButtonClick}>
        Ver todos os produtos
      </AllProductsButton>
    </PickingListHeadersContainer>
  );

  const renderDivider = () => <StyledDivider />;
  const renderProducts = items => (
    <div>
      {items
        .filter(
          (product: any) => product.status !== PickingItemStatus.waiting && product.status !== PickingItemStatus.done
        )
        .map((product: any, index: number) => (
          <ProductCard
            key={product.key}
            index={index}
            kind="product"
            product={product}
            onManualPickIconClick={handleManualPickIconClick}
          />
        ))}
    </div>
  );

  const renderManualPickingModal = () => (
    <ManualPickingModal
      open={manualPickingModal}
      onClose={handleClose}
      onConfirm={handleManualPickConfirm}
      product={product}
    />
  );

  const renderAllProductsModal = () =>
    allProductsModal && (
      <AllProductsModal open={allProductsModal} onClose={handleClose} sale={sale} alreadyFinishedPicking={false} />
    );

  const renderRejectedBatchModal = () =>
    rejectedBatchModal && <RejectedBatchesModal open={rejectedBatchModal} onClose={handleClose} />;

  const renderUnsafeExpirationDateModal = () =>
    unsafeExpirationDateModal && <UnsafeExpirationDateModal open={unsafeExpirationDateModal} onClose={handleClose} />;

  const renderMeals = items => (
    <div>
      {items
        .filter((meal: any) => meal.status !== 'waiting' && meal.status !== 'done')
        .map((meal: any) => (
          <MealCard key={meal.key} meal={meal} onManualPickIconClick={handleManualPickIconClick} />
        ))}
    </div>
  );

  const renderKits = items => {
    return (
      <div>
        {items
          .filter((kit: any) => kit.status !== 'waiting' && kit.status !== 'done')
          .map((kit: any) =>
            kit.kitDays
              .filter((kitDay: any) => kitDay.status !== 'waiting' && kitDay.status !== 'done')
              .map((kitDay: any) => (
                <KitDayCard
                  alreadyFinishedPicking={false}
                  modal={false}
                  key={kitDay.key}
                  kitDay={kitDay}
                  onManualPickIconClick={handleManualPickIconClick}
                />
              ))
          )}
      </div>
    );
  };

  const renderMarketables = () => (
    <React.Fragment>
      {sale.marketables.map(marketable => {
        if (marketable.kind === Marketables.Kit) {
          return renderKits(marketable.items);
        }
        if (marketable.kind === Marketables.Meal) {
          return renderMeals(marketable.items);
        }
        return renderProducts(marketable.items);
      })}
    </React.Fragment>
  );
  return (
    <div>
      {renderManualPickingModal()}
      {renderRejectedBatchModal()}
      {renderUnsafeExpirationDateModal()}
      {renderAllProductsModal()}
      {renderHeaders()}
      {renderDivider()}
      {renderMarketables()}
    </div>
  );
};
