import React from 'react';
import { PeriodOrderContainer, PeriodOrderTextContainer, PeriodOrderImage } from './styles';
import { Typography } from 'src/components/atoms/typography/styles';

export type Size = 'large' | 'small'

interface PeriodOrderProps {
  image: any;
  size: Size;
  gutter: string;
  period: string;
  counter: string;
  active: boolean
}

export const PeriodOrder: React.FC<PeriodOrderProps> = ({ image, gutter, period, counter, size, active }) => {
  return (
    <PeriodOrderContainer active={active} size={size}>
      <PeriodOrderImage size={size}>{image}</PeriodOrderImage>
      <PeriodOrderTextContainer gutter={gutter}>
        <Typography size="medium" color="#888888">
          {period}
        </Typography>
        <Typography size="medium" color="#004D69" fontWeight="bold">
          {counter}
        </Typography>
      </PeriodOrderTextContainer>
    </PeriodOrderContainer>
  );
};
