import React from 'react';
import { StyledModal, ModalContainer, ModalTitle, PickingProblem } from './styles';
import { reasons } from 'src/common/picking/organisms/manualPickingModal';

export interface PickingProblemsModalProps {
  open: boolean;
  onClose: () => void;
  pickingProblems: any;
}

export const PickingProblemsModal: React.FC<PickingProblemsModalProps> = ({ ...props }) => {
  const handleClose = () => {
    props.onClose();
  };
  return (
    <StyledModal {...props} open={props.open}>
      <ModalContainer>
        <ModalTitle>Problemas no picking</ModalTitle>
        {props.pickingProblems.map((pickingProblem: any) => (
          <PickingProblem>
            {pickingProblem.name}: {reasons[pickingProblem.problem]}
          </PickingProblem>
        ))}
      </ModalContainer>
    </StyledModal>
  );
};
