import React, { useEffect, useState } from 'react';
import momentTimezone from 'moment-timezone';
import {
  AddTagsBagsPickingProblemsIcon,
  AddTagsBagsPickingProblemsText,
  AddTagsBagsQuantityInputCollapse,
  AddTagsBagsQuantityInputCollapseDetailsInput,
  AddTagsBagsQuantityInputCollapseDetailsText,
  AddTagsBagsQuantityInputCollapseSummaryContainer,
  AddTagsBagsQuantityInputCollapseSummaryIcon,
  AddTagsBagsQuantityInputCollapseSummaryTitle,
  AddTagsCodeText,
  AddTagsContainer,
  AddTagsDivider,
  AddTagsLeftColumnContainer,
  AddTagsObservationsHeader,
  AddTagsObservationsText,
  AddTagsPickingProblemsContainer,
  AddTagsPrintNfePrintMore,
  AddTagsPrintTagsHeader,
  AddTagsPrintTagsPrintMore,
  AddTagsPrintTagsPrintMoreContainer,
  AddTagsPrintTagsText,
  AddTagsRightColumnContainer,
  AddTagsScannedTags,
  AddTagsScannedTotalTags,
  AllProductsButton,
  LoadingContainer,
  StyledCircularProgress,
} from './styles';
import { useMutation } from 'react-apollo';

import { AddRemove } from 'src/common/picking/molecules/addRemove';
import {
  checkOrder,
  checkTagcode,
  checkType,
  triggerBusy,
  triggerDebounce,
} from 'src/common/picking/organisms/pickingList/logic';
import {
  LOG_EVENT_MUTATION,
  PRINT_NFE_MUTATION,
  PRINT_TAGS_MUTATION,
} from 'src/common/picking/graphql';
import { printTags } from 'src/common/picking/logic/printing';
import { dcHasMultipleStations } from 'src/common/picking/logic/dcHasMultipleStations';
import { DropDown } from 'src/common/icons/dropDown';
import { useSnackbar } from 'notistack';
import { PickingProblemsModal } from 'src/common/picking/organisms/pickingProblemsModal';
import { PickingErrors } from 'src/common/icons/pickingErrors';
import { AllProductsModal } from 'src/common/picking/organisms/allProductsModal';
import { LogEventMutation, LogEventMutationVariables } from 'src/apollo/types/LogEventMutation';
import { EMAIL } from 'src/common/constants';
import { PickingEventTypes } from 'src/apollo/types/globalTypes';
import ERROR_SOUND from '../../../../assets/sounds/error.mp3';
import { FormattedSale, Marketables, ProductTypes } from 'src/common/types';

const errorSound = new Audio(ERROR_SOUND);

export interface AddTagsProps {
  order: string;
  packageCodes: string[];
  onSetPackageCodes: (data: any) => void;
  onSetNumberOfPackages: (data: any) => void;
  productType: string;
  totalTags: number;
  observations: string;
  insideObservations: string;
  onError: (error: Error) => void;
  pickingProblems: any;
  alreadyFinishedPicking: boolean;
  sale: FormattedSale;
  hasNfe: boolean;
  pcId?: number;
  distributionCenter: string;
}
export const AddTags: React.FC<AddTagsProps> = ({ ...props }) => {
  const [logEventMutation] = useMutation<LogEventMutation, LogEventMutationVariables>(LOG_EVENT_MUTATION);
  const [nfeUrl, setNfeUrl] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [pickingProblemsModal, setPickingProblemsModal] = useState(false);
  const [allProductsModal, setAllProductsModal] = useState(false);
  const [tagsQuantity, setTagsQuantity] = useState(0);
  const [numberOfPackages, setNumberOfPackages] = useState();
  const [busy, setBusy] = useState(false);
  const [tagcode, setTagcode] = useState('');
  const [lastTagCode, setLastTagCode] = useState(!props.alreadyFinishedPicking ? props.totalTags : 100);
  const [printTagsMutation] = useMutation(PRINT_TAGS_MUTATION, {
    onError: props.onError,
  });

  const handleCompleted = (data: any) => {
    if (data && data.printNFE) {
      setNfeUrl(data.printNFE);
      window.open(data.printNFE, '_blank');
    }
  };

  const [printNfeMutation, { loading }] = useMutation(PRINT_NFE_MUTATION, {
    onCompleted: handleCompleted,
    onError: props.onError,
  });
  const handleTagScan = (tag: string) => {
    props.onSetPackageCodes([...props.packageCodes, tag]);
  };

  const handlePaste = (event: any) => {
    if (!busy) {
      const { clipboardData } = event;
      const paste = clipboardData.getData('text');
      if (checkTagcode(paste)) {
        triggerBusy(setBusy);
        if (props.productType !== ProductTypes.greenGrocer && props.packageCodes.some(tag => tag === paste)) {
          enqueueSnackbar('Etiqueta já lida', {
            variant: 'error',
          });
        } else {
          handleTagScan(paste);
        }
      }
    }
  };

  const handleKeyPress = (event: any) => {
    const { keyCode } = event;
    const char = String.fromCharCode(keyCode);
    if (tagcode === '') {
      triggerDebounce(setTagcode);
    }
    if (checkTagcode(`${tagcode}${char}`)) {
      if (!busy) {
        triggerBusy(setBusy);
        if (!checkOrder(`${tagcode}${char}`, props.order)) {
          errorSound.play();
          enqueueSnackbar('Essa sacola pertence a outro pedido', {
            variant: 'error',
          });
        } else if (!checkType(`${tagcode}${char}`, props.productType)) {
          errorSound.play();
          enqueueSnackbar('Essa sacola não é do tipo de produtos deste picking', {
            variant: 'error',
          });
        } else {
          if (
            props.productType !== ProductTypes.greenGrocer &&
            props.packageCodes.some(tag => tag === `${tagcode}${char}`)
          ) {
            errorSound.play();
            enqueueSnackbar('Etiqueta já lida', {
              variant: 'error',
            });
          } else {
            handleTagScan(`${tagcode}${char}`);
          }
        }
      } else {
        errorSound.play();
        enqueueSnackbar('Espere alguns segundos para ler novamente', {
          variant: 'error',
        });
      }
    }
    setTagcode(`${tagcode}${char}`);
  };

  useEffect(() => {
    window.addEventListener('paste', handlePaste);
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('paste', handlePaste);
      window.removeEventListener('keypress', handleKeyPress);
    };
  });

  const handleRemove = () => {
    if (tagsQuantity > 0) {
      setTagsQuantity(tagsQuantity - 1);
    }
  };

  const handleAdd = () => {
    setTagsQuantity(tagsQuantity + 1);
  };

  const handlePickingProblemsModalOpen = () => {
    setPickingProblemsModal(true);
  };

  const handlePrintNfe = () => {
    if (nfeUrl) {
      window.open(nfeUrl, '_blank');
    } else {
      printNfeMutation({ variables: { order: props.order } });
    }
  };

  const handlePrintMore = () => {
    setLastTagCode(lastTagCode + tagsQuantity);
    logEventMutation({
      variables: {
        input: {
          event: PickingEventTypes.MANUAL_TAG_PRINTING,
          order: props.order,
          user: localStorage.getItem(EMAIL),
          payload: String(tagsQuantity),
          timestamp: momentTimezone()
            .tz('America/Sao_Paulo')
            .format(),
        },
      },
    });
    printTags({
      ...(dcHasMultipleStations(props.distributionCenter) && { pcId: String(props.pcId) }),
      order: props.order,
      quantity: tagsQuantity,
      productType: props.productType,
      print: printTagsMutation,
      lastCode: lastTagCode,
    });
  };

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    if (/^[0-9]*$/.test(value) || !value) {
      setNumberOfPackages(value);
      props.onSetNumberOfPackages(value);
    }
  };

  const handleClose = () => {
    setPickingProblemsModal(false);
    setAllProductsModal(false);
  };

  const handleAllProductsButtonClick = () => {
    setAllProductsModal(true);
  };

  const renderAllProductsModal = () =>
    allProductsModal && (
      <AllProductsModal
        open={allProductsModal}
        onClose={handleClose}
        sale={props.sale}
        alreadyFinishedPicking={props.alreadyFinishedPicking}
      />
    );

  return (
    <AddTagsContainer>
      {renderAllProductsModal()}
      {props.alreadyFinishedPicking && (
        <AllProductsButton variant="outlined" color="primary" size="large" onClick={handleAllProductsButtonClick}>
          Ver todos os produtos
        </AllProductsButton>
      )}
      <AddTagsLeftColumnContainer>
        <AddTagsCodeText>Escaneie as etiquetas com o leitor</AddTagsCodeText>
        <AddTagsScannedTags>
          Etiquetas lidas: <b>{props.packageCodes.length}</b>
        </AddTagsScannedTags>
        <AddTagsScannedTotalTags>
          Total de etiquetas: <b>{props.totalTags}</b>
        </AddTagsScannedTotalTags>
        <AddTagsObservationsHeader>Observações da venda:</AddTagsObservationsHeader>
        <AddTagsObservationsText>{props.observations || 'Não há observações.'}</AddTagsObservationsText>
        <AddTagsObservationsHeader>Observações internas:</AddTagsObservationsHeader>
        <AddTagsObservationsText>{props.insideObservations || 'Não há observações.'}</AddTagsObservationsText>
      </AddTagsLeftColumnContainer>
      <AddTagsDivider />
      <AddTagsRightColumnContainer>
        <AddTagsPrintTagsHeader>Imprimir etiquetas</AddTagsPrintTagsHeader>
        <AddTagsPrintTagsText>
          Caso tenha problemas com a impressão ou apenas precise imprimir mais etiquetas, utilize os campos abaixo.
        </AddTagsPrintTagsText>
        <AddTagsPrintTagsPrintMoreContainer>
          <AddRemove quantity={tagsQuantity} onAdd={handleAdd} onRemove={handleRemove} />
          <AddTagsPrintTagsPrintMore variant="outlined" color="primary" size="large" onClick={handlePrintMore}>
            Imprimir novamente
          </AddTagsPrintTagsPrintMore>
        </AddTagsPrintTagsPrintMoreContainer>
        {props.hasNfe && (
          <React.Fragment>
            <AddTagsPrintTagsHeader>Imprimir NFe</AddTagsPrintTagsHeader>
            <AddTagsPrintTagsText>Para esse pedido deve ser impressa uma NFe</AddTagsPrintTagsText>
            {loading ? (
              <LoadingContainer>
                {' '}
                <StyledCircularProgress size={30} />
              </LoadingContainer>
            ) : (
              <AddTagsPrintNfePrintMore variant="outlined" color="primary" size="large" onClick={handlePrintNfe}>
                Imprimir NFe
              </AddTagsPrintNfePrintMore>
            )}
          </React.Fragment>
        )}

        <AddTagsBagsQuantityInputCollapse
          title={
            <AddTagsBagsQuantityInputCollapseSummaryContainer>
              <AddTagsBagsQuantityInputCollapseSummaryIcon>
                <DropDown />
              </AddTagsBagsQuantityInputCollapseSummaryIcon>
              <AddTagsBagsQuantityInputCollapseSummaryTitle>
                Não conseguiu escanear as etiquetas?
              </AddTagsBagsQuantityInputCollapseSummaryTitle>
            </AddTagsBagsQuantityInputCollapseSummaryContainer>
          }
        >
          <AddTagsBagsQuantityInputCollapseDetailsText>
            Você pode informar apenas a quantidades de sacola que usou para esse pedido.
          </AddTagsBagsQuantityInputCollapseDetailsText>
          <AddTagsBagsQuantityInputCollapseDetailsInput
            label="Quantidade de sacolas"
            onChange={handleChange}
            value={numberOfPackages}
          />
        </AddTagsBagsQuantityInputCollapse>
        <AddTagsPickingProblemsContainer onClick={handlePickingProblemsModalOpen}>
          <AddTagsBagsPickingProblemsIcon>
            <PickingErrors />
          </AddTagsBagsPickingProblemsIcon>
          <AddTagsBagsPickingProblemsText>Ver problemas relatados no picking</AddTagsBagsPickingProblemsText>
        </AddTagsPickingProblemsContainer>
      </AddTagsRightColumnContainer>
      {pickingProblemsModal && (
        <PickingProblemsModal
          open={pickingProblemsModal}
          pickingProblems={props.pickingProblems}
          onClose={handleClose}
        />
      )}
    </AddTagsContainer>
  );
};
