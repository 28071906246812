import styled from 'styled-components';
import HeaderfulContainer from 'src/components/molecules/headerfulContainer';
import { Typography } from 'src/components/atoms';

export const OrdersPerPeriodHeaderfulContainer = styled(HeaderfulContainer)`
  && {
    margin-bottom: 24px;
  }
`;

export const PeriodsContainer = styled.div`
  && {
    display: flex;
    justify-content: space-evenly;
  }
`;

export const Period = styled.div`
  && {
    opacity: ${props => (props.active ? 1 : 0.3)};
    margin-left: 12%;
    margin-right: 12%;
    width: 40px;
    height: 80px;
  }
`;

export const Date = styled(Typography)`
  && {
    font-weight: bold;
    color: ${props => props.theme.palette.primary.darker};
  }
`;

export const PeriodName = styled(Typography)`
  && {
    margin-top: 18px;
    font-weight: bold;
    color: ${props => props.theme.palette.primary.darker};
  }
`;
