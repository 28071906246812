import {
  AllProductsQuery_products,
  AllProductsQuery_products_catalogueCategories,
} from 'src/apollo/types/AllProductsQuery';

export enum PickingTypesPT {
  frozen = 'Congelados',
  snacks = 'Snacks e Refrigerados',
  greenGrocer = 'Quitanda',
  basket = 'Cesta de Orgânicos',
}

export enum ProductTypes {
  frozen = 'frozen',
  snacks = 'snacks',
  refrigerated = 'refrigerated',
  greenGrocer = 'greenGrocer',
  basket = 'basket',
  babyFood = 'babyFood',
}

export enum PickingTypes {
  frozen = 'frozen',
  snacks = 'snacks',
  greenGrocer = 'greenGrocer',
  basket = 'basket',
}

export enum LogisticKindsPT {
  b2c = 'B2C',
  b2b = 'B2B Ultrafast',
  varejo = 'Varejo',
}

export enum LogisticKinds {
  b2c = 'b2c',
  b2b = 'b2b',
  varejo = 'varejo',
}

export const PRODUCT_TYPES_BY_PICKING_TYPE = {
  [PickingTypes.frozen]: [ProductTypes.frozen, ProductTypes.babyFood],
  [PickingTypes.snacks]: [ProductTypes.snacks, ProductTypes.refrigerated],
  [PickingTypes.greenGrocer]: [ProductTypes.greenGrocer],
  [PickingTypes.basket]: [ProductTypes.basket],
};

export enum Marketables {
  Product = 'Product',
  Meal = 'Meal',
  Kit = 'Kit',
}
export enum AvailableMeals {
  breakfast = 'breakfast',
  midmorning = 'midmorning',
  lunch = 'lunch',
  midafternoon = 'midafternoon',
  dinner = 'dinner',
  supper = 'supper',
}

export interface Marketable {
  kind: Marketables;
  items: any[];
}

export interface PickingOrder {
  sku: string;
  position: number;
}

export interface PackageSize {
  type: ProductTypes;
  quantity: number;
  icon: JSX.Element;
}

export interface FormattedSale {
  marketables: Marketable[];
  packageSizes: PackageSize[];
}

export enum PickingItemStatus {
  checking = 'checking',
  waiting = 'waiting',
  done = 'done',
}

export interface FormattedProduct {
  key: string;
  sku: string;
  name: string;
  color: string;
  totalQuantity: number;
  checks: number;
  status: PickingItemStatus;
  type: ProductTypes;
  catalogueCategories: AllProductsQuery_products_catalogueCategories[];
  pickingPriority?: number;
}

export interface CartItem {
  kind: Marketables;
  quantity: number;
  item: any;
}

export interface DayProduct {
  product: any;
}

export interface Day {
  day: number;
  products: DayProduct[];
}

export interface Fiscal {
  barcode: string;
}
export interface ProductsBySkuProduct {
  id: string;
  sku: string;
  name: string;
  color: string;
  type: ProductTypes;
  fiscal: Fiscal;
}

export interface ProductsBySku {
  [sku: string]: AllProductsQuery_products;
}

export enum TypeTagCode {
  F = 'F',
  S = 'S',
  G = 'G',
  B = 'B',
}
