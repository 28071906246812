import React, { ReactElement } from 'react';
import { StyledIconButton } from './styles';

export interface IconButtonProps {
  icon: ReactElement;
  onClick: (event: any) => void;
  fontColor?: string;
  disabled?: boolean;
}
export const IconButton = ({
  icon,
  onClick,
  fontColor,
  disabled
}: IconButtonProps) => (
  <StyledIconButton disabled={disabled} onClick={onClick} fontColor={fontColor}>
    {icon}
  </StyledIconButton>
);
