import styled from 'styled-components';
import { StyledButton } from './styles';

export const OutlinedButton = styled(StyledButton).attrs({
  classes: { label: 'label' },
})`
	&& {
		border-color: ${props => props.theme.palette[props.color].light};
		background-color: transparent;
		&:hover {
			background-color: transparent;
			box-shadow: inset 0 0 3px ${props => props.theme.palette[props.color].light};
			border-color: ${props => props.theme.palette[props.color].light};
		}
	}
	& .label {
		color: ${props =>
  props.disabled
    ? props.theme.palette.grayScale['40']
    : props.theme.palette[props.color].light};
	}
`;
