import React from "react";
import styled from "styled-components";
import { Divider } from "@material-ui/core";
import { Typography } from "src/components/atoms/typography/styles";
import { Button } from "src/components/atoms/button";

export const PickingListHeadersContainer = styled.div`
  && {
    display: flex;
    position: relative;
    margin-top: 15px;
  }
`;

export const StyledDivider = styled(Divider)`
  && {
    margin-top: 4px;
    margin-bottom: 12px;
  }
`;

export const PickingListQuantityHeader = styled(Typography)`
  && {
    color: ${props => props.theme.palette.primary.light};
    font-size: ${props => props.theme.typography.fontSize.xsmall};
    position: absolute;
    top: -27px;
    left: 0;
  }
`;

export const AllProductsButton = styled(Button)`
  && {
    position: absolute;
    top: -174px;
    right: 0;
  }
`;

export const PickingListProductHeader = styled(Typography)`
  && {
    color: ${props => props.theme.palette.primary.light};
    font-size: ${props => props.theme.typography.fontSize.xsmall};
    position: absolute;
    top: -27px;
    left: 103px;
  }
`;

export const PickingListQuantityConfirmationHeader = styled(Typography)`
  && {
    color: ${props => props.theme.palette.primary.light};
    font-size: ${props => props.theme.typography.fontSize.xsmall};
    position: absolute;
    right: 214px;
    top: -27px;
  }
`;

export const PickingListManualPickingtHeader = styled(Typography)`
  && {
    color: ${props => props.theme.palette.primary.light};
    font-size: ${props => props.theme.typography.fontSize.xsmall};
    position: absolute;
    right: 0;
    top: -27px;
    text-align: center;
    white-space: normal;
    width: 66px;
  }
`;
