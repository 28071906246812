import React from 'react';
import { DescribedLabel } from 'src/components/molecules/describedLabel';
import HeaderfulContainer from 'src/components/molecules/headerfulContainer';
import { SaleInfoLabelsContainer } from './styles';

interface SaleInfoProps {
  customer?: string;
  order: string;
  isExpressDelivery?: boolean;
  distributionCenter: string;
  observations: string;
}

export const SaleInfo: React.FC<SaleInfoProps> = ({
  customer,
  observations,
  order,
  isExpressDelivery,
  distributionCenter,
}) => {
  return (
    <HeaderfulContainer title="Informações gerais" isExpressDelivery={isExpressDelivery}>
      <SaleInfoLabelsContainer>
        <DescribedLabel description="Cliente:" label={customer || '-'} user={true} />
        <DescribedLabel description="Pedido:" label={order || '-'} />
        <DescribedLabel description="Centro de distribuição:" label={distributionCenter || '-'} />
        <DescribedLabel description="Observações:" label={observations || '-'} />
      </SaleInfoLabelsContainer>
    </HeaderfulContainer>
  );
};
