import React from "react";
import styled from "styled-components";

import { ExpansionPanel as MuiExpansionPanel, ExpansionPanelSummary as MuiExpansionPanelSummary , ExpansionPanelDetails as MuiExpansionPanelDetails } from '@material-ui/core';

export const StyledMuiExpansionPanel = styled(MuiExpansionPanel)`
`;

export const StyledMuiExpansionPanelSummary = styled(MuiExpansionPanelSummary)`
`;

export const StyledMuiExpansionPanelDetails = styled(MuiExpansionPanelDetails)`
  && {
    
  }
`;