import { AvailableMeals, Marketables, ProductTypes } from 'src/common/types';

const AVAILABLE_MEALS = Object.values(AvailableMeals);

export function extractProductsFromKits(kitCartItems: any[], productTypes: ProductTypes[]): any[] {
  return kitCartItems.reduce((previous, currentKit) => {
    const mealsWithItems = getMealsWithItems(currentKit);
    const products = getProductsFromMeals(mealsWithItems, currentKit.quantity, productTypes);
    return [...previous, ...products];
  }, []);
}

function getMealsWithItems(kit: any) {
  return AVAILABLE_MEALS.reduce((previous, current) => {
    const meals = kit.item[current];
    return meals.length > 0 ? [...previous, ...meals] : previous;
  }, []);
}

function getProductsFromMeals(meals: any[], kitQuantity: number, productTypes: ProductTypes[]) {
  return meals.map(meal => {
    const validProducts = meal.products.filter(product => productTypes.includes(product.product.type));
    return validProducts.map((product: any) => ({
      kind: Marketables.Product,
      quantity: kitQuantity,
      item: product.product,
    }));
  });
}
