import React from 'react';
import { StyledModal, ModalContainer, ModalTitle, Text, CloseButton } from './styles';

export interface RejectedBatchesModalProps {
  open: boolean;
  onClose: () => void;
}

export const RejectedBatchesModal: React.FC<RejectedBatchesModalProps> = ({ ...props }) => {
  const handleClose = () => {
    props.onClose();
  };
  return (
    <StyledModal {...props} open={props.open}>
      <ModalContainer>
        <ModalTitle>Lote rejeitado</ModalTitle>
        <Text>Este lote foi rejeitado. Descarte IMEDIATAMENTE!</Text>
        <CloseButton variant="outlined" size="large" color="primary" onClick={handleClose}>
          Fechar
        </CloseButton>
      </ModalContainer>
    </StyledModal>
  );
};
