import gql from 'graphql-tag';

export const FETCH_PICKING_ORDER_QUERY = gql`
  query FetchPickingOrderQuery {
    pickingOrder {
      sku
      position
    }
  }
`;

export const FETCH_PRODUCTS_QUERY = gql`
  query AllProductsQuery {
    products {
      id
      sku
      name
      color
      type
      expiration
      expirationCut
      pickingPriority
      fiscal {
        barcode
      }
      catalogueCategories {
        id
      }
    }
  }
`;

export const START_PICKING_MUTATION = gql`
  mutation StartPickingMutation($input: StartPicking!) {
    startPicking(input: $input) {
      order
      distributionCenter
      packageSize
      first
      second
      rejectedBatches {
        code
      }
      promotionalItems {
        name
        title
        image
      }
      hasBoughtGreenGrocer
    }
  }
`;

export const PRINT_TAGS_MUTATION = gql`
  mutation PrintTagsMutation($input: PrintTag!) {
    printTags(input: $input)
  }
`;

export const PRINT_NFE_MUTATION = gql`
  mutation PrintNFEMutation($order: String!) {
    printNFE(order: $order)
  }
`;

export const FINISH_PICKING_MUTATION = gql`
  mutation FinishPickingMutation($input: FinishPicking!) {
    finishPicking(input: $input)
  }
`;

export const CANCEL_PICKING_MUTATION = gql`
  mutation CancelPickingMutation($input: CancelPicking!) {
    cancelPicking(input: $input)
  }
`;

export const LOG_EVENT_MUTATION = gql`
  mutation LogEventMutation($input: LogEvent!) {
    logEvent(input: $input)
  }
`;

export const FETCH_SALE = gql`
  query FetchSaleQuery($order: Float!) {
    sale(order: $order) {
      id
      user {
        name
        cpf
      }
      logisticKind
      observations
      insideObservations {
        text
      }
      cart {
        deliverySettings {
          chosenPeriod {
            id
          }
          chosenDateString
          deliveryModifier
        }
        items {
          quantity
          kind
          ... on ProductCartItem {
            item {
              sku
              name
              type
            }
          }
          ... on MealCartItem {
            item {
              sku
              name
              type
              products
            }
          }
          ... on KitCartItem {
            item {
              sku
              name
              type
              breakfast {
                day
                products {
                  product {
                    sku
                    name
                    type
                  }
                }
              }
              midmorning {
                day
                products {
                  product {
                    sku
                    name
                    type
                  }
                }
              }
              lunch {
                day
                products {
                  product {
                    sku
                    name
                    type
                  }
                }
              }
              midafternoon {
                day
                products {
                  product {
                    sku
                    name
                    type
                  }
                }
              }
              dinner {
                day
                products {
                  product {
                    sku
                    name
                    type
                  }
                }
              }
              supper {
                day
                products {
                  product {
                    sku
                    name
                    type
                  }
                }
              }
              other {
                day
                products {
                  product {
                    sku
                    name
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
