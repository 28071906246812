import React, { useState } from 'react';
import {
  StyledModal,
  ModalContainer,
  HeaderContainer,
  ButtonsContainer,
  ActionButton,
  Header,
  ProductHeader,
  ProductName,
  RadioHeader,
} from './styles';
import { Radio } from 'src/components/atoms/radio';
import { useSnackbar } from 'notistack';

export interface ManualPickingModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
  product: any;
}

export const reasons = {
  TAG_PROBLEM: 'Impossível ler a etiqueta',
  MISSING_PRODUCT: 'Produto faltando',
};

export const ManualPickingModal: React.FC<ManualPickingModalProps> = ({ ...props }) => {
  const [reason, setReason] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const handleRadioSelect = (index: string) => {
    setReason(Object.keys(reasons)[Number(index)]);
  };
  const handleClick = () => {
    if (!reason) {
      enqueueSnackbar('Escolha uma razão', {
        variant: 'error',
      });
    } else {
      props.onConfirm(reason);
      setReason(null);
    }
  };

  const handleClose = () => {
    setReason(null);
    props.onClose();
  };
  return (
    <StyledModal {...props} open={props.open}>
      <ModalContainer>
        <HeaderContainer>
          <Header>Confirmação manual</Header>
        </HeaderContainer>
        <ProductHeader>Produto:</ProductHeader>
        <ProductName>{props.product && props.product.name}</ProductName>
        <RadioHeader>Informe o motivo para a conferência manual:</RadioHeader>
        <Radio labels={Object.values(reasons)} direction="row" onSelect={handleRadioSelect} selected={reasons[reason]} />
        <ButtonsContainer>
          <ActionButton size="large" variant="text" color="primary" onClick={handleClose}>
            Cancelar
          </ActionButton>
          <ActionButton size="large" variant="text" color="primary" onClick={handleClick}>
            Confirmar
          </ActionButton>
        </ButtonsContainer>
      </ModalContainer>
    </StyledModal>
  );
};
