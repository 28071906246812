import React, { useEffect, useState } from 'react';
import { FailBag } from 'src/common/icons/failBag';
import { SuccessBag } from 'src/common/icons/successBag';
import { CircularProgress } from 'src/components/atoms/circularProgress';
import { FinishPickingParams } from '../..';
import {
  Back,
  ButtonsContainer,
  Cancel,
  CircularProgressContainer,
  Container,
  Icon,
  Item,
  Message,
  MessageContainer,
  NextPicking,
  NextPickingTimer,
  Retry,
  SubContainer,
  Title,
} from './styles';
import { ExpressDeliveryBike } from "src/common/icons/expressDeliveryBike";

interface PickingSuccessProps {
  isExpressDelivery: boolean;
  order: string;
}

const PickingSuccess: React.FC<PickingSuccessProps> = ({ isExpressDelivery, order }) => {
  return isExpressDelivery ? (
    <React.Fragment>
      <Title>O pedido expresso {order} foi finalizado com sucesso!</Title>
      <Icon>
        <ExpressDeliveryBike />
      </Icon>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Title>O pedido {order} foi finalizado com sucesso!</Title>
      <Icon>
        <SuccessBag />
      </Icon>
    </React.Fragment>
  )
}

export interface PickingFinishedProps {
  success: boolean;
  nextPicking: ({ pickingLine }: { pickingLine: boolean }) => void;
  order: string;
  isExpressDelivery: boolean;
  loading: boolean;
  finishingPicking: boolean;
  params: FinishPickingParams;
  onRetry: (data: any) => void;
  onCancel: () => void;
  onForceCancel: () => void;
  onBack: () => void;
}

export const PickingFinished: React.FC<PickingFinishedProps> = ({ ...props }) => {
  const [order] = useState(props.order);
  const [timer, setTimer] = useState(5);
  const [loading, setLoading] = useState(false);

  let idleTime = 0;

  useEffect(() => {
    window.addEventListener('unload', props.onForceCancel, false);
    document.addEventListener('mousemove', resetIdleTime);
    const idleInterval = setInterval(timerIncrement, 60000);
    return () => {
      window.removeEventListener('unload', props.onForceCancel, false);
      document.removeEventListener('mousemove', resetIdleTime);
      clearInterval(idleInterval);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleKeyDown = (event: any) => {
    resetIdleTime();
    const { keyCode } = event;
    if (keyCode === 32 && !(loading || props.loading)) {
      handleNextPicking();
    }
  };

  const timerIncrement = () => {
    idleTime = idleTime + 1;
    if (idleTime > 10) {
      props.onCancel();
    }
  };
  const handleNextPicking = () => {
    setLoading(true);
    props.nextPicking({ pickingLine: true });
  };

  const resetIdleTime = () => {
    idleTime = 0;
  };
  const timerDecrement = () => {
    setTimer(timer - 1);
    if (!(loading || props.loading) && timer - 1 < 0) {
      handleNextPicking();
    }
  };

  useEffect(() => {
    function getInterval() {
      if (!props.success) {
        return null;
      }

      return setInterval(timerDecrement, 1000);
    }

    const nextPickingInterval = getInterval();
    return () => {
      clearInterval(nextPickingInterval);
    };
  });

  return (
    <Container title="Produtos para separar" {...props}>
      {props.success ? (
        <SubContainer>
          <Item>
            <PickingSuccess isExpressDelivery={props.isExpressDelivery} order={order}/>
            {loading || props.loading ? (
              <MessageContainer>
                <Message>Buscando próximo pedido...</Message>
              </MessageContainer>
            ) : (
              <MessageContainer>
                <Message>O próximo pedido vai começar em:</Message>
                <NextPickingTimer>{timer}</NextPickingTimer>
              </MessageContainer>
            )}
          </Item>
          {!(loading || props.loading) && (
            <React.Fragment>
              <Back size="large" variant="outlined" color="primary" onClick={props.onBack}>
                Voltar ao menu
              </Back>
              <NextPicking size="large" variant="contained" color="secondary" onClick={handleNextPicking}>
                Próximo pedido
              </NextPicking>
            </React.Fragment>
          )}
        </SubContainer>
      ) : (
        <SubContainer>
          <Item>
            <Title>Epa! Não conseguimos enviar o pedido. Por favor tente novamente</Title>
            <Icon>
              <FailBag />
            </Icon>
            {props.finishingPicking ? (
              <CircularProgressContainer>
                <CircularProgress size={30} />
              </CircularProgressContainer>
            ) : (
              <ButtonsContainer>
                <Retry size="large" variant="contained" color="secondary" onClick={() => props.onRetry(props.params)}>
                  Finalizar picking
                </Retry>
                <Cancel size="large" variant="outlined" color="primary" onClick={props.onCancel}>
                  Cancelar
                </Cancel>
              </ButtonsContainer>
            )}
          </Item>
        </SubContainer>
      )}
    </Container>
  );
};
