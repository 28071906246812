import React from 'react';

export const DropDown = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <title>outline-arrow_drop_down_circle-24px (1) copy 2</title>
    <desc>Created with Sketch.</desc>
    <g id="04_Protótipos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Acrescentar_etiquetas" transform="translate(-637.000000, -525.000000)" fill="#004D69" fill-rule="nonzero">
        <g id="outline-arrow_drop_down_circle-24px-(1)-copy-2" transform="translate(637.000000, 525.000000)">
          <path
            d="M10,2 C14.41,2 18,5.59 18,10 C18,14.41 14.41,18 10,18 C5.59,18 2,14.41 2,10 C2,5.59 5.59,2 10,2 L10,2 Z M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10,13 L6,9 L14,9 L10,13 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
