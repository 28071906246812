import styled from 'styled-components';

interface ContainerProps {
  loading: boolean;
  size: 'large' | 'small'
  index: number;
}

export const PeriodsContainer = styled.div<Partial<ContainerProps>>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.size === 'large' ? 'space-between' : 'space-evenly')};
  padding-top: 24px;
`;

export const Container = styled.div<Partial<ContainerProps>>`
  opacity ${props => (props.loading ? 0.6 : 1)};
  display ${props => (props.size === 'large' ? 'block' : 'flex')};
  justify-content: space-evenly;
`;

export const OrdersPerPeriodHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Divider = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  width: 1px;
  background-color: ${props => props.color || props.theme.palette.grayScale[20]};
`;

export const DayContainer = styled.div<Partial<ContainerProps>>`
  width:  ${props => props.size === 'small' ? '100%' : 'auto'}
  border-left: ${props => props.size === 'small' && props.index !== 0 ? `1px solid ${props.theme.palette.grayScale[20]}` : '0'};
  margin-left:  ${props => props.size === 'small' && props.index !== 0 ? '12px' : '0'}
  padding-left:  ${props => props.size === 'small' && props.index !== 0 ? '12px' : '0'}
`;
