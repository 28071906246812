import React, { Fragment } from 'react';
import {
  BagInfoBagIcon,
  BagsInfoBagTypeText,
  BagsInfoContainer,
  BagsInfoIconsContainer,
  BagsInfoIconsQuantityText,
  BagsInfoModelText,
  BagsInfoTagsText,
  PackageSizeContainer,
  BagIconAndQuantityContainer,
} from './styles';
import { PackageSize } from 'src/common/types';

enum BagsProductTypes {
  frozen = 'Congelados',
  snacks = 'Snacks',
  refrigerated = 'Sucos',
  greenGrocer = 'Quitanda',
}
export interface BagsInfoProps {
  packageSizes: PackageSize[];
  productType: string;
  firstOrder: boolean;
}

export const BagsInfo: React.FC<BagsInfoProps> = ({ ...props }) => {
  return (
    <BagsInfoContainer {...props}>
      <BagsInfoTagsText>Enquanto isso, as etiquetas já estão sendo impressa</BagsInfoTagsText>
      <BagsInfoIconsContainer>
        {props.packageSizes.map((pkg, index) => (
          <PackageSizeContainer index={index} hasTitle={index === 0 || pkg.type !== props.packageSizes[index - 1].type}>
            {(index === 0 || pkg.type !== props.packageSizes[index - 1].type) && (
              <BagsInfoIconsQuantityText>{BagsProductTypes[pkg.type]}</BagsInfoIconsQuantityText>
            )}
            <BagIconAndQuantityContainer>
              <BagsInfoIconsQuantityText>{pkg.quantity}x</BagsInfoIconsQuantityText>
              <BagInfoBagIcon>{pkg.icon}</BagInfoBagIcon>
            </BagIconAndQuantityContainer>
          </PackageSizeContainer>
        ))}
      </BagsInfoIconsContainer>
    </BagsInfoContainer>
  );
};
