import React from "react";
import styled from "styled-components";
import { Typography } from "../../atoms/typography/styles";

export const PageTitle = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[80]}
    margin-bottom: 16px;
    font-size: ${props => props.theme.typography.fontSize.h2};
    font-weight: bold;
  }
`;
