import React from 'react';

export const Minus = () => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <title>outline-remove_circle_outline-24px</title>
    <desc>Created with Sketch.</desc>
    <g id="04_Protótipos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Acrescentar_etiquetas" transform="translate(-647.000000, -417.000000)">
        <g id="outline-remove_circle_outline-24px" transform="translate(646.000000, 416.000000)">
          <polygon id="Path" points="0 0 20 0 20 20 0 20"></polygon>
          <path
            d="M5.83333333,10 C5.83333333,10.4602373 6.20642938,10.8333333 6.66666667,10.8333333 L13.3333333,10.8333333 C13.7935706,10.8333333 14.1666667,10.4602373 14.1666667,10 C14.1666667,9.53976271 13.7935706,9.16666667 13.3333333,9.16666667 L6.66666667,9.16666667 C6.20642938,9.16666667 5.83333333,9.53976271 5.83333333,10 Z M10,1.66666667 C5.4,1.66666667 1.66666667,5.4 1.66666667,10 C1.66666667,14.6 5.4,18.3333333 10,18.3333333 C14.6,18.3333333 18.3333333,14.6 18.3333333,10 C18.3333333,5.4 14.6,1.66666667 10,1.66666667 Z M10,16.6666667 C6.325,16.6666667 3.33333333,13.675 3.33333333,10 C3.33333333,6.325 6.325,3.33333333 10,3.33333333 C13.675,3.33333333 16.6666667,6.325 16.6666667,10 C16.6666667,13.675 13.675,16.6666667 10,16.6666667 Z"
            id="Shape"
            fill="#004D69"
            fill-rule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
