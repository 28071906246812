import gql from 'graphql-tag';

/**
 * Mutations
 */

export const SIGNIN_USER_MUTATION = gql`
  mutation LoginMutation($input: EmailSignIn!) {
    emailSignIn(input: $input) {
      accessToken
      user {
        name
        email
      }
    }
  }
`;
