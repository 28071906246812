import React from 'react';

export const SmallBag = () => (
  <svg width="89px" height="107px" viewBox="0 0 89 107" version="1.1">
    <title>Group 6</title>
    <desc>Created with Sketch.</desc>
    <g id="04_Protótipos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Sacola_plastico" transform="translate(-419.000000, -339.000000)">
        <g id="Group-6" transform="translate(419.000000, 339.000000)">
          <path
            d="M88,1 L88,59.4901389 L76.8001019,54.5538229 L53.9639117,63.6914894 L2,84 L2,1 L88,1 Z M44.3320158,11.0606061 L23.2332016,11.0606061 C20.4550419,11.0606061 18.2028986,13.3127494 18.2028986,16.0909091 C18.2028986,18.8690687 20.4550419,21.1212121 23.2332016,21.1212121 L23.2332016,21.1212121 L44.3320158,21.1212121 C47.1101755,21.1212121 49.3623188,18.8690687 49.3623188,16.0909091 C49.3623188,13.3127494 47.1101755,11.0606061 44.3320158,11.0606061 L44.3320158,11.0606061 Z"
            id="Combined-Shape-Copy"
            fill="#E57374"
          ></path>
          <polygon id="Path-6" fill="#CC5253" points="66 3 66 59.2710803 77 55.0054616 88 60 88 3"></polygon>
          <path
            d="M87,1.5 L2,1.5 C1.72385763,1.5 1.5,1.72385763 1.5,2 L1.5,105 C1.5,105.276142 1.72385763,105.5 2,105.5 L87,105.5 C87.2761424,105.5 87.5,105.276142 87.5,105 L87.5,2 C87.5,1.72385763 87.2761424,1.5 87,1.5 Z M43.8520298,9.82941176 C47.4613731,9.82941176 50.3873239,12.7553626 50.3873239,16.3647059 C50.3873239,19.9740492 47.4613731,22.9 43.8520298,22.9 L22.5845899,22.9 C18.9752466,22.9 16.0492958,19.9740492 16.0492958,16.3647059 C16.0492958,12.7553626 18.9752466,9.82941176 22.5845899,9.82941176 L43.8520298,9.82941176 Z"
            id="Combined-Shape-Copy-2"
            stroke="#004D69"
            stroke-width="3"
          ></path>
          <path
            d="M65.5,2 L65.5,104"
            id="Path-2-Copy-5"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M76.5,43 L76.5,92.6596788"
            id="Path-2-Copy-6"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M75.5769231,93.5304293 L65,105.301452"
            id="Path-2-Copy-7"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M85.5833333,93.4986943 L76.625,104"
            id="Path-2-Copy-8"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            transform="translate(81.000000, 98.500000) scale(-1, 1) translate(-81.000000, -98.500000) "
          ></path>
          <path
            d="M77,2 L77,34.3653846"
            id="Path-2-Copy-9"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <text id="P" font-family="Lato-Black, Lato" font-size="20" font-weight="700" fill="#004D69">
            <tspan x="28" y="95">
              P
            </tspan>
          </text>
          <g id="logo-rgb-branco-copy-2" transform="translate(26.000000, 32.000000)" fill="#FFFFFF">
            <path
              d="M5.10861132,8.96163747 C5.23186609,9.27301813 5.34400678,9.34822117 5.40592259,9.76068837 C5.46787686,10.1730072 5.89771105,9.96286145 6.11976193,9.79309836 C6.34246657,9.62329819 6.97239267,9.29975452 7.23290057,8.26437768 C7.37842196,7.68689394 7.55901616,5.78975991 7.59758856,5.27038425 C7.63604558,4.75093441 7.20767276,5.11745656 6.80245107,5.24220165 C6.36661758,5.37662524 5.51202553,6.0123283 5.33416178,6.31317756 C5.15595192,6.61391558 4.98531809,8.65018265 5.10861132,8.96163747"
              id="Fill-1"
            ></path>
            <path
              d="M5.39656511,1.29629826 C5.405607,1.26707538 5.37324418,1.24257079 5.33958326,1.25208344 C4.90015612,1.37601429 3.80863825,1.1441719 2.95874488,2.00902431 C2.21220663,2.76866678 1.93266884,1.69959672 1.19074107,3.0640537 C0.691377795,3.98225308 0.429744743,5.54168141 0.233240193,6.72265833 C-0.0962070712,8.70060537 -0.0501918827,10.8569337 0.206427841,12.5534964 L0.206606888,12.5545619 C0.287760134,13.1595286 0.656821641,13.0955274 0.913038508,13.3349039 C1.16947918,13.5743944 1.57251494,13.7547163 1.91601743,13.6257627 C2.25969896,13.4965809 2.8648345,14.0233156 3.34772541,13.5453237 C3.83061632,13.0671797 4.50616226,12.3357328 5.54553257,11.5665776 C6.56722194,10.8106641 8.31356101,9.83862314 8.84824139,9.67538601 C8.86377375,9.67051553 8.87080136,9.6563607 8.86417661,9.64342349 C8.83382807,9.58174345 8.73920151,9.42276798 8.56870359,9.4179736 C8.35684573,9.41199966 8.28880771,9.42801896 7.9926185,9.32044988 C7.79333872,9.24815371 7.86486816,9.11889577 6.82898928,9.44670182 C5.63357914,9.82530543 4.58158598,10.2587019 3.71674215,10.7862737 C3.6889898,10.8027116 3.60286799,10.8572381 3.58952896,10.865457 C2.39890834,11.627725 3.35936349,7.14795005 3.9700495,5.22681257 C4.2011102,4.50012194 5.24898527,1.77965521 5.39656511,1.29629826"
              id="Fill-2"
            ></path>
            <path
              d="M6.70313756,3.58902843 C6.98590822,3.78182988 7.40346664,3.92074629 7.93071206,3.27489008 C9.14530635,1.7873189 9.38320505,0.867375073 7.39637481,1.40469687 C5.97723492,1.78835074 6.23748363,3.27175634 6.70313756,3.58902843"
              id="Fill-3"
            ></path>
            <path
              d="M18.3644408,0.459573854 C18.3027854,0.430578216 18.2488223,0.388778798 18.1983769,0.342409414 C17.9432121,0.107371403 17.0857178,-0.0504105679 16.8387093,0.0147902215 C14.6526649,0.592339208 10.559978,5.74611689 10.5577746,5.70053543 C10.5306385,5.16454949 10.4080233,4.51336891 10.0228989,4.10104779 C9.86417948,3.93113177 9.44751213,3.92762551 9.33204823,3.92006143 C8.69199089,3.87759227 8.79752034,7.77683586 9.13869355,8.64532613 C9.16072717,8.70154457 9.21519273,8.73538595 9.27456754,8.73262821 C9.81245845,8.70702065 10.2125813,8.89891977 10.6998722,8.42458895 C11.7288809,7.42317574 13.0591317,5.69328652 13.8291487,4.98671483 C14.6917843,4.19508663 14.68127,4.68517595 15.0198533,4.27829151 C15.7473106,3.40452214 17.5380255,2.48036497 18.6163586,1.75192835 C19.4634933,1.17942209 18.6904224,0.612943445 18.3644408,0.459573854"
              id="Fill-4"
            ></path>
            <path
              d="M15.2779043,6.2507054 C13.2254312,6.28896316 10.4945316,7.61185545 9.72553932,9.35025963 C8.95332041,11.0966491 8.69444077,14.4398135 8.97891223,15.9321444 C9.20419984,18.0478821 9.5890054,18.8412043 10.0764434,18.7417659 C10.0968639,18.737674 10.1140577,18.7257954 10.1266105,18.7108578 C10.2783492,18.5283885 10.499261,18.4059081 10.5846997,18.3364641 C10.6921942,18.248944 11.1657535,17.9772066 11.0956522,17.6104803 C11.079033,17.5235958 11.069751,17.4362347 11.068646,17.3480789 C11.0407115,15.4281987 11.758697,9.26281899 12.8392554,8.0363458 C13.4583212,7.33372191 14.4114951,7.61161709 14.6198099,7.91859293 C15.4102392,9.08328957 14.5115198,10.1145012 14.4907458,10.4927882 C14.4750105,10.7835552 14.6865961,10.7906267 14.5788806,11.0728522 C14.2252803,11.9978717 12.6921577,12.8982203 11.6790927,13.2091689 C11.6545175,13.2167172 11.6431581,13.2392428 11.6541197,13.2605368 C11.6888167,13.3297027 11.7773494,13.4681537 11.92816,13.4726032 C12.1294912,13.4785226 12.1941558,13.4626316 12.4756659,13.5704922 C13.9054488,13.595759 16.6865154,11.2661274 17.3010728,10.0730652 C18.2576501,8.21615332 17.6451701,6.20684603 15.2779043,6.2507054"
              id="Fill-5"
            ></path>
            <path
              d="M8.43852985,11.2747731 C7.82991931,11.4285164 6.80723419,12.0537933 6.5542659,12.2892296 C6.34899748,12.4092613 6.29040614,12.6721879 6.26218372,13.083453 C6.22541508,13.6183271 6.06156604,13.43861 6.02543339,13.9134488 C6.0047237,14.1832188 5.99259998,14.523017 5.99391173,14.8955158 C5.99685322,15.6688201 6.05874379,16.5845139 6.22966831,17.3165635 C5.7565254,18.8654663 4.25656337,19.6269502 4.43762411,17.2053582 C4.52483536,16.0399969 4.69886037,15.0175305 5.02262321,14.1200339 C5.06169735,13.9720065 4.86926814,13.8995287 4.59606716,13.9788499 C4.05364019,14.1365592 3.22879026,14.7090331 3.02900732,14.9080361 C2.85184207,15.0387217 2.80159821,15.2932107 2.77894078,15.6982156 C2.74924761,16.2252353 2.60622751,16.0514674 2.57701135,16.5191905 C2.49230433,17.8723176 2.36900018,19.5359641 3.9432945,19.9335424 C5.24112835,20.2614035 6.40568082,19.3048049 6.94385455,18.6239253 C6.94385455,18.6239253 6.98161695,18.6843883 7.10245661,18.8036035 C7.16812342,18.8684992 7.229855,18.9121258 7.26070091,18.9325394 C7.27286438,18.9403938 7.28796933,18.9375942 7.29731056,18.9270569 C7.39664553,18.8145685 7.58971073,18.6866435 7.97142891,18.5297119 C8.06682864,18.5104648 8.09505106,18.3553218 8.20555575,18.2767393 C8.31637844,18.1982735 8.37481077,18.2049225 8.40669018,18.1049933 C8.43852985,18.0051418 8.4017612,18.031349 8.35251109,17.8733286 C8.15646464,17.2492959 8.2234432,15.2886225 8.44934181,13.7382421 C8.53210108,13.1708231 8.70250885,12.0197707 8.86210465,11.4265722 C8.8985553,11.2896653 8.71284382,11.2054837 8.43852985,11.2747731"
              id="Fill-6"
            ></path>
          </g>
          <path d="" id="Path-5" stroke="#979797"></path>
        </g>
      </g>
    </g>
  </svg>
);
