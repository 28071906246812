import styled from 'styled-components';

export const PeriodOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding-left: 24px;
  padding-right: 24px;
  opacity: ${props => (props.size === 'small' && !props.active ? '0.2' : '1')};
`;

export const PeriodOrderTextContainer = styled.div`
  margin-top: ${props => props.gutter};
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const PeriodOrderImage = styled.div`
  height: ${props => (props.size === 'large' ? '50px' : '40px')};
  width: ${props => (props.size === 'large' ? '50px' : '40px')};
  margin-bottom: 12px;
`;
