import React from 'react';
import {
  MealCardContainer,
  MealCardInfoContainer,
  MealCardProductsContainer,
  MealQuantityText,
  MealText,
  MealProductsQuantityText,
} from './styles';
import { ProductCard } from 'src/common/picking/molecules/productCard';

export interface ProductCardProps {
  meal: any;
  onManualPickIconClick?: (data: any) => void;
  alreadyFinishedPicking?: boolean;
}

export const MealCard: React.FC<ProductCardProps> = ({ ...props }) => {
  return (
    <MealCardContainer {...props} itemsQuantity={props.meal.products.length} totalQuantity={props.meal.totalQuantity}>
      <MealCardInfoContainer itemsQuantity={props.meal.products.length} totalQuantity={props.meal.totalQuantity}>
        <MealText>Prato</MealText>
        <MealQuantityText>{props.meal.totalQuantity}x</MealQuantityText>
        <MealText>Total</MealText>
        <MealProductsQuantityText>
          {!props.alreadyFinishedPicking ? `${props.meal.products.reduce((acc, cur) => acc + cur.checks, 0)}/` : ''}
          {props.meal.products.length * props.meal.totalQuantity}
        </MealProductsQuantityText>
      </MealCardInfoContainer>

      <MealCardProductsContainer>
        {props.meal.products &&
          props.meal.products.map((product, index) => (
            <ProductCard
              key={product.key}
              index={index}
              kind="meal"
              product={product}
              onManualPickIconClick={props.onManualPickIconClick}
              alreadyFinishedPicking={props.alreadyFinishedPicking}
            />
          ))}
      </MealCardProductsContainer>
    </MealCardContainer>
  );
};
