import { Typography } from 'src/components/atoms/typography';
import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import { Paper } from 'src/components/atoms/paper';
import { Button } from 'src/components/atoms/button';

export const StyledModal = styled(Modal)`
  && {
  }
`;

export const ModalContainer = styled(Paper)`
  && {
    width: 520px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
`;

export const Header = styled(Typography)`
  color: ${props => props.theme.palette.grayScale[80]};
  font-size: ${props => props.theme.typography.fontSize.large};
  font-weight: bold;
`;

export const ProductHeader = styled(Typography)`
  color: ${props => props.theme.palette.grayScale[80]};
  font-size: ${props => props.theme.typography.fontSize.small};
  margin-bottom: 4px;
`;

export const ProductName = styled(Typography)`
  color: ${props => props.theme.palette.primary.darker};
  font-size: ${props => props.theme.typography.fontSize.medium};
  margin-bottom: 24px;
  font-weight: bold;
`;

export const RadioHeader = styled(Typography)`
  color: ${props => props.theme.palette.grayScale[80]};
  font-size: ${props => props.theme.typography.fontSize.medium};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ActionButton = styled(Button)`
  margin-left: 10px;
`;
