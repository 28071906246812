import React from 'react';
import * as R from 'ramda';
import {
  FormattedProduct,
  FormattedSale,
  Marketables,
  PackageSize,
  PickingOrder,
  PickingTypes,
  ProductsBySku,
  ProductTypes,
  PRODUCT_TYPES_BY_PICKING_TYPE,
} from 'src/common/types';
import { FetchSaleQuery_sale } from 'src/apollo/types/FetchSaleQuery';
import { extractProductsFromKits } from '../extracting/extractProductsFromKits';
import { extractProductsFromMeals } from '../extracting/extractProductsFromMeals';
import { filterByKind, filterBySkuInitials, filterOutBySkuInitials } from '../filters';
import { formatKits } from './formatKits';
import { mergeAndFormatMeals, mergeAndFormatProducts } from '../merging';
import { setMarketablesStatus } from '../status/setMarketablesStatus';
import { SmallBag } from 'src/common/icons/smallBag';
import { BigBag } from 'src/common/icons/bigBag';
import { ClothBag } from 'src/common/icons/clothBag';

const SMALL_BAG = 10;
const BIG_BAG = 22;
const CLOTH_BAG = 32;

export const formatSaleForPicking = (
  sale: FetchSaleQuery_sale,
  productsBySku: ProductsBySku,
  pickingType: PickingTypes,
  firstOrder: boolean,
  packageSize: string,
): FormattedSale => {
  const productTypes = PRODUCT_TYPES_BY_PICKING_TYPE[pickingType];

  const lunchBoxesCartItems = sale.cart.items.filter(filterBySkuInitials('V'));
  const soupCartItems = sale.cart.items.filter(filterBySkuInitials('S9'));
  const mealCartItems = sale.cart.items.filter(filterByKind(Marketables.Meal));
  const kitCartItems = sale.cart.items.filter(filterByKind(Marketables.Kit));
  const productCartItems = sale.cart.items
    .filter(filterByKind(Marketables.Product))
    .filter(filterOutBySkuInitials(['V', 'S9']));

  if (pickingType === PickingTypes.frozen) {
    const products = mergeAndFormatProducts(productCartItems, productsBySku, productTypes);
    const lunchBoxes = mergeAndFormatProducts(lunchBoxesCartItems, productsBySku, productTypes);
    const soups = mergeAndFormatProducts(soupCartItems, productsBySku, productTypes);
    const meals = mergeAndFormatMeals(mealCartItems, productsBySku, productTypes);
    const kits = formatKits(kitCartItems, productsBySku, productTypes);
    const packageSizes = getPackageSizesForFrozenAndSnacks(packageSize, ProductTypes.frozen);
    const reorderedProducts = arrangeProductsByPriority(products);
    const marketables = [
      { items: lunchBoxes, kind: Marketables.Product },
      { items: kits, kind: Marketables.Kit },
      { items: meals, kind: Marketables.Meal },
      { items: soups, kind: Marketables.Product },
      { items: reorderedProducts, kind: Marketables.Product },
    ];
    return {
      marketables: setMarketablesStatus(marketables),
      packageSizes,
    };
  } else {
    const productsFromMeals = extractProductsFromMeals(mealCartItems, productsBySku, productTypes);
    const productsFromKits = extractProductsFromKits(kitCartItems, productTypes);
    const flattenProducts = [
      ...productCartItems,
      ...lunchBoxesCartItems,
      ...soupCartItems,
      ...productsFromMeals,
      ...productsFromKits,
    ].flat();

    const allProducts = mergeAndFormatProducts(flattenProducts, productsBySku, productTypes);
    const reorderedProducts = arrangeProductsByPriority(allProducts);
    const marketables = [{ items: reorderedProducts, kind: Marketables.Product }];

    if (pickingType === PickingTypes.snacks) {
      const snacks = reorderedProducts.filter(product => product.type === ProductTypes.snacks);
      const refrigerated = reorderedProducts.filter(product => product.type === ProductTypes.refrigerated);

      return {
        marketables: setMarketablesStatus(marketables),
        packageSizes: [
          ...getPackageSizesForFrozenAndSnacks(packageSize, ProductTypes.snacks),
          ...getRefrigeratedPackageSizes(refrigerated, firstOrder, snacks.length > 0),
        ],
      };
    }
    if (pickingType === PickingTypes.greenGrocer) {
      return {
        marketables: setMarketablesStatus(marketables),
        packageSizes: getPackageSizesForFrozenAndSnacks(packageSize, ProductTypes.greenGrocer),
      };
    }
    if (pickingType === PickingTypes.basket) {
      return {
        marketables: setMarketablesStatus(marketables),
        packageSizes: getBasketsPackageSizes(allProducts),
      };
    }

    return {
      marketables: setMarketablesStatus(marketables),
      packageSizes: [],
    };
  }
};

const getPackageSizesForFrozenAndSnacks = (packageSize: string, productType: ProductTypes): PackageSize[] => {
  if (!packageSize || packageSize === '') {
    return [];
  }
  const packageSizes: any[] = [];
  const sizesStrings = packageSize.trim().split(',');
  sizesStrings.forEach(sizeString => {
    const strings = sizeString.trim().split(' ');
    if (strings.length === 4) {
      packageSizes.push({
        type: productType,
        quantity: Number(strings[0]),
        icon: getIcon(strings[3]),
      });
    } else {
      packageSizes.push({
        type: productType,
        quantity: Number(strings[0]),
        icon: getIcon(strings[2]),
      });
    }
  });
  return packageSizes;
};

const getIcon = (size: string): JSX.Element => {
  if (size === 'P') {
    return <SmallBag />;
  }
  if (size === 'G') {
    return <BigBag />;
  }
  if (size === 'Pano') {
    return <ClothBag />;
  }
  return <div />;
};

const getRefrigeratedPackageSizes = (refrigerated: any[], firstOrder: boolean, hasSnacks: boolean): any[] => {
  if (!refrigerated || refrigerated.length === 0) {
    return [];
  }
  const packageSizes: any[] = [];
  let total = refrigerated.reduce((acc, curr) => acc + curr.totalQuantity, 0);
  if (firstOrder && !hasSnacks) {
    total = total - CLOTH_BAG;
    packageSizes.push({
      type: ProductTypes.refrigerated,
      quantity: 1,
      icon: getIcon('Pano'),
    });
  }
  if (total > 0) {
    let bigBagsQuantity = total >= BIG_BAG && Math.floor(total / BIG_BAG);
    if (bigBagsQuantity) {
      total = total - bigBagsQuantity * BIG_BAG;
      if (total > SMALL_BAG) {
        bigBagsQuantity += 1;
        total = 0;
      }
      packageSizes.push({
        type: ProductTypes.refrigerated,
        quantity: bigBagsQuantity,
        icon: getIcon('G'),
      });
    }
    if (total > 0) {
      const smallBagsQuantity = Math.ceil(total / SMALL_BAG);
      packageSizes.push({
        type: ProductTypes.refrigerated,
        quantity: smallBagsQuantity,
        icon: getIcon('P'),
      });
    }
  }
  return packageSizes;
};

const getBasketsPackageSizes = (baskets: FormattedProduct[]) => {
  if (!baskets || baskets.length === 0) {
    return [];
  }
  return [
    {
      type: ProductTypes.basket,
      quantity: baskets.reduce((acc, curr) => acc + curr.totalQuantity, 0),
      icon: getIcon('G'),
    },
  ];
};

function arrangeProductsByPriority(products: FormattedProduct[]): FormattedProduct[] {
  return R.sortWith([R.ascend(R.propOr(3, 'pickingPriority'))], products);
};
