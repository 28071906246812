import React from 'react';
import { KindTitle, ModalContainer, ModalTitle, StyledModal } from './styles';
import { ProductCard } from 'src/common/picking/molecules/productCard';
import { MealCard } from 'src/common/picking/organisms/mealCard';
import { KitDayCard } from 'src/common/picking/organisms/kitDayCard';
import { FormattedSale, Marketables } from 'src/common/types';
import { filterByKind } from 'src/common/picking/logic/filters';

export interface AllProductsModalProps {
  open: boolean;
  onClose: () => void;
  sale: FormattedSale;
  alreadyFinishedPicking: boolean;
}
const setProductStatus = (product: any) => ({ ...product, status: 'checking' });
const allProductsModalFormat = (sale: FormattedSale) => {
  const products = sale.marketables
    .filter(filterByKind(Marketables.Product))
    .map(marketable => marketable.items)
    .flat()
    .map(setProductStatus);
  const meals = sale.marketables
    .filter(filterByKind(Marketables.Meal))
    .map(marketable => marketable.items)
    .flat()
    .map((meal: any) => ({
      ...meal,
      status: 'checking',
      products: meal.products.map(setProductStatus),
    }));
  const kits = sale.marketables
    .filter(filterByKind(Marketables.Kit))
    .map(marketable => marketable.items)
    .flat()
    .map((kit: any) => ({
      ...kit,
      status: 'checking',
      kitDays: kit.kitDays.map((kitDay: any) => ({
        ...kitDay,
        status: 'checking',
        products: kitDay.products.map(setProductStatus),
      })),
    }));
  return { products, meals, kits };
};

export const AllProductsModal: React.FC<AllProductsModalProps> = ({ ...props }) => {
  const sale = allProductsModalFormat(props.sale);
  const handleClose = () => {
    props.onClose();
  };
  const renderProducts = () =>
    sale &&
    sale.products &&
    sale.products.length > 0 && (
      <div>
        <KindTitle>Produtos</KindTitle>
        {sale.products.map((product: any, index: number) => (
          <ProductCard
            index={index}
            kind="product"
            product={product}
            alreadyFinishedPicking={props.alreadyFinishedPicking}
          />
        ))}
      </div>
    );

  const renderMeals = () =>
    sale &&
    sale.meals &&
    sale.meals.length > 0 && (
      <div>
        <KindTitle>Pratos</KindTitle>
        {sale &&
          sale.meals.map((meal: any) => <MealCard meal={meal} alreadyFinishedPicking={props.alreadyFinishedPicking} />)}
      </div>
    );

  const renderKits = () =>
    sale &&
    sale.kits &&
    sale.kits.length > 0 && (
      <div>
        <KindTitle>Kits</KindTitle>
        {sale.kits.map((kit: any) =>
          kit.kitDays.map((kitDay: any) => (
            <KitDayCard kitDay={kitDay} modal={true} alreadyFinishedPicking={props.alreadyFinishedPicking} />
          ))
        )}
      </div>
    );
  return (
    <StyledModal {...props} open={props.open}>
      <ModalContainer>
        <ModalTitle>Lista de produtos</ModalTitle>
        {renderProducts()}
        {renderMeals()}
        {renderKits()}
      </ModalContainer>
    </StyledModal>
  );
};
