import React from 'react';
import styled from 'styled-components';
import { Typography } from 'src/components/atoms/typography/styles';
import { Lookup } from 'src/components/molecules/lookup';
import { Divider } from '@material-ui/core';
import { Radio } from 'src/components/atoms/radio';
import Input from 'src/components/atoms/input';
import { Button } from 'src/components/atoms/button';

export const DcSelectInstruction = styled(Typography)`
  && {
    margin-bottom: 24px;
    font-size: ${props => props.theme.typography.fontSize.medium};
    color: ${props => props.theme.palette.grayScale[80]};
  }
`;

export const PickingInitialFormDistributionCentersLookup = styled(Lookup)`
  && {
    margin-bottom: 30px;
  }
`;

export const PcIdInstruction = styled(Typography)`
  && {
    font-size: ${props => props.theme.typography.fontSize.medium};
    color: ${props => props.theme.palette.grayScale[80]};
    margin-bottom: 12px;
  }
`;

export const ProductTypeInstruction = styled(Typography)`
  && {
    font-size: ${props => props.theme.typography.fontSize.medium};
    color: ${props => props.theme.palette.grayScale[80]};
  }
`;

export const LogisticKindInstruction = styled(Typography)`
  && {
    font-size: ${props => props.theme.typography.fontSize.medium};
    color: ${props => props.theme.palette.grayScale[80]};
  }
`;

export const PickingInitialFormProductTypesRadio = styled(Radio)`
  && {
    margin-bottom: 32px;
  }
`;

export const PickingInitialFormLogisticKindsRadio = styled(Radio)`
  && {
    margin-bottom: 32px;
  }
`;

export const PickingInitialFormPcIdInput = styled(Input)`
  && {
    width: 100%;
    margin-bottom: 38px;
  }
`;

export const PickingInitialFormOrderInput = styled(Input)`
  && {
    width: 100%;
    margin-bottom: 38px;
  }
`;
export const StyledDivider = styled(props => <Divider {...props} />)`
  && {
    margin-bottom: 34px;
  }
`;

export const StartPickingInstruction = styled(Typography)`
  && {
    font-size: ${props => props.theme.typography.fontSize.medium};
    color: ${props => props.theme.palette.grayScale[80]};
    margin-bottom: 19px;
  }
`;

export const StartPickingButton = styled(Button)`
  && {
    height: 45px;
    span {
        font-size: 16px;
    }
    width: 100%
    margin-bottom: 19px;
  }
`;

export const StartPickingCircularProgressContainer = styled.div`
  height: 45px;
  display: flex;
  justify-content: center;
`;
