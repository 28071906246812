import React, { useState } from 'react';
import HeaderfulContainer from 'src/components/molecules/headerfulContainer';
import { CircularProgress } from 'src/components/atoms/circularProgress';

import {
  DcSelectInstruction,
  PickingInitialFormDistributionCentersLookup,
  ProductTypeInstruction,
  PickingInitialFormProductTypesRadio,
  PickingInitialFormOrderInput,
  StyledDivider,
  StartPickingInstruction,
  StartPickingButton,
  StartPickingCircularProgressContainer,
  PickingInitialFormPcIdInput,
  LogisticKindInstruction,
  PickingInitialFormLogisticKindsRadio,
} from './styles';
import { useQuery } from 'react-apollo';
import { gql } from 'apollo-boost';
import { dcHasMultipleStations } from 'src/common/picking/logic/dcHasMultipleStations';
import { LogisticKinds, LogisticKindsPT, PickingTypesPT } from 'src/common/types';

export interface PickingInitialFormProps {
  selectedDistributionCenter: any;
  onDistributionCenterSelect: (data: any) => void;
  onProductTypeSelect: (data: any) => void;
  onLogisticKindSelect: (index: string) => void;
  pickingType: PickingTypesPT;
  logisticKind: LogisticKinds;
  productTypes: any[];
  logisticKindsValues: LogisticKinds[];
  logisticKindsLabels: LogisticKindsPT[];
  onOrderInputChange: (data: any) => void;
  nextPicking: (data: any) => void;
  loadingSale: boolean;
  onError: (error: Error) => void;
  pcId?: number;
  onPcIdInputChange: (data: any) => void;
}

const mapDistributionCentersToOptions = (distributionCenters: any[]) =>
  distributionCenters.map(distributionCenter => ({
    value: distributionCenter.id,
    label: distributionCenter.name,
    hasNfce: distributionCenter.hasNfce,
  }));

export const PickingInitialForm: React.FC<PickingInitialFormProps> = ({ ...props }) => {
  const { data } = useQuery(FETCH_DCS_QUERY, { onError: props.onError });
  const [value, setValue] = useState<string>();
  const handleClick = () => {
    props.nextPicking({});
    setValue('');
  };
  const handleChange = (event: any) => {
    const { target } = event;
    setValue(target.value);
    props.onOrderInputChange(event);
  };

  const handlePcIdChange = (event: any) => {
    const {
      target: { value },
    } = event;
    if (/^[0-9]*$/.test(value)) {
      props.onPcIdInputChange(event);
    }
  };
  return (
    <HeaderfulContainer title="Informações iniciais">
      <DcSelectInstruction>
        Antes de iniciar o picking, selecione o centro de distribuição e o tipo de produto
      </DcSelectInstruction>
      <PickingInitialFormDistributionCentersLookup
        options={
          (data &&
            data.distributionCenters &&
            data.distributionCenters.length > 0 &&
            mapDistributionCentersToOptions(data.distributionCenters)) ||
          []
        }
        placeholder="Selecione o centro de distribuição (obrigatório)"
        selected={props.selectedDistributionCenter}
        onSelect={props.onDistributionCenterSelect}
      />
      {props.selectedDistributionCenter && dcHasMultipleStations(props.selectedDistributionCenter.value) && (
        <React.Fragment>
          <PickingInitialFormPcIdInput
            label="Informe o número do computador"
            onChange={handlePcIdChange}
            value={String(props.pcId)}
          />
        </React.Fragment>
      )}

      <ProductTypeInstruction>Tipo de produto (obrigatório)</ProductTypeInstruction>
      <PickingInitialFormProductTypesRadio
        labels={props.productTypes}
        direction="row"
        onSelect={props.onProductTypeSelect}
        selected={props.pickingType}
      />

      <LogisticKindInstruction>Tipo de cliente (obrigatório)</LogisticKindInstruction>
      <PickingInitialFormLogisticKindsRadio
        labels={props.logisticKindsLabels}
        direction="row"
        onSelect={props.onLogisticKindSelect}
        selected={props.logisticKindsLabels[props.logisticKindsValues.indexOf(props.logisticKind)]}
      />

      <PickingInitialFormOrderInput
        label="Informe o número de um pedido (opcional)"
        onChange={handleChange}
        value={value}
      />
      <StyledDivider />
      <StartPickingInstruction>
        Após selecionar os campos obrigatórios, você pode iniciar o próximo picking da fila
      </StartPickingInstruction>
      {props.loadingSale ? (
        <StartPickingCircularProgressContainer>
          <CircularProgress size={40} />
        </StartPickingCircularProgressContainer>
      ) : (
        <StartPickingButton onClick={handleClick} size="large" variant="contained" color="secondary">
          Iniciar picking
        </StartPickingButton>
      )}
    </HeaderfulContainer>
  );
};

const FETCH_DCS_QUERY = gql`
  query AllDistributionCentersQuery {
    distributionCenters {
      id
      name
      hasNfce
    }
  }
`;
