import React from 'react';
import { PeriodsContainer, Container, OrdersPerPeriodHeader, Divider, DayContainer } from './styles';
import { PeriodOrder, Size } from '../../molecules/PeriodOrder';
import { Typography } from 'src/components/atoms/typography/styles';
import { Day } from 'src/common/picking/organisms/ordersPerPeriodContainer';

interface OrdersPerPeriodProps {
  days: any;
  loading: boolean;
  size: Size;
}

export const OrdersPerPeriod: React.FC<OrdersPerPeriodProps> = props => {
  return (
    <Container loading={props.loading} size={props.size}>
      {props.days.map((day: Day, index: number) => (
        <React.Fragment>
          {day.periods && day.periods.length > 0 && (
            <DayContainer size={props.size} index={index}>
              <OrdersPerPeriodHeader>
                <Typography fontSize="medium" color="#004D69" fontWeight="bold">
                  {day.date}
                </Typography>

                {props.size === 'large' && (
                  <React.Fragment>
                    <Divider />
                    <Typography fontSize="medium" color="#004D69" fontWeight="bold">
                      {`Total de pedidos: ${day.ordersTotal}`}
                    </Typography>
                  </React.Fragment>
                )}
              </OrdersPerPeriodHeader>
              <PeriodsContainer size={props.size}>
                {day.periods.map(period => {
                  return (
                    <PeriodOrder
                      image={period.icon}
                      gutter="2px"
                      period={period.name}
                      counter={period.orders}
                      size={props.size}
                      active={day.active && period.active}
                    />
                  );
                })}
              </PeriodsContainer>
            </DayContainer>
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};
