import React from 'react';
import {
  ProductCardContainer,
  ProductCardManualPickIcon,
  ProductCardName,
  ProductCardQuantityProgress,
  ProductCardManualPickIconContainer,
  ProductCardQuantity,
  CheckBoxContainer,
  ProductCheckBox,
} from './styles';
import { ListIcon } from 'src/common/icons/listIcon';
export interface ProductCardProps {
  index: number;
  kind?: string;
  product: any;
  onManualPickIconClick?: (product: any) => void;
  alreadyFinishedPicking?: boolean;
}
const getCheckboxes = (checks: number, total: number) => (
  <CheckBoxContainer>
    {[...Array(checks)].map(() => (
      <ProductCheckBox selected={true} />
    ))}
    {[...Array(total - checks)].map(() => (
      <ProductCheckBox selected={false} />
    ))}
  </CheckBoxContainer>
);

export const ProductCard: React.FC<ProductCardProps> = React.memo(({ ...props }) => {
  const done = props.product.checks === props.product.totalQuantity;
  const handleManualPickIconClick = () => {
    if (!done) {
      props.onManualPickIconClick(props.product);
    }
  };
  return (
    <ProductCardContainer {...props}>
      {props.kind === 'product' && (
        <ProductCardQuantity done={done}>{props.product.totalQuantity}x</ProductCardQuantity>
      )}
      <ProductCardName kind={props.kind} done={done}>
        {props.product.name}
      </ProductCardName>
      {!props.alreadyFinishedPicking && (
        <ProductCardQuantityProgress done={done}>
          {getCheckboxes(props.product.checks, props.product.totalQuantity)}
        </ProductCardQuantityProgress>
      )}
      {props.alreadyFinishedPicking && props.kind !== 'product' && (
        <ProductCardQuantityProgress done={done}>{props.product.totalQuantity}</ProductCardQuantityProgress>
      )}
      {props.onManualPickIconClick && (
        <ProductCardManualPickIconContainer>
          <ProductCardManualPickIcon done={done} onClick={handleManualPickIconClick}>
            <ListIcon />
          </ProductCardManualPickIcon>
        </ProductCardManualPickIconContainer>
      )}
    </ProductCardContainer>
  );
});
