import { Typography } from 'src/components/atoms/typography';
import styled from 'styled-components';

export const BagsInfoContainer = styled.div`
  && {
  }
`;

export const BagsInfoModelText = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[40]};
    font-size: ${props => props.theme.typography.fontSize.small};
    margin-bottom: 4px;
  }
`;

export const BagsInfoBagTypeText = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[80]};
    font-weight: bold;
    font-size: ${props => props.theme.typography.fontSize.medium};
    margin-bottom: 11px;
  }
`;

export const BagsInfoTagsText = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.medium};
    margin-bottom: 32px;
  }
`;

export const BagsInfoIconsContainer = styled.div`
  && {
    display: flex;
  }
`;

export const PackageSizeContainer = styled.div`
  && {
    margin-top: ${props => (props.hasTitle ? '' : '47px')};
    border-left: ${props =>
      props.hasTitle && props.index !== 0 ? `1px solid ${props.theme.palette.grayScale[20]}` : '0'};
    padding-left: ${props => (props.hasTitle && props.index !== 0 ? '40px' : '0')};
  }
`;

export const BagIconAndQuantityContainer = styled.div`
  && {
    display: flex;
  }
`;

export const BagsInfoIconsQuantityText = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.h1};
    margin-right: 20px;
    margin-bottom: 12px;
  }
`;

export const BagInfoBagIcon = styled.div`
  && {
    margin-right: 40px;
    align-self: flex-start;
  }
`;
