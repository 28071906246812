import React from 'react';
import { StyledModal, ModalContainer, ModalTitle, Text, CloseButton } from './styles';

export interface UnsafeExpirationDateModalProps {
  open: boolean;
  onClose: () => void;
}

export const UnsafeExpirationDateModal: React.FC<UnsafeExpirationDateModalProps> = (props) => {
  const handleClose = () => {
    props.onClose();
  };
  return (
    <StyledModal {...props} open={props.open}>
      <ModalContainer>
        <ModalTitle>Produto Vencido ou Pŕoximo de Vencer!</ModalTitle>
        <Text>NÃO EXPEDIR, produto fora do prazo de expedição</Text>
        <CloseButton variant="outlined" size="large" color="primary" onClick={handleClose}>
          OK
        </CloseButton>
      </ModalContainer>
    </StyledModal>
  );
};
