import React, { ReactNode } from 'react';
import { Body, Container, ExpressDeliveryHeader, Header } from './styles';

interface HeaderfulContainerProps {
  children: ReactNode;
  title: string;
  isExpressDelivery?: boolean;
}

const HeaderfulContainer = ({ children, title, isExpressDelivery, ...props }: HeaderfulContainerProps) => {
  return (
    <Container {...props}>
      {isExpressDelivery ? (
        <ExpressDeliveryHeader fontSize="large">{title}</ExpressDeliveryHeader>
      ) : (
        <Header fontSize="large">{title}</Header>
      )}
      <Body>{children}</Body>
    </Container>
  );
};

export default HeaderfulContainer;
