const SPACING_UNIT = 4;

export const v2 = {
  typography: {
    fontSize: {
      h1: '30px',
      h2: '22px',
      large: '18px',
      medium: '16px',
      small: '14px',
      xsmall: '12px',
      caption: '10px',
    },
    fontFamily: 'Lato',
  },
  spacing: {
    unit: SPACING_UNIT,
    xxs: `${SPACING_UNIT}px`,
    xs: `${SPACING_UNIT * 2}px`,
    s: `${SPACING_UNIT * 3}px`,
    m: `${SPACING_UNIT * 4}px`,
    g: `${SPACING_UNIT * 6}px`,
    xg: `${SPACING_UNIT * 8}px`,
    xxg: `${SPACING_UNIT * 12}px`,
  },
  icon: {
    small: '12px',
  },
  palette: {
    primary: {
      darkest: '#002938',
      darker: '#004D69',
      dark: '#094D59',
      main: '#2F7F9C',
      light: '#008EA6',
      lighter: '#2BC0D9',
      lightest: '#DFF3F8',
      extralightest: '#ECF6F8',
    },
    secondary: {
      dark: '#B34748',
      main: '#E57374',
      light: '#F7B2B8',
      ligther: '#FFE6E6',
    },
    error: {
      dark: '#993E2F',
      main: '#D15541',
      light: '#EB8778',
      lighter: '#FFDBD5',
    },
    warning: {
      dark: '#E68A09',
      regular: '#FFAF3E',
      light: '#FFCC85',
      lighter: '#FFF0DA',
    },
    success: {
      dark: '#016650',
      regular: '#10A17F',
      light: '#52CDB3',
      lighter: '#BEEDE2',
    },
    grayScale: {
      '100': '#262626',
      '80': '#4D4D4D',
      '60': '#737373',
      '40': '#999999',
      '20': '#D5D5D5',
      '10': '#F2F1F1',
    },
    expressDelivery: {
      header: '#D9224A',
    },
    background: '#F7F7F9',
    disable: '#EEEEEE',
    transparent: '#00000000',
    categoryColors: {
      Vermelho: '#FFE6E6',
      Azul: '#E2EFF1',
      Verde: '#BEEDE2',
      Amarelo: '#FFF4DB',
      LARANJA: '#ff740038',
      Petroleo: '#006e7866',
      Potinho: '#FFF4DB',
    },
  },
};
