import { Paper, Typography } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper';
import { ComponentType } from 'react';
import styled from 'styled-components';

export const ExpressOrderIndicatorContainer: ComponentType<PaperProps> = styled(Paper)`
  && {
    height: 133px;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 24px;
    border-radius: 2px;
    background-color: ${props => props.theme.palette.expressDelivery.header};
  }
`;

export const InfoText = styled(Typography)`
  && {
    color: ${props => props.theme.palette.background};
    font-size: 24px;
    font-weight: bold;
  }
`;
