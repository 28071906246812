import React from 'react';
import { GhostButton } from './ghost.styled';
import { OutlinedButton } from './outlined.styled';
import { PrimaryButton } from './primary.styled';

const VARIANT = {
  contained: 'contained',
  outlined: 'outlined',
  text: 'text',
  icon: 'icon',
  dropdown: 'dropdown',
};

export const Button = ({ variant, ...props }: any) => {
  switch (variant) {
    case VARIANT.contained:
      return <PrimaryButton variant={variant} {...props} />;
    case VARIANT.outlined:
      return <OutlinedButton variant={variant} {...props} />;
    case VARIANT.text:
      return <GhostButton variant={variant} {...props} />;
    case VARIANT.icon:
      return <PrimaryButton variant={variant} {...props} />;
    case VARIANT.dropdown:
      return <PrimaryButton variant={variant} {...props} />;
    default:
      return <PrimaryButton variant={variant} {...props} />;
  }
};
