import { Typography } from 'src/components/atoms/typography';
import styled from 'styled-components';
import {
  Stepper as MuiStepper,
  Step as MuiStep,
  StepLabel as MuiStepLabel,
  StepContent as MuiStepContent,
  LinearProgress as MuiProgressBar,
} from '@material-ui/core';
import { StepContentProps } from '@material-ui/core/StepContent';
import { Button } from 'src/components/atoms/button';
import React, { ComponentType } from 'react';
import HeaderfulContainer from 'src/components/molecules/headerfulContainer';

export interface StyledStepContentProps extends StepContentProps {
  done: boolean;
}

export const StepperHeaderfulContainer = styled(HeaderfulContainer)`
  && {
    position: relative;
    margin-right: 24px;
    flex-basis: 75%;
    max-width: 75%;
  }
`;
export const Stepper = styled(MuiStepper)`
  && {
    span[class*="MuiStepConnector-line"] {
      
    }
    span[class*="MuiStepConnector-lineVertical"] {
      border: 0;
    }
    div[class*="MuiStepConnector-vertical"] {
      padding 0;
      border-left: 3px solid ${props => props.theme.palette.grayScale[20]};
    }
    div[class*="MuiStepConnector-completed"] {
      border-left: 3px solid ${props => props.theme.palette.primary.light};
    }
    div[class*="MuiStepConnector-active"] {
      border-left: 3px solid ${props => props.theme.palette.primary.light};
    }
    div[class*="MuiStepContent-last"] {
      border: 0;
    }
    div[class*="MuiCollapse-container"] {
      padding-top: 25px;
    }
    span[class*="MuiStepLabel-iconContainer"] {
      margin-left: 1px;
    }
  }
`;

export const Step = styled(MuiStep)`
  && {
  }
`;
export const StepLabel = styled(MuiStepLabel)`
  && {
    svg[class*='MuiStepIcon'] {
      color: ${props => props.theme.palette.primary.light};
    }
    span[class*='MuiStepLabel-labelContainer'] {
      padding-left: 19px;
    }
    span[class*='MuiStepLabel-active'] {
      margin-left: -5px;
    }
    svg[class*='MuiStepIcon'] {
      color: #fff;
      border: 2.5px solid ${props => props.theme.palette.grayScale[20]};
      border-radius: 33px;
      margin-left: -2px;
      text[class*='MuiStepIcon-text'] {
        fill: ${props => props.theme.palette.grayScale[20]};
      }
    }
    svg[class*='MuiStepIcon-active'] {
      height: 33.5px;
      width: 33.5px;
      color: ${props => props.theme.palette.primary.extralightest};
      border: 2.5px solid ${props => props.theme.palette.primary.light};
      border-radius: 33px;
      margin-left: -7px;
      text[class*='MuiStepIcon-text'] {
        fill: ${props => props.theme.palette.primary.light};
      }
    }
    svg[class*='MuiStepIcon-completed'] {
      color: ${props => props.theme.palette.primary.light};
      border: 0;
      margin-left: 0;
      text[class*='MuiStepIcon-text'] {
        fill: #fff;
      }
    }
  }
`;

export const StepLabelContentContainer = styled.div`
  && {
    display: flex;
  }
`;

export const StepLabelContentTitleActive = styled(Typography)`
  && {
    color: ${props => props.theme.palette.primary.darker};
    font-size: ${props => props.theme.typography.fontSize.medium};
    font-weight: bold;
  }
`;

export const StepLabelContentTitleDone = styled(Typography)`
  && {
    color: ${props => props.theme.palette.primary.light};
    font-weight: bold;
  }
`;

export const StepLabelContentTitleWaiting = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[40]};
    font-weight: bold;
  }
`;

export const StepContent = styled(MuiStepContent)<StyledStepContentProps>`
  && {
    padding-left: 42px;
    margin-top: 0;
    border-left: 3px solid
      ${props => (props.done ? props.theme.palette.primary.light : props.theme.palette.grayScale[20])};
  }
`;

export const ProgressBarContainer = styled.div``;

export const StyledProgressBar = styled(MuiProgressBar)`
  && {
    height: 5px;
    position: absolute;
    left: 363px;
    top: 196px;
    width: calc(100% - 420px);
    div[class*='MuiLinearProgress-barColorPrimary'] {
      background-color: ${props => props.theme.palette.primary.darker};
    }
  }
`;

export const TotalQuantityProgress = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.xsmall};
    height: 5px;
    position: absolute;
    left: 363px;
    top: 179px;
  }
`;

export const VerticalDivider = styled.div`
  && {
    height: 23px;
    font-size: ${props => props.theme.typography.fontSize.xsmall};
    border-left: 2px solid ${props => props.theme.palette.primary.light};
    position: absolute;
    left: 338px;
    top: 180px;
  }
`;

export const ButtonsContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: flex-end;
    right: 24px;
    bottom: 24px;
`;

export const PreviousStep = styled(Button)`
  && {
  }
`;

export const CancelPicking = styled(Button)`
  && {
    margin-right: 16px;
  }
`;

export const NextStep = styled(Button)`
  && {
    opacity: ${props => props.disabled ? '0.5' : '1'};
  }
`;

export const CircularProgressContainer = styled.div`
  && {
    width: 147px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
