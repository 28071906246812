import Select, { components } from 'react-select';
import styled from 'styled-components';

export const DropdownIndicator = styled(components.DropdownIndicator)`
  && {
    color: ${props => props.theme.palette.grayScale['80']};
  }
`;

export const StyledSelect = styled(Select)`
  && {
    height: 40px;
    div[class*="-control"] {
      height: 100%;
    }
    div[class*="-menu"] {
      z-index: 10;
    }
    font-family: Lato; 
  }
`;

export const SelectContainer = styled.div`
  && {
    position: relative;
  }
`;