import React from "react";
import styled from 'styled-components';
import { Button } from "src/components/atoms/button";

export const DisplayInProgressPickingsButton = styled(Button)`
  && {
    position: absolute;
    top: 68px;
    right: 24px;
    opacity: ${props => props.disabled ? '0.5' : '1'}
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
