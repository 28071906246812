import React from 'react';
import {
  KitDayCardContainer,
  KitDayCardDayNumberText,
  KitDayCardDayText,
  KitDayCardInfoContainer,
  KitDayCardKitText,
  KitDayCardProductsContainer,
  KitDayCardQuantityText,
  KitDayCardTotalText,
} from './styles';
import { ProductCard } from 'src/common/picking/molecules/productCard';

export interface KitDayCardProps {
  kitDay: any;
  onManualPickIconClick?: (data: any) => void;
  modal: boolean;
  alreadyFinishedPicking: boolean;
}

export const KitDayCard: React.FC<KitDayCardProps> = ({ ...props }) => {
  return (
    <KitDayCardContainer {...props}>
      <KitDayCardInfoContainer {...props}>
        <KitDayCardKitText>Kit</KitDayCardKitText>
        <KitDayCardDayText>Dia</KitDayCardDayText>
        <KitDayCardDayNumberText>{`0${props.kitDay.day}`.slice(-2)}</KitDayCardDayNumberText>
        <KitDayCardTotalText>Total</KitDayCardTotalText>
        <KitDayCardQuantityText>
          {!props.alreadyFinishedPicking ? `${props.kitDay.products.reduce((acc, cur) => acc + cur.checks, 0)}/` : ''}
          {props.kitDay.products.reduce((acc, cur) => acc + cur.totalQuantity, 0)}
        </KitDayCardQuantityText>
      </KitDayCardInfoContainer>

      <KitDayCardProductsContainer>
        {props.kitDay.products &&
          props.kitDay.products
            .filter((product: any) => product.status !== 'waiting' && product.status !== 'done')
            .map((product, index) => (
              <ProductCard
                key={product.key}
                index={index}
                kind="kit"
                product={product}
                onManualPickIconClick={props.onManualPickIconClick}
                alreadyFinishedPicking={props.alreadyFinishedPicking}
              />
            ))}
      </KitDayCardProductsContainer>
    </KitDayCardContainer>
  );
};
