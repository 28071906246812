import React, { useState } from 'react';
import { OrdersPerPeriod } from './organisms/OrdersPerPeriod';
import { useLazyQuery, useQuery } from 'react-apollo';
import { PICKING_STATUS_QUERY } from './service';
import { PickingStatus, PickingStatusVariables } from 'src/apollo/types/PickingStatus';
import HeaderfulContainer from 'src/components/molecules/headerfulContainer';
import { InProgressPickings } from './molecules/InProgressPickings';
import { HorizontalDivider } from 'src/components/atoms/horizontalDivider';
import { DisplayInProgressPickingsButton } from 'src/common/status/styles';
import { Afternoon } from 'src/common/icons/afternoon';
import { Morning } from 'src/common/icons/morning';
import { Night } from 'src/common/icons/night';
import { Size } from 'src/common/status/molecules/PeriodOrder';
import { Button } from 'src/components/atoms/button';
import { ButtonContainer } from './styles';
import { LogisticKinds } from '../types';

interface PickingStatusProps {
  productType: string;
  logisticKind: LogisticKinds;
  distributionCenter: string;
  size: Size;
  onError: (error: Error) => void;
}

export const PickingStatusContainer: React.FC<PickingStatusProps> = ({
  productType,
  logisticKind,
  distributionCenter,
  size,
  onError,
}) => {
  const [displayInProgressPickings, setDisplayInProgressPickings] = useState(false);
  const [renderPickingStatus, setRenderPickingStatus] = useState(false);

  const [fetchPickingStatus, { data, loading }] = useLazyQuery<PickingStatus, PickingStatusVariables>(
    PICKING_STATUS_QUERY,
    {
      onError,
      fetchPolicy: 'cache-and-network',
      variables: {
        productType,
        logisticKind,
        distributionCenter,
      },
    }
  );

  const handleRenderStatus = () => {
    if (!(distributionCenter && productType && logisticKind)) return;
    fetchPickingStatus()
    setRenderPickingStatus(true)
  }

  const handleDisplayInProgressPickingsButtonClick = () => {
    setDisplayInProgressPickings(!displayInProgressPickings);
  };
  return !renderPickingStatus ?
  (
    <HeaderfulContainer title="Status do picking">
      <ButtonContainer>
        <Button size="large" variant="outlined" color="primary" onClick={handleRenderStatus}>
            Ver Status
        </Button>
      </ButtonContainer>
    </HeaderfulContainer>
  )
  :
  (
    <HeaderfulContainer title="Status do picking">
      <DisplayInProgressPickingsButton
        onClick={handleDisplayInProgressPickingsButtonClick}
        size="large"
        variant="outlined"
        color="secondary"
        disabled={!data || loading}
      >
        {!displayInProgressPickings ? 'Ver pickings em andamento' : 'Ver pedidos de amanhã'}
      </DisplayInProgressPickingsButton>
      <OrdersPerPeriod
        size={size}
        loading={!data || loading}
        days={[
          {
            date: new Date().toLocaleDateString('pt-br'),
            periods: [
              {
                icon: <Morning />,
                name: 'Manhã',
                orders: !data || !data.pickingStatus || loading ? '-' : data.pickingStatus.salesPickedToday.morning,
              },
              {
                icon: <Afternoon />,
                name: 'Tarde',
                orders: !data || !data.pickingStatus || loading ? '-' : data.pickingStatus.salesPickedToday.afternoon,
              },
              {
                icon: <Night />,
                name: 'Noite',
                orders: !data || !data.pickingStatus || loading ? '-' : data.pickingStatus.salesPickedToday.night,
              },
            ],
            ordersTotal: !data || !data.pickingStatus || loading ? '-' : data.pickingStatus.salesPickedToday.total,
          },
        ]}
      />
      <HorizontalDivider />
      {displayInProgressPickings ? (
        <InProgressPickings
          currentPickings={
            !data || !data.pickingStatus || loading ? [] : data.pickingStatus.pickingInProcess.split(', ')
          }
        />
      ) : (
        <OrdersPerPeriod
          size={size}
          loading={!data || loading}
          days={[
            {
              date: new Date(Date.now() + 86400000).toLocaleDateString('pt-br'),
              periods: [
                {
                  icon: <Morning />,
                  name: 'Manhã',
                  orders:
                    !data || !data.pickingStatus || loading
                      ? '-'
                      : data.pickingStatus.salesPickedTomorrowByPeriod.morning,
                },
                {
                  icon: <Afternoon />,
                  name: 'Tarde',
                  orders:
                    !data || !data.pickingStatus || loading
                      ? '-'
                      : data.pickingStatus.salesPickedTomorrowByPeriod.afternoon,
                },
                {
                  icon: <Night />,
                  name: 'Noite',
                  orders:
                    !data || !data.pickingStatus || loading
                      ? '-'
                      : data.pickingStatus.salesPickedTomorrowByPeriod.night,
                },
              ],
              ordersTotal:
                !data || !data.pickingStatus || loading ? '-' : data.pickingStatus.salesPickedTomorrowByPeriod.total,
            },
          ]}
        />
      )}
    </HeaderfulContainer>
  );
};
