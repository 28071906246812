import gql from 'graphql-tag';

export const PICKING_STATUS_QUERY = gql`
  query PickingStatus($productType: String!, $distributionCenter: String!, $logisticKind: String!) {
    pickingStatus(
      input: {
        today: "default"
        productType: $productType
        logisticKind: $logisticKind
        distributionCenter: $distributionCenter
      }
    ) {
      salesPickedToday {
        morning
        afternoon
        night
        total
      }
      salesPickedTomorrow
      salesPickedTomorrowByPeriod {
        morning
        afternoon
        night
        total
      }
      salesPickedWeek
      pickingInProcess
    }
  }
`;
