import { TextField } from '@material-ui/core/';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { Redirect } from 'react-router';
import { LoginMutation, LoginMutationVariables } from 'src/apollo/types/LoginMutation';
import { AUTH_TOKEN, EMAIL } from 'src/common/constants';
import logo from '../../assets/images/logo-livup.png';
import { SIGNIN_USER_MUTATION } from './graphql';
import {
  LoginButton,
  LoginButtonContainer,
  LoginCircularProgress,
  LoginFormContainer,
  LoginPageContainer,
  LoginPaper,
  LoginTitle,
  Logo,
  LogoContainer,
} from './styles';

export interface LoginProps {
  signIn?: (data: { [AUTH_TOKEN]: string; [EMAIL]: string }) => void;
  token: string;
}

export const LoginPage: React.FC<LoginProps> = ({ ...props }) => {
  if (props.token) {
    return <Redirect to="/" />;
  }

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [signInMutation, { loading }] = useMutation<LoginMutation, LoginMutationVariables>(SIGNIN_USER_MUTATION, {
    variables: {
      input: {
        email,
        password,
      },
    },
  });

  const handleEmailInputChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setEmail(value);
  };

  const handlePasswordInputChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setPassword(value);
  };

  const handleLogin = async () => {
    const res = await signInMutation({
      variables: {
        input: { email, password },
      },
    });
    props.signIn({
      [AUTH_TOKEN]: res.data.emailSignIn.accessToken,
      [EMAIL]: res.data.emailSignIn.user.email,
    });
  };

  const handleKeyPress = (event: any) => {
    const { charCode } = event;
    if (charCode === 13) {
      handleLogin();
    }
  };

  return (
    <LoginPageContainer>
      <LoginPaper>
        <LoginFormContainer>
          <LoginTitle>Login</LoginTitle>
          <TextField
            label="Email"
            fullWidth
            onChange={handleEmailInputChange}
            defaultValue={email}
            margin="normal"
            className="mt-1 my-sm-3"
          />
          <TextField
            type="password"
            label="Senha"
            fullWidth
            onChange={handlePasswordInputChange}
            defaultValue={password}
            margin="normal"
            className="mt-1 my-sm-3"
            onKeyPress={handleKeyPress}
          />
          <LoginButtonContainer>
            {loading ? (
              <LoginCircularProgress size={30} />
            ) : (
              <LoginButton variant="contained" color="primary" size="large" onClick={handleLogin}>
                Entrar
              </LoginButton>
            )}
          </LoginButtonContainer>
        </LoginFormContainer>
        <LogoContainer>
          <Logo src={logo} alt="LivUp logo" title="LivUp" />
        </LogoContainer>
      </LoginPaper>
    </LoginPageContainer>
  );
};
