import styled from 'styled-components';
import { StyledButton } from './styles';

export const GhostButton = styled(StyledButton).attrs({
  classes: { label: 'label' },
})`
  & .label {
    font-weight: bold;
    color: ${props => (props.disabled ? props.theme.palette.grayScale['40'] : props.theme.palette[props.color].light)};
  }
  && {
    background-color: ${props => props.theme.palette.transparent};
    &:hover {
      background-color: ${props => props.theme.palette.transparent};
    }
  }
  
`;
