import { Day } from 'src/common/types';
import { sortKitDays } from '../sorting';

export const setKitDaysStatus = (kitDays: Day[]) =>
  kitDays.sort(sortKitDays).map((kitDay, i) =>
    i === 0
      ? {
          ...kitDay,
          products: kitDay.products.map((product: any, j: number) => ({
            ...product,
            status: j < 7 ? 'checking' : 'waiting',
          })),
          status: 'checking',
        }
      : { ...kitDay, status: 'waiting' }
  );
