// tslint:disable-next-line:no-submodule-imports
import { GridProps as MuiGridProps } from '@material-ui/core/Grid';
import React from 'react';
import { StyledGrid } from './styles';

const GUTTER_SIZES = {
  small: 8,
  medium: 16,
  large: 24,
};

export interface GridProps extends MuiGridProps {
  gutter?: 'small' | 'medium' | 'large';
  spacing?: never;
}

export const Grid = ({ gutter = 'medium', ...props }: GridProps) => (
  <StyledGrid {...props} spacing={GUTTER_SIZES[gutter]} />
);
