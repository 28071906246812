import { Typography } from 'src/components/atoms/typography';
import styled from 'styled-components';
import Input from 'src/components/atoms/input';
import { Button } from 'src/components/atoms/button';
import { Collapse } from 'src/components/molecules/collapse';
import { CircularProgress } from 'src/components/atoms/circularProgress';

export const AddTagsContainer = styled.div`
  && {
    position: relative;
    display: flex;
    margin-bottom: 45px;
  }
`;

export const AddTagsLeftColumnContainer = styled.div`
  && {
    flex-basis: 55%;
    max-width: 55%;
  }
`;

export const AddTagsDivider = styled.div`
  && {
    border-left: 2px solid ${props => props.theme.palette.grayScale[20]};
  }
`;

export const AddTagsRightColumnContainer = styled.div`
  && {
    margin-left: 33px;
  }
`;

export const AddTagsCodeText = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.medium};
    font-weight: bold;
    margin-bottom: 12px;
  }
`;

export const AddTagsScannedTags = styled(Typography)`
  && {
    color: ${props => props.theme.palette.primary.light};
    font-size: ${props => props.theme.typography.fontSize.medium};
    margin-bottom: 6px;
  }
`;

export const AddTagsScannedTotalTags = styled(Typography)`
  && {
    color: ${props => props.theme.palette.primary.light};
    font-size: ${props => props.theme.typography.fontSize.medium};
    margin-bottom: 60px;
  }
`;

export const AddTagsObservationsHeader = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[60]};
    font-size: ${props => props.theme.typography.fontSize.small};
    margin-bottom: 4px;
  }
`;

export const AddTagsObservationsText = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.medium};
    font-weight: bold;
    margin-bottom: 32px;
  }
`;

export const AddTagsPrintTagsHeader = styled(Typography)`
  && {
    color: ${props => props.theme.palette.primary.dark};
    font-size: ${props => props.theme.typography.fontSize.medium};
    font-weight: bold;
    margin-bottom: 6px;
  }
`;

export const AddTagsPrintTagsText = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.small};
    margin-bottom: 12px;
  }
`;

export const AddTagsPrintTagsPrintMoreContainer = styled.div`
  display: flex;
  margin-bottom: 80px;
`;

export const AddTagsPrintTagsPrintMore = styled(Button)`
  && {
    height: 38px;
  }
`;

export const AddTagsPrintNfePrintMore = styled(Button)`
  && {
    height: 38px;
    margin-bottom: 24px;
  }
`;
export const AddTagsBagsQuantityInputCollapse = styled(Collapse)`
  && {
    box-shadow: 0 0;
    margin-bottom: 24px;
    div[class*='MuiExpansionPanelSummary-root'] {
      padding: 0;
      min-height: 0;
      height: 20px;
      transition: height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    div[class*='MuiExpansionPanelSummary-expanded'] {
      height: 30px;
    }
    div[class*='MuiExpansionPanelSummary-content'] {
      margin: 0;
    }

    div[class*='MuiCollapse-container'] {
      padding-top: 0;
    }
    div[class*='MuiExpansionPanelDetails-root'] {
      display: block;
      padding: 0 0 0 28px;
    }
    :before {
      background-color: transparent;
    }
  }
`;

export const AddTagsBagsQuantityInputCollapseSummaryContainer = styled.div`
  && {
    display: flex;
    align-items: center;
  }
`;

export const AddTagsBagsQuantityInputCollapseSummaryIcon = styled.div`
  && {
    height: 20px;
    width: 20px
    margin-right: 8px;
  }
`;

export const AddTagsBagsQuantityInputCollapseSummaryTitle = styled(Typography)`
  && {
    color: ${props => props.theme.palette.primary.darker};
    font-size: ${props => props.theme.typography.fontSize.small};
    font-weight: bold;
  }
`;

export const AddTagsBagsQuantityInputCollapseDetailsText = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.xsmall};
    margin-bottom: 20px;
  }
`;

export const AddTagsBagsQuantityInputCollapseDetailsInput = styled(Input)`
  && {
  }
`;

export const AddTagsPickingProblemsContainer = styled.div`
  && {
    display: flex;
    :hover {
      cursor: pointer;
    }
  }
`;

export const AddTagsBagsPickingProblemsIcon = styled.div`
&& {
    height: 20px;
    width: 20px
    margin-right: 8px;
  }`;

export const AddTagsBagsPickingProblemsText = styled(Typography)`
  && {
    color: ${props => props.theme.palette.secondary.main};
    font-size: ${props => props.theme.typography.fontSize.small};
    margin-bottom: 20px;
    font-weight: bold;
  }
`;

export const AllProductsButton = styled(Button)`
  && {
    position: absolute;
    top: -231px;
    right: 0;
  }
`;
export const LoadingContainer = styled.div`
  && {
    width: 131.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }
`;
export const StyledCircularProgress = styled(CircularProgress)`
  && {
  }
`;
