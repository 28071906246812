import React from 'react';
import { ExtraItemContainer, ExtraItemIconContainer, ExtraItemIcon, ExtraItemName, ExtraItemImage } from './styles';
import { PromotionalItems } from '../../organisms/extraItems';

export interface ExtraItemCardProps {
  promotionalItem: PromotionalItems;
  index: number;
}

export const ExtraItemCard: React.FC<ExtraItemCardProps> = ({ ...props }) => {
  return (
    <ExtraItemContainer {...props}>
      <ExtraItemName>{props.promotionalItem.title}</ExtraItemName>
      <ExtraItemIconContainer>
        {typeof props.promotionalItem.image === 'string' ? (
          <ExtraItemImage src={props.promotionalItem.image} alt="promotional_item" />
        ) : (
          <ExtraItemIcon>{props.promotionalItem.image}</ExtraItemIcon>
        )}
      </ExtraItemIconContainer>
    </ExtraItemContainer>
  );
};
