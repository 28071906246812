import styled from 'styled-components';
import { Typography } from 'src/components/atoms/typography';
import { Paper } from 'src/components/atoms/paper';
import { Button } from 'src/components/atoms/button';
import { CircularProgress } from "src/components/atoms/circularProgress";

export const LoginPageContainer = styled.div`
  && {
    width: 100%;
    height: 100%;
  }
`;

export const LoginPaper = styled(Paper)`
  && {
    overflow: hidden;
    width: 43%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    display: flex;
  }
`;

export const LoginFormContainer = styled.div`
  && {
    flex-basis: 55%;
    max-width: 55%;
    padding: 35px;
  }
`;

export const LoginTitle = styled(Typography).attrs({ fontSize: 'h2' })`
  && {
    color: ${props => props.theme.palette.grayScale[80]};
    margin-bottom: 30px;
  }
`;

export const LogoContainer = styled.div`
  && {
    flex-basis: 45%;
    max-width: 45%;
    background-color: ${props => props.theme.palette.primary.darkest};
    position: relative;
  }
`;

export const Logo = styled.img`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 34%;
    height: 29%;
  }
`;

export const LoginButton = styled(Button)`
  && {
    margin-top: 10px;
    background-color: ${props => props.theme.palette.primary.darkest};
    :hover {
      background-color: ${props => props.theme.palette.primary.darkest};
    }
  }
`;
export const LoginButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const LoginCircularProgress = styled(CircularProgress)`
  margin-left: 16px;
  margin-top: 10px;
`;
