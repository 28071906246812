import React from 'react';

export const Morning = () => (
  <svg viewBox="0 0 64 65" version="1.1">
    <title>Group 7 Copy</title>
    <desc>Created with Sketch.</desc>
    <g id="04_Protótipos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Inicial_preenchido_ver_dia"
        transform="translate(-883.000000, -483.000000)"
        stroke="#004D69"
        stroke-width="2.4"
      >
        <g id="Group-7-Copy" transform="translate(885.000000, 485.000000)">
          <path
            d="M30.8955224,-8.52651283e-14 L30.8955224,7.27904978"
            id="Line-2-Copy-4"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M30.4477612,50.8778733 C32.370317,50.8660144 33.0485157,50.7493663 34.291782,50.5042594 C43.3324144,48.7219214 50.1492537,40.7740999 50.1492537,31.2391966 C50.1492537,20.393055 41.3285951,11.60052 30.4477612,11.60052 C19.5669273,11.60052 10.7462687,20.393055 10.7462687,31.2391966 C10.7462687,40.8586106 17.6844828,48.8626845 26.8446507,50.5501954"
            id="Oval-Copy"
            stroke-linecap="round"
          ></path>
          <path
            d="M45.6716418,3.4566448 L42.0895522,9.88366428"
            id="Line-2-Copy-4"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M56.4179104,13.249495 L50.1492537,17.4245864"
            id="Line-2-Copy-4"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M51.9402985,54.4201476 L47.4626866,49.0107594"
            id="Line-2-Copy-4"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M38.5074627,61.5017709 L36.7164179,54.4389401"
            id="Line-2-Copy-4"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M23.2835821,61.635376 L25.0746269,54.5725451"
            id="Line-2-Copy-4"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M10.7462687,54.789685 L15.2238806,49.3802968"
            id="Line-2-Copy-4"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M1.79104478,42.5865908 L8.95522388,39.9780127"
            id="Line-2-Copy-4"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M0,27.7435414 L7.1641791,28.6306344"
            id="Line-2-Copy-4"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M5.37313433,13.701884 L10.7462687,17.8769755"
            id="Line-2-Copy-4"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M16.119403,3.71591754 L19.7014925,10.142937"
            id="Line-2-Copy-4"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M48.6567164,25.7483335 C54.921439,25.7483335 60,30.9605409 60,37.3901246 C60,37.999298 59.9544113,38.5975435 59.8665445,39.1814633 L26.1080512,39.1805762 C26.8100915,35.3216903 30.1089325,32.4007856 34.0724947,32.4007856 C35.4918946,32.4007856 36.8260478,32.775375 37.9862218,33.4334866 C39.5630385,28.9517985 43.7453652,25.7483335 48.6567164,25.7483335 Z"
            id="Combined-Shape"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
