import React from 'react';

export const User = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" fill="#A2A2A2">
    <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
    <g>
      <path d="M500,990C229.4,990,10,770.6,10,500S229.4,10,500,10s490,219.4,490,490S770.6,990,500,990z M769,848.9c-45.2-104.1-148.2-177.4-269-177.4s-223.9,73.1-269.1,177.3C305.3,906.4,398.6,941,500,941C601.4,941,694.5,906.5,769,848.9L769,848.9z M647,451c0-81.2-65.8-147-147-147c-81.2,0-147,65.8-147,147c0,81.2,65.8,147,147,147C581.2,598,647,532.2,647,451z M500,59C256.5,59,59,256.5,59,500c0,123.8,51.1,235.5,133.3,315.6c44.8-91.8,129-160.8,231-184.2C353.2,601.6,304,532,304,451c0-108.2,87.8-196,196-196c108.2,0,196,87.8,196,196c0,81-49.2,150.6-119.3,180.4c102,23.3,186.1,92.4,231,184.2C889.8,735.6,941,623.8,941,500C941,256.5,743.5,59,500,59L500,59z" />
    </g>
  </svg>
);
