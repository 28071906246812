import { Typography } from 'src/components/atoms/typography';
import styled from 'styled-components';
import HeaderfulContainer from 'src/components/molecules/headerfulContainer';
import { Button } from 'src/components/atoms/button';
export const Container = styled(HeaderfulContainer)`
  && {
    position: relative;
    margin-right: 24px;
    padding-bottom: 48px;
  }
`;

export const SubContainer = styled.div`
  && {
    display: flex;
    justify-content: center;
    position: relative;
  }
`;
export const Item = styled.div`
  && {
    width: fit-content;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled(Typography)`
  && {
    font-size: ${props => props.theme.typography.fontSize.h2};
    color: ${props => props.theme.palette.primary.darker};
    margin-bottom: 32px;
    margin-top: 48px;
    font-weight: bold;
    text-align: center;
  }
`;

export const Icon = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${props => props.theme.palette.grayScale[20]};
    padding-bottom: 32px;
    margin-bottom: 32px;
  }
`;

export const MessageContainer = styled.div`
  && {
    display: flex;
  }
`;

export const Message = styled(Typography)`
  && {
    font-size: ${props => props.theme.typography.fontSize.h2};
    color: ${props => props.theme.palette.primary.darker};
  }
`;

export const NextPickingTimer = styled(Typography)`
  && {
    font-size: ${props => props.theme.typography.fontSize.large};
    color: ${props => props.theme.palette.primary.darker};
    font-weight: bold;
    margin-left: 9px;
    margin-top: 4.3px;
  }
`;
export const ButtonsContainer = styled.div`
  && {
    display: flex;
  }
`;
export const Retry = styled(Button)`
  && {
    margin-right: 24px;
  }
`;

export const Cancel = styled(Button)`
  && {
  }
`;
export const Back = styled(Button)`
  && {
    position: absolute;
    right: 160px;
    bottom: -48px;
  }
`;

export const NextPicking = styled(Button)`
  && {
    position: absolute;
    right: 0;
    bottom: -48px;
  }
`;
export const CircularProgressContainer = styled.div`
  && {
    width: 147px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
