import { Day } from 'src/common/types';

export function sortByPackageColor(products: any[]) {
  const colorsOrder = ['Vermelho', 'Verde', 'Azul', 'Amarelo', 'Potinho'];

  return products.sort((a, b) => {
    if (colorsOrder.indexOf(a.color) > colorsOrder.indexOf(b.color)) {
      return 1;
    }
    if (colorsOrder.indexOf(a.color) < colorsOrder.indexOf(b.color)) {
      return -1;
    }
    return 0;
  });
}

export function sortKitDays(day1: Day, day2: Day) {
  if (day1.day <= day2.day) {
    return -1;
  }
  return 1;
}
