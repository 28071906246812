import React from 'react';
import { OrdersPerPeriodHeaderfulContainer, Period, PeriodName, Date } from './styles';

import { Morning } from 'src/common/icons/morning';
import { Afternoon } from 'src/common/icons/afternoon';
import { Night } from 'src/common/icons/night';
import { PeriodsContainer } from 'src/common/status/organisms/OrdersPerPeriod/styles';

interface OrdersPerPeriodContainerProps {
  period: string;
  dateString: string;
}

export interface Day {
  date: any;
  periods: any[];
  ordersTotal?: any;
  active: boolean;
}

const periods = [
  {
    icon: <Morning />,
    name: 'Manhã',
    id: 'morning',
  },
  {
    icon: <Afternoon />,
    name: 'Tarde',
    id: 'afternoon',
  },
  {
    icon: <Night />,
    name: 'Noite',
    id: 'night',
  },
];

const formatDate = (date: string) => {
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
};
export const OrdersPerPeriodContainer: React.FC<OrdersPerPeriodContainerProps> = props => {
  return (
    <OrdersPerPeriodHeaderfulContainer title="Data e período">
      <Date>{formatDate(props.dateString)}</Date>
      <PeriodsContainer>
        {periods.map(period => (
          <Period active={props.period === period.id}>
            {period.icon}
            <PeriodName>{period.name}</PeriodName>
          </Period>
        ))}
      </PeriodsContainer>
    </OrdersPerPeriodHeaderfulContainer>
  );
};
