import momentTimezone from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { PickingEventTypes, PickingProblems } from 'src/apollo/types/globalTypes';
import { LogEventMutation, LogEventMutationVariables } from 'src/apollo/types/LogEventMutation';
import {
  StartPickingMutation_startPicking_promotionalItems,
  StartPickingMutation_startPicking_rejectedBatches
} from 'src/apollo/types/StartPickingMutation';
import { EMAIL } from 'src/common/constants';
import { Letter } from 'src/common/icons/firstBuyLetter';
import { GreenGrocerLetter } from 'src/common/icons/greenGrocerLetter/index';
import { Letter2 } from 'src/common/icons/secondBuyLetter';
import { LOG_EVENT_MUTATION } from 'src/common/picking/graphql';
import { filterByKind } from 'src/common/picking/logic/filters';
import { AddTags } from 'src/common/picking/organisms/addTags';
import { BagsInfo } from 'src/common/picking/organisms/bagsInfo';
import { ExtraItems } from 'src/common/picking/organisms/extraItems';
import { FormattedSale, Marketables, ProductTypes } from 'src/common/types';
import { CircularProgress } from 'src/components/atoms/circularProgress';
import { PickingList } from '../pickingList';
import {
  ButtonsContainer,
  CancelPicking,
  CircularProgressContainer,
  NextStep,
  PreviousStep,
  ProgressBarContainer,
  Step,
  StepContent,
  StepLabel,
  StepLabelContentContainer,
  StepLabelContentTitleActive,
  StepLabelContentTitleDone,
  StepLabelContentTitleWaiting,
  Stepper,
  StepperHeaderfulContainer,
  StyledProgressBar,
  TotalQuantityProgress,
  VerticalDivider
} from './styles';
const papinhaIcon = require('src/assets/images/papinha.jpeg');

const GREEN_GROCER_ENABLED_CDS = ['5f762a55f9265901d66f6e51', '587e81a99565c515f102b3ac'];

export interface StepperProps {
  order: string;
  alreadyFinishedPicking: boolean;
  productType: string;
  sale: any;
  isExpressDelivery: boolean;
  onFinish: (data: any) => void;
  onCancel: () => void;
  onBack: () => void;
  onForceCancel: () => void;
  observations: string;
  insideObservations: string;
  productsBySku: any;
  totalTags: number;
  finishingPicking: boolean;
  hasBabyFood: boolean;
  firstOrder: boolean;
  secondOrder: boolean;
  onError: (error: Error) => void;
  rejectedBatches: StartPickingMutation_startPicking_rejectedBatches[];
  hasNfe: boolean;
  pcId?: number;
  distributionCenter: string;
  promotionalItems: StartPickingMutation_startPicking_promotionalItems[];
  hasBoughtGreenGrocer: boolean;
}

const getStepLabelContentTitleTypography = (step: number, index: number, label: string) => {
  if (step === index) {
    return <StepLabelContentTitleActive>{label}</StepLabelContentTitleActive>;
  }
  if (step < index) {
    return <StepLabelContentTitleWaiting>{label}</StepLabelContentTitleWaiting>;
  }
  return <StepLabelContentTitleDone>{label}</StepLabelContentTitleDone>;
};

const getStepsLabels = (step: number) => [
  step === 0 ? 'Separe as sacolas para esse pedido:' : 'Sacolas separadas',
  'Lista de produtos para picking',
  'Itens extras a serem adicionados',
  'Acrescentar etiquetas',
];

const getProductsTotalQuantity = (sale: FormattedSale) => {
  const products = sale.marketables
    .filter(filterByKind(Marketables.Product))
    .map(productMarketable => productMarketable.items)
    .flat();
  const meals = sale.marketables
    .filter(filterByKind(Marketables.Meal))
    .map(productMarketable => productMarketable.items)
    .flat();
  const kits = sale.marketables
    .filter(filterByKind(Marketables.Kit))
    .map(productMarketable => productMarketable.items)
    .flat();

  const productsTotal = products.reduce((acc: any, cur: any) => acc + cur.totalQuantity, 0);

  const kitsTotal = kits.reduce(
    (total: any, kit: any) =>
      kit.kitDays.reduce(
        (kitDayTotal: any, kitDay: any) =>
          kitDay.products.reduce(
            (productTotal: any, product: any) => productTotal + product.totalQuantity,
            kitDayTotal
          ),
        total
      ),
    0
  );
  const mealsTotal = meals.reduce(
    (total: any, meal: any) =>
      meal.products.reduce((productTotal: any, product: any) => productTotal + product.totalQuantity, total),
    0
  );
  return productsTotal + mealsTotal + kitsTotal;
};

const getEvent = (nextStep: number): PickingEventTypes => {
  switch (nextStep) {
    case 1:
      return PickingEventTypes.PICKING_LIST;
    case 2:
      return PickingEventTypes.EXTRA_ITEMS;
    case 3:
      return PickingEventTypes.ADD_TAGS;
    case 4:
      return PickingEventTypes.FINISH_PACKING;
    default:
      return PickingEventTypes.BAG_PICKING;
  }
};

export const StepperContainer: React.FC<StepperProps> = ({ ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = useState(props.alreadyFinishedPicking ? 3 : 0);
  const [productsChecked, setProductsChecked] = useState(0);
  const [pickingProblems, setPickingProblems] = useState([]);
  const [productsTotalQuantity] = useState(getProductsTotalQuantity(props.sale));
  const [packageCodes, setPackageCodes] = useState([]);
  const [numberOfPackages, setNumberOfPackages] = useState<number | string | undefined>();
  const [productBatches, setProductBatches] = useState([]);
  const [logEventMutation] = useMutation<LogEventMutation, LogEventMutationVariables>(LOG_EVENT_MUTATION);

  const logStep = (nextStep: number) => {
    const event = getEvent(nextStep);
    logEventMutation({
      variables: {
        input: {
          event,
          order: props.order,
          user: localStorage.getItem(EMAIL),
          timestamp: momentTimezone()
            .tz('America/Sao_Paulo')
            .format(),
        },
      },
    });
  };
  let idleTime = 0;

  useEffect(() => {
    if (!props.alreadyFinishedPicking) {
      window.addEventListener('unload', props.onForceCancel, false);
    }
    window.addEventListener('keypress', resetIdleTime);
    window.addEventListener('mousemove', resetIdleTime);
    const idleInterval = setInterval(timerIncrement, 60000);
    return () => {
      if (!props.alreadyFinishedPicking) {
        window.removeEventListener('unload', props.onForceCancel, false);
      }
      window.removeEventListener('keypress', resetIdleTime);
      window.removeEventListener('mousemove', resetIdleTime);
      clearInterval(idleInterval);
    };
  }, []);

  const timerIncrement = () => {
    idleTime = idleTime + 1;
    if (idleTime > 10) {
      if (props.alreadyFinishedPicking) {
        props.onBack();
      } else {
        logEventMutation({
          variables: {
            input: {
              event: PickingEventTypes.CANCELED,
              order: props.order,
              user: localStorage.getItem(EMAIL),
              payload: 'idle',
              timestamp: momentTimezone()
                .tz('America/Sao_Paulo')
                .format(),
            },
          },
        });
        props.onCancel();
      }
    }
  };

  const handleKeyDown = (event: any) => {
    resetIdleTime();
    const { keyCode } = event;
    if (!props.finishingPicking && keyCode === 32 && step !== 1) {
      handleNextStep();
    }
  };

  const resetIdleTime = () => {
    idleTime = 0;
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleManualPickingConfirm = (pickingProblem: any) => {
    setPickingProblems([...pickingProblems, pickingProblem]);
  };
  const handleNextStep = () => {
    if (step === 3 && !((numberOfPackages && numberOfPackages !== '') || packageCodes.length > 0)) {
      enqueueSnackbar('Você deve escanear as etiquetas ou informar a quantidade de sacolas', {
        variant: 'error',
      });
    } else {
      logStep(step + 1);
      if (step === 3) {
        const tagProblems = pickingProblems.filter(
          pickingProblem => pickingProblem.problem === PickingProblems.TAG_PROBLEM
        );
        if (tagProblems.length > 0) {
          logEventMutation({
            variables: {
              input: {
                event: PickingEventTypes.TAG_PROBLEM,
                order: props.order,
                user: localStorage.getItem(EMAIL),
                payload: JSON.stringify(tagProblems),
                timestamp: momentTimezone()
                  .tz('America/Sao_Paulo')
                  .format(),
              },
            },
          });
        }
        logEventMutation({
          variables: {
            input: {
              event: PickingEventTypes.NUMBER_OF_BAGS,
              order: props.order,
              user: localStorage.getItem(EMAIL),
              payload: String(props.totalTags),
              timestamp: momentTimezone()
                .tz('America/Sao_Paulo')
                .format(),
            },
          },
        });
        props.onFinish({
          pickingProblems,
          productBatches,
          ...(numberOfPackages ? { numberOfPackages: Number(numberOfPackages) } : { packageCodes }),
        });
      } else {
        setStep(step + 1);
      }
    }
  };

  const handlePreviousStep = () => {
    setNumberOfPackages(0);
    setProductsChecked(0);
    setStep(step - 1);
  };

  const handleCancelPicking = () => {
    logEventMutation({
      variables: {
        input: {
          event: PickingEventTypes.CANCELED,
          order: props.order,
          user: localStorage.getItem(EMAIL),
          payload: 'cancel button',
          timestamp: momentTimezone()
            .tz('America/Sao_Paulo')
            .format(),
        },
      },
    });
    props.onCancel();
  };

  const getProgress = () => (productsChecked / productsTotalQuantity) * 100;

  const getExtraItems = () => {
    const extraItems = [];
    if (props.firstOrder) {
      extraItems.push({ name: 'carta', title: 'Carta de primeira compra', image: <Letter /> });
    }
    if (props.secondOrder) {
      extraItems.push({ name: 'carta2', title: 'Carta de segunda compra', image: <Letter2 /> });
    }
    if (props.hasBabyFood && props.productType === ProductTypes.frozen) {
      extraItems.push({ name: 'papinha', title: 'Carta de papinha', image: <img src={papinhaIcon} /> });
    }
    if (!props.hasBoughtGreenGrocer && GREEN_GROCER_ENABLED_CDS.includes(props.distributionCenter)) {
      extraItems.push({ name: 'Quitanda', title: 'Carta de Quitanda', image: <GreenGrocerLetter /> });
    }

    return [
      ...extraItems,
      ...props.promotionalItems.map(item => ({ name: item.name, title: item.title, image: item.image })),
    ];
  };
  const handleProductChecked = (barcode?: string) => {
    if (productsChecked + 1 === productsTotalQuantity) {
      handleNextStep();
    }
    if (barcode) {
      setProductBatches([...productBatches, barcode]);
    }
    setProductsChecked(productsChecked + 1);
  };
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return renderBagsInfo();
      case 1:
        return renderPickingList();
      case 2:
        return renderExtraItems();
      default:
        return renderAddTags();
    }
  };

  const renderBagsInfo = () => (
    <BagsInfo productType={props.productType} packageSizes={props.sale.packageSizes} firstOrder={props.firstOrder} />
  );
  const renderPickingList = () => (
    <PickingList
      order={props.order}
      sale={props.sale}
      onProductCheck={handleProductChecked}
      productsBySku={props.productsBySku}
      onManualPickingConfirm={handleManualPickingConfirm}
      rejectedBatches={props.rejectedBatches}
    />
  );
  const renderExtraItems = () => <ExtraItems promotionalItems={getExtraItems()} />;
  const renderAddTags = () => (
    <AddTags
      sale={props.sale}
      alreadyFinishedPicking={props.alreadyFinishedPicking}
      packageCodes={packageCodes}
      onSetPackageCodes={setPackageCodes}
      onSetNumberOfPackages={setNumberOfPackages}
      order={props.order}
      productType={props.productType}
      totalTags={props.totalTags}
      observations={props.observations}
      insideObservations={props.insideObservations}
      pickingProblems={pickingProblems}
      onError={props.onError}
      hasNfe={props.hasNfe}
      pcId={props.pcId}
      distributionCenter={props.distributionCenter}
    />
  );
  return (
    <StepperHeaderfulContainer title="Produtos para separar" isExpressDelivery={props.isExpressDelivery}>
      {!props.alreadyFinishedPicking && (
        <PreviousStep
          size="small"
          variant="text"
          color="primary"
          onClick={handlePreviousStep}
          disabled={step === 2 || step === 0}
        >
          Voltar
        </PreviousStep>
      )}

      <Stepper activeStep={step} orientation="vertical">
        {getStepsLabels(step).map((stepLabel, index) => (
          <Step key={stepLabel}>
            <StepLabel>
              <StepLabelContentContainer>
                {getStepLabelContentTitleTypography(step, index, stepLabel)}
              </StepLabelContentContainer>
            </StepLabel>
            <StepContent done={index < step}>
              {index === 1 && step === 1 && (
                <ProgressBarContainer>
                  <VerticalDivider />
                  <TotalQuantityProgress>
                    Total de produtos:{' '}
                    <b>
                      {productsChecked}/{productsTotalQuantity}
                    </b>
                  </TotalQuantityProgress>
                  <StyledProgressBar variant="determinate" value={getProgress()} />
                </ProgressBarContainer>
              )}
              {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <ButtonsContainer>
        {!props.alreadyFinishedPicking ? (
          <React.Fragment>
            <CancelPicking size="large" variant="outlined" color="primary" onClick={handleCancelPicking}>
              Cancelar picking
            </CancelPicking>
            {!props.finishingPicking ? (
              <NextStep
                size="large"
                variant="contained"
                color="secondary"
                onClick={handleNextStep}
                disabled={step === 1}
              >
                {step === 3 ? 'Finalizar picking' : 'Próxima etapa'}
              </NextStep>
            ) : (
              <CircularProgressContainer>
                <CircularProgress size={30} />
              </CircularProgressContainer>
            )}
          </React.Fragment>
        ) : (
          <CancelPicking size="large" variant="outlined" color="primary" onClick={props.onBack}>
            Voltar ao menu
          </CancelPicking>
        )}
      </ButtonsContainer>
    </StepperHeaderfulContainer>
  );
};
