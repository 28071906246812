import React from 'react';

export const ClothBag = () => (
  <svg width="152px" height="152px" viewBox="0 0 152 152" version="1.1">
    <title>Group</title>
    <desc>Created with Sketch.</desc>
    <g id="04_Protótipos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Sacola_tecido" transform="translate(-192.000000, -339.000000)">
        <g id="Group" transform="translate(192.000000, 339.000000)">
          <path
            d="M12,37 L105.432906,37 L105.432906,37 L125,37 C126.104569,37 127,37.8954305 127,39 L127,136 C127,144.836556 119.836556,152 111,152 L26,152 C17.163444,152 10,144.836556 10,136 L10,39 C10,37.8954305 10.8954305,37 12,37 Z"
            id="Rectangle-Copy-7"
            fill="#FCF0D4"
          ></path>
          <path
            d="M129.811745,31.5746122 L146.725613,31.5746122 L146.725613,31.5746122 L149,31.5746122 C150.104569,31.5746122 151,32.4700427 151,33.5746122 L151,134 C151,142.836556 143.836556,150 135,150 L109,150 L109,150 C121.541163,144.580699 127.811745,140.158809 127.811745,136.734332 C127.811745,112.273639 127.811745,77.8870662 127.811745,33.5746118 C127.811746,32.4700425 128.707176,31.5746122 129.811745,31.5746122 Z"
            id="Rectangle-Copy-8"
            fill="#E6DBC2"
          ></path>
          <path
            d="M139.5,66 L139.5,109.710669"
            id="Path-2-Copy"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M139.5,34 L139.5,58"
            id="Path-2-Copy-2"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M2,31.5 C1.72385763,31.5 1.5,31.7238576 1.5,32 L1.5,136 C1.5,144.008129 7.99187113,150.5 16,150.5 L136,150.5 C144.008129,150.5 150.5,144.008129 150.5,136 L150.5,32 C150.5,31.7238576 150.276142,31.5 150,31.5 L2,31.5 Z"
            id="Rectangle-Copy-3"
            stroke="#004D69"
            stroke-width="3"
          ></path>
          <path
            d="M128,33 L128,130.573839 C128,141.771458 118.930419,150 108,150"
            id="Rectangle"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M39.5,31.5 L96.5,31.5 L96.5,10 C96.5,5.30557963 92.6944204,1.5 88,1.5 L48,1.5 C43.3055796,1.5 39.5,5.30557963 39.5,10 L39.5,31.5 Z"
            id="Rectangle"
            stroke="#004D69"
            stroke-width="3"
          ></path>
          <path
            d="M48.5,31.5 L105.5,31.5 L105.5,10 C105.5,5.30557963 101.69442,1.5 97,1.5 L57,1.5 C52.3055796,1.5 48.5,5.30557963 48.5,10 L48.5,31.5 Z"
            id="Rectangle-Copy-6"
            stroke="#004D69"
            stroke-width="3"
          ></path>
          <g id="logo-rgb-branco" transform="translate(50.000000, 60.000000)" fill="#E57374">
            <path
              d="M10.2702703,18.3713568 C10.4564946,19.0096872 10.6259268,19.1638534 10.7194747,20.0094112 C10.8130807,20.8546648 11.4625126,20.423866 11.7980069,20.0758516 C12.134489,19.7277613 13.0862376,19.0644968 13.4798362,16.9419742 C13.6997029,15.7581326 13.9725607,11.8690078 14.0308393,10.8042877 C14.0889436,9.73941555 13.4417197,10.4907859 12.8294746,10.7465134 C12.1709785,11.0220817 10.8797845,12.325273 10.6110521,12.942014 C10.3417967,13.5585269 10.0839879,17.7328744 10.2702703,18.3713568"
              id="Fill-1"
            ></path>
            <path
              d="M10.0949487,3.93391585 C10.1118627,3.87700431 10.051324,3.82928161 9.98835708,3.8478075 C9.16635374,4.08916284 7.1245333,3.63764977 5.53470165,5.32194985 C4.13820867,6.80135356 3.61529833,4.71933961 2.22742981,7.37661958 C1.29330848,9.16481287 0.80389119,12.2017995 0.436304898,14.5017521 C-0.179967337,18.353804 -0.0938901825,22.5532534 0.386149046,25.8573093 L0.386483977,25.8593842 C0.538291254,27.0375569 1.22866688,26.9129147 1.70795251,27.3791002 C2.18765681,27.8455081 2.94158551,28.196685 3.58414979,27.945548 C4.22704901,27.6939663 5.35903057,28.7197821 6.2623383,27.788893 C7.16564603,26.8577075 8.42933904,25.4332146 10.373611,23.935285 C12.2848085,22.4631434 15.5515537,20.5700936 16.5517401,20.2521893 C16.5807953,20.242704 16.5939414,20.2151375 16.5815489,20.1899423 C16.5247782,20.0698204 16.3477674,19.7602156 16.0288298,19.7508786 C15.6325232,19.7392443 15.5052496,19.7704419 14.9511907,19.5609511 C14.578413,19.4201543 14.7122177,19.1684245 12.774477,19.8068268 C10.5383131,20.5441573 8.57042854,21.388197 6.95263019,22.415643 C6.90071595,22.4476558 6.73961435,22.5538462 6.71466203,22.5698526 C4.48745752,24.0543694 6.28410879,15.3300077 7.4264732,11.5885925 C7.8587011,10.1733625 9.81888221,4.87525352 10.0949487,3.93391585"
              id="Fill-2"
            ></path>
            <path
              d="M13.3173539,7.44000621 C13.7445891,7.73643844 14.3754736,7.95002242 15.1720831,6.9570185 C17.0072006,4.66987781 17.3666392,3.25546417 14.3647587,4.08159643 C12.2205954,4.67146427 12.6138024,6.95220038 13.3173539,7.44000621"
              id="Fill-3"
            ></path>
            <path
              d="M35.7196539,0.874831658 C35.5954478,0.81963639 35.4867382,0.740068211 35.385115,0.651800778 C34.8710807,0.204389135 33.143642,-0.0959601167 32.6460387,0.0281542431 C28.2422107,1.12755999 19.9974149,10.9381439 19.9929761,10.8513764 C19.9383099,9.83108884 19.6912993,8.59152011 18.9154587,7.8066374 C18.5957155,7.48319013 17.7563311,7.4765157 17.5237269,7.46211693 C16.2343192,7.38127386 16.4469103,14.8037625 17.1342102,16.4569958 C17.1785974,16.5640116 17.2883193,16.6284311 17.407931,16.6231816 C18.4915225,16.5744357 19.2975775,16.9397294 20.2792343,16.0368068 C22.352192,14.1305453 25.0320076,10.8375776 26.5832216,9.49256786 C28.3210173,7.98564705 28.2998361,8.91856707 28.9819186,8.14403347 C30.4473953,6.48075107 34.0548244,4.72155188 36.227147,3.33492076 C37.933716,2.24511418 36.3763501,1.16678163 35.7196539,0.874831658"
              id="Fill-4"
            ></path>
            <path
              d="M28.5792272,14.0951238 C24.73982,14.1696308 19.6313315,16.7459635 18.1928354,20.1315056 C16.7483035,23.5325991 16.2640368,30.0434118 16.7961762,32.9497262 C17.2176048,37.0701255 17.9374317,38.6151205 18.8492454,38.4214642 C18.8874443,38.4134951 18.9196075,38.3903616 18.9430891,38.3612706 C19.2269354,38.0059117 19.6401785,37.767381 19.8000022,37.6321388 C20.0010841,37.4616934 20.8869358,36.9324848 20.7558026,36.2182854 C20.7247143,36.0490779 20.7073511,35.878942 20.7052841,35.7072587 C20.6530292,31.9682919 21.9961107,19.961215 24.0174301,17.5726584 C25.1754699,16.2042984 26.9585006,16.7454993 27.3481795,17.3433347 C28.8267762,19.6115814 27.1456093,21.619866 27.1067489,22.3565801 C27.0773142,22.9228487 27.4731115,22.9366205 27.2716162,23.4862546 C26.6101627,25.2877301 23.7422654,27.041159 21.847201,27.6467314 C21.8012299,27.6614317 21.7799807,27.7053003 21.8004858,27.7467704 C21.8653909,27.881471 22.0310024,28.1511043 22.3131123,28.1597697 C22.6897274,28.1712978 22.8106907,28.1403499 23.3372905,28.3504086 C26.0118779,28.3996157 31.2142102,23.8626581 32.3638164,21.5391695 C34.1532137,17.9228336 33.0074934,14.0097076 28.5792272,14.0951238"
              id="Fill-5"
            ></path>
            <path
              d="M15.7237171,24.3909074 C14.4976596,24.6835686 12.4374409,25.8738279 11.9278315,26.3219978 C11.5143143,26.5504868 11.396281,27.0509863 11.3394264,27.8338588 C11.2653552,28.8520298 10.9352782,28.5099255 10.8624883,29.413815 C10.8207682,29.9273414 10.7963448,30.5741716 10.7989873,31.2832496 C10.804913,32.7552897 10.9295928,34.4983783 11.2739235,35.8918869 C10.320768,38.840334 7.29906595,40.2898731 7.66381626,35.6801997 C7.839505,33.4618512 8.19008169,31.5155134 8.8423081,29.8070646 C8.9210237,29.5252838 8.5333714,29.3873171 7.98300281,29.5383107 C6.89027334,29.8385216 5.22859744,30.9282665 4.82613091,31.307083 C4.46922813,31.5558524 4.36801092,32.0402904 4.32236708,32.8112462 C4.26254963,33.8144658 3.97443292,33.4836861 3.9155764,34.3740305 C3.74493251,36.9498047 3.49653394,40.1166745 6.66797985,40.8734932 C9.28249086,41.4976003 11.628504,39.6766464 12.7126653,38.3805436 C12.7126653,38.3805436 12.7887383,38.4956392 13.0321721,38.7225738 C13.1644592,38.8461073 13.2888186,38.9291538 13.3509583,38.9680125 C13.3754618,38.9829638 13.405891,38.9776346 13.4247091,38.9575762 C13.6248213,38.7434464 14.0137548,38.4999322 14.7827334,38.2012016 C14.974918,38.1645634 15.0317726,37.8692376 15.2543864,37.7196502 C15.4776408,37.570285 15.5953538,37.5829418 15.6595755,37.3927194 C15.7237171,37.202645 15.649646,37.2525321 15.5504307,36.9517291 C15.1554914,35.7638383 15.290421,32.0315564 15.745498,29.0802966 C15.9122182,28.0001739 16.2555079,25.8090635 16.5770166,24.6798678 C16.6504472,24.4192557 16.2763278,24.25901 15.7237171,24.3909074"
              id="Fill-6"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
