import React from 'react';

export const BigBag = () => (
  <svg width="126px" height="151px" viewBox="0 0 126 151" version="1.1">
    <title>Group 5</title>
    <desc>Created with Sketch.</desc>
    <g id="04_Protótipos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Sacola_plastico" transform="translate(-194.000000, -339.000000)">
        <g id="Group-5" transform="translate(194.000000, 339.000000)">
          <path
            d="M125,1 L125,84.1546553 L109.111772,77.1367602 L76.7162468,90.1276596 L3,119 L3,1 L125,1 Z M62.4514754,14.1808511 L33.2382369,14.1808511 C29.0961013,14.1808511 25.7382369,17.5387154 25.7382369,21.6808511 L25.7382369,21.6808511 L25.7382369,21.7446809 C25.7382369,25.8868165 29.0961013,29.2446809 33.2382369,29.2446809 L33.2382369,29.2446809 L62.4514754,29.2446809 C66.5936111,29.2446809 69.9514754,25.8868165 69.9514754,21.7446809 L69.9514754,21.7446809 L69.9514754,21.6808511 C69.9514754,17.5387154 66.5936111,14.1808511 62.4514754,14.1808511 L62.4514754,14.1808511 Z"
            id="Combined-Shape"
            fill="#E57374"
          ></path>
          <polygon
            id="Path-6"
            fill="#CC5253"
            points="92 4 92 84.6950684 108.966514 77.9511261 125 85 123.730769 4"
          ></polygon>
          <path
            d="M124,1.5 L2,1.5 C1.72385763,1.5 1.5,1.72385763 1.5,2 L1.5,149 C1.5,149.276142 1.72385763,149.5 2,149.5 L124,149.5 C124.276142,149.5 124.5,149.276142 124.5,149 L124.5,2 C124.5,1.72385763 124.276142,1.5 124,1.5 Z M61.8,12.9708333 C66.7705627,12.9708333 70.8,17.0002706 70.8,21.9708333 L70.8,22.0708333 C70.8,27.0413961 66.7705627,31.0708333 61.8,31.0708333 L32.7,31.0708333 C27.7294373,31.0708333 23.7,27.0413961 23.7,22.0708333 L23.7,21.9708333 C23.7,17.0002706 27.7294373,12.9708333 32.7,12.9708333 L61.8,12.9708333 Z"
            id="Combined-Shape"
            stroke="#004D69"
            stroke-width="3"
          ></path>
          <path
            d="M93.5,2 L92.75,148"
            id="Path-2"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M108.5,58 L108.5,131.513398"
            id="Path-2-Copy"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M108.384615,131.775243 L93,148.979045"
            id="Path-2-Copy-3"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M122.375,131.775243 L108.3125,149.253401"
            id="Path-2-Copy-4"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            transform="translate(115.500000, 140.500000) scale(-1, 1) translate(-115.500000, -140.500000) "
          ></path>
          <path
            d="M108.5,2 L108.5,44"
            id="Path-2-Copy-2"
            stroke="#004D69"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <text id="G" font-family="Lato-Black, Lato" font-size="25" font-weight="700" fill="#004D69">
            <tspan x="39" y="137">
              G
            </tspan>
          </text>
          <g id="logo-rgb-branco-copy" transform="translate(33.000000, 44.000000)" fill="#FFFFFF">
            <path
              d="M10.2702703,17.9232749 C10.4564946,18.5460363 10.6259268,18.6964423 10.7194747,19.5213767 C10.8130807,20.3460145 11.4625126,19.9257229 11.7980069,19.5861967 C12.134489,19.2465964 13.0862376,18.599509 13.4798362,16.5287554 C13.6997029,15.3737879 13.9725607,11.5795198 14.0308393,10.5407685 C14.0889436,9.50186883 13.4417197,10.2349131 12.8294746,10.4844033 C12.1709785,10.7532505 10.8797845,12.0246566 10.6110521,12.6263551 C10.3417967,13.2278312 10.0839879,17.3003653 10.2702703,17.9232749"
              id="Fill-1"
            ></path>
            <path
              d="M10.0949487,3.83796669 C10.1118627,3.78244323 10.051324,3.7358845 9.98835708,3.75395854 C9.16635374,3.98942716 7.1245333,3.54892661 5.53470165,5.1921462 C4.13820867,6.63546688 3.61529833,4.60423376 2.22742981,7.19670203 C1.29330848,8.94128085 0.80389119,11.9041947 0.436304898,14.1480508 C-0.179967337,17.9061502 -0.0938901825,22.003174 0.386149046,25.2266432 L0.386483977,25.2286675 C0.538291254,26.3781043 1.22866688,26.2565021 1.70795251,26.7113173 C2.18765681,27.1663494 2.94158551,27.5089609 3.58414979,27.2639492 C4.22704901,27.0185037 5.35903057,28.0192996 6.2623383,27.1111151 C7.16564603,26.2026415 8.42933904,24.8128923 10.373611,23.3514975 C12.2848085,21.9152619 15.5515537,20.068384 16.5517401,19.7582334 C16.5807953,19.7489795 16.5939414,19.7220853 16.5815489,19.6975046 C16.5247782,19.5803125 16.3477674,19.2782592 16.0288298,19.2691498 C15.6325232,19.2577993 15.5052496,19.288236 14.9511907,19.0838548 C14.578413,18.946492 14.7122177,18.700902 12.774477,19.3237334 C10.5383131,20.0430803 8.57042854,20.8665337 6.95263019,21.86892 C6.90071595,21.900152 6.73961435,22.0037524 6.71466203,22.0193683 C4.48745752,23.4676775 6.28410879,14.9561051 7.4264732,11.3059439 C7.8587011,9.92523169 9.81888221,4.7563449 10.0949487,3.83796669"
              id="Fill-2"
            ></path>
            <path
              d="M13.3173539,7.25854264 C13.7445891,7.54774482 14.3754736,7.75611944 15.1720831,6.78733512 C17.0072006,4.55597835 17.3666392,3.17606261 14.3647587,3.9820453 C12.2205954,4.55752611 12.6138024,6.78263451 13.3173539,7.25854264"
              id="Fill-3"
            ></path>
            <path
              d="M35.7196539,0.8534943 C35.5954478,0.799645258 35.4867382,0.722017767 35.385115,0.635903198 C34.8710807,0.199404034 33.143642,-0.093619626 32.6460387,0.0274675542 C28.2422107,1.10005853 19.9974149,10.6713599 19.9929761,10.5867087 C19.9383099,9.59130619 19.6912993,8.38197084 18.9154587,7.61623161 C18.5957155,7.3006733 17.7563311,7.29416166 17.5237269,7.28011408 C16.2343192,7.20124279 16.4469103,14.4426952 17.1342102,16.0556057 C17.1785974,16.1600114 17.2883193,16.2228596 17.407931,16.2177381 C18.4915225,16.1701812 19.2975775,16.5265653 20.2792343,15.6456652 C22.352192,13.7858978 25.0320076,10.5732464 26.5832216,9.26104182 C28.3210173,7.79087517 28.2998361,8.70104105 28.9819186,7.94539851 C30.4473953,6.32268397 34.0548244,4.60639208 36.227147,3.25358123 C37.933716,2.1903553 36.3763501,1.13832354 35.7196539,0.8534943"
              id="Fill-4"
            ></path>
            <path
              d="M28.5792272,13.7513403 C24.73982,13.82403 19.6313315,16.3375254 18.1928354,19.6404933 C16.7483035,22.9586333 16.2640368,29.3106456 16.7961762,32.1460743 C17.2176048,36.1659761 17.9374317,37.6732883 18.8492454,37.4843553 C18.8874443,37.4765806 18.9196075,37.4540113 18.9430891,37.4256298 C19.2269354,37.0789382 19.6401785,36.8462253 19.8000022,36.7142817 C20.0010841,36.5479936 20.8869358,36.0316925 20.7558026,35.3349126 C20.7247143,35.1698321 20.7073511,35.0038458 20.7052841,34.8363499 C20.6530292,31.1885774 21.9961107,19.4743561 24.0174301,17.144057 C25.1754699,15.8090716 26.9585006,16.3370725 27.3481795,16.9203266 C28.8267762,19.1332502 27.1456093,21.0925522 27.1067489,21.8112976 C27.0773142,22.3637548 27.4731115,22.3771907 27.2716162,22.9134192 C26.6101627,24.6709562 23.7422654,26.3816185 21.847201,26.9724209 C21.8012299,26.9867626 21.7799807,27.0295612 21.8004858,27.0700199 C21.8653909,27.2014351 22.0310024,27.464492 22.3131123,27.4729461 C22.6897274,27.484193 22.8106907,27.4539999 23.3372905,27.6589352 C26.0118779,27.7069422 31.2142102,23.2806421 32.3638164,21.0138239 C34.1532137,17.4856913 33.0074934,13.6680075 28.5792272,13.7513403"
              id="Fill-5"
            ></path>
            <path
              d="M15.7237171,23.7960072 C14.4976596,24.0815304 12.4374409,25.242759 11.9278315,25.6799979 C11.5143143,25.9029139 11.396281,26.3912062 11.3394264,27.1549842 C11.2653552,28.1483217 10.9352782,27.8145615 10.8624883,28.6964048 C10.8207682,29.1974063 10.7963448,29.8284601 10.7989873,30.5202436 C10.804913,31.9563802 10.9295928,33.6569544 11.2739235,35.016475 C10.320768,37.8930088 7.29906595,39.3071933 7.66381626,34.8099509 C7.839505,32.6457085 8.19008169,30.7468424 8.8423081,29.080063 C8.9210237,28.8051549 8.5333714,28.6705532 7.98300281,28.8178641 C6.89027334,29.1107528 5.22859744,30.1739186 4.82613091,30.5434956 C4.46922813,30.7861975 4.36801092,31.2588199 4.32236708,32.0109719 C4.26254963,32.9897228 3.97443292,32.6670108 3.9155764,33.5356396 C3.74493251,36.0485899 3.49653394,39.138219 6.66797985,39.8765787 C9.28249086,40.4854637 11.628504,38.7089234 12.7126653,37.4444327 C12.7126653,37.4444327 12.7887383,37.5567212 13.0321721,37.7781208 C13.1644592,37.8986413 13.2888186,37.9796623 13.3509583,38.0175732 C13.3754618,38.0321598 13.405891,38.0269606 13.4247091,38.0073914 C13.6248213,37.7984843 14.0137548,37.5609094 14.7827334,37.269465 C14.974918,37.2337204 15.0317726,36.9455977 15.2543864,36.7996588 C15.4776408,36.6539365 15.5953538,36.6662847 15.6595755,36.4807018 C15.7237171,36.2952634 15.649646,36.3439338 15.5504307,36.0504674 C15.1554914,34.8915496 15.290421,31.250299 15.745498,28.3710211 C15.9122182,27.3172428 16.2555079,25.1795742 16.5770166,24.0779198 C16.6504472,23.8236641 16.2763278,23.6673268 15.7237171,23.7960072"
              id="Fill-6"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
