import React from 'react';
import { Typography } from 'src/components/atoms/typography';
import styled from 'styled-components';

export const ExtraItemContainer = styled.div`
  && {
    width: fit-content;
    display: flex;
    flex-direction: column
    height: 220px;
    padding-left: ${props => (props.index > 0 ? '70px' : '0')}
    margin-left:  ${props => (props.index > 0 ? '70px' : '0')}
    border-left: ${props => (props.index > 0 ? `1px solid ${props.theme.palette.grayScale[20]}` : ``)};
  }
`;
export const ExtraItemName = styled(Typography)`
  && {
    color: ${props => props.theme.palette.grayScale[80]};
    font-size: ${props => props.theme.typography.fontSize.medium};
    font-weight: bold;
    margin-bottom: 42px;
  }
`;
export const ExtraItemIconContainer = styled.div`
  && {
    align-self: center;
  }
`;

export const ExtraItemIcon = styled.div``;

export const ExtraItemImage = styled.img`
  && {
    width: 138px;
    height: 138px;
  }
`;
