import { AvailableMeals, FormattedProduct, PickingItemStatus, ProductsBySku, ProductTypes } from 'src/common/types';
import { sortByPackageColor } from '../sorting';

const AVAILABLE_MEALS = Object.values(AvailableMeals);

export function formatKits(kits: any[], productsBySku: ProductsBySku, productTypes: ProductTypes[]) {
  const formattedKits: any[] = [];
  kits.forEach(kit => {
    new Array(kit.quantity).fill(1).forEach(_ => {
      const kitDays: any[] = [];
      AVAILABLE_MEALS.forEach(meal => {
        if (kit.item[meal]) {
          kit.item[meal].forEach((day: any) => {
            if (
              day.products.filter((product: any) => productTypes.includes(product.product.type as ProductTypes))
                .length > 0
            ) {
              const foundDay = kitDays.find(d => d.day === day.day);
              if (foundDay) {
                foundDay.products = mergeKitProducts(
                  [
                    ...foundDay.products,
                    ...day.products
                      .filter((product: any) => productTypes.includes(product.product.type as ProductTypes))
                      .map((product: any) => product.product),
                  ],
                  productsBySku
                );
              } else {
                kitDays.push({
                  day: day.day,
                  status: PickingItemStatus.waiting,
                  key: String(Math.random()),
                  products: mergeKitProducts(
                    day.products
                      .filter((product: any) => productTypes.includes(product.product.type as ProductTypes))
                      .map((product: any) => product.product),
                    productsBySku
                  ),
                });
              }
            }
          });
        }
      });
      if (kitDays.length > 0) {
        formattedKits.push({
          key: String(Math.random()),
          name: kit.item.name,
          totalQuantity: Number(kit.quantity),
          checks: 0,
          status: PickingItemStatus.waiting,
          kitDays,
        });
      }
    });
  });
  return formattedKits;
}

function mergeKitProducts(products: any[], productsBySku: ProductsBySku) {
  const formattedProducts: FormattedProduct[] = [];
  products.forEach(product => {
    const found = formattedProducts.find(p => p.sku === product.sku);
    if (found) {
      found.totalQuantity += 1;
    } else {
      formattedProducts.push({
        key: String(Math.random()),
        sku: product.sku,
        name: productsBySku[product.sku].name,
        color: productsBySku[product.sku].color,
        totalQuantity: product.totalQuantity || 1,
        checks: 0,
        status: PickingItemStatus.checking,
        type: productsBySku[product.sku].type as ProductTypes,
        catalogueCategories: productsBySku[product.sku].catalogueCategories,
      });
    }
  });
  return sortByPackageColor(formattedProducts);
}
