import styled from 'styled-components';
import { StyledButton } from './styles';

export const PrimaryButton = styled(StyledButton).attrs({
  classes: { label: 'label' },
})`
  && {
    background-color: ${props =>
      props.disabled ? props.theme.palette.grayScale['20'] : props.theme.palette[props.color].regular};
    &:hover {
      background-color: ${props => props.theme.palette[props.color].regular};
      box-shadow: inset 0 0 5px ${props => props.theme.palette[props.color].ligther};
    }
  }
  & .label {
    color: ${props => (props.disabled ? props.theme.palette.grayScale['40'] : props.theme.palette.background)};
  }
`;
